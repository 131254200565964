import { numberWithCommas } from "../../../../Utilities/utilsFunctions";
import * as Styled from "./styled";

const SubCollapse = ({
  layout = {},
  data = [],
  index,
  active = false,
  setActive = () => {}
}) => {
  const onSubCollapseChange = () => {
    if (active) {
      setActive('');
    } else {
      setActive(index);
    }
  }
  
  return (
    <Styled.MainWrapDiv active={active ? 'true' : 'false'}>
      <Styled.HeadWrapDiv
        active={active ? 'true' : 'false'}
        onClick={onSubCollapseChange}
        showArrow={layout?.layout ? 'true' : 'false'}
      >
        <Styled.HeadingDiv active={active ? 'true' : 'false'}>
          <div>{layout?.title || ''}</div>
          <div>₹{numberWithCommas(data.reduce((a, b) => a + (b.total_amount || 0), 0))}</div>
        </Styled.HeadingDiv>
      </Styled.HeadWrapDiv>

      <Styled.ContentWrapDiv maxHeight="800px" active={active ? 'true' : 'false'}>
        { layout?.layout?.map((item) => {
          const totalAmount = data?.filter((e) => e.child_name === item)?.[0]?.total_amount || 0

          return (
          <Styled.RowDiv>
            <Styled.InputLabelDiv>{item}</Styled.InputLabelDiv>
            <Styled.InputLabelDiv>₹{numberWithCommas(totalAmount)}</Styled.InputLabelDiv>
          </Styled.RowDiv>
        )})}
      </Styled.ContentWrapDiv>
    </Styled.MainWrapDiv>
  )
}

export default SubCollapse;
