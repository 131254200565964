import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Modal from "../../Components/Modal";
import Table from "../../Components/Table";
import Input from "../../Components/Input";
import { userTypes } from "../../StaticData/databases";
import { fetchData } from "../../Utilities/handleRequest";
import { toastError } from "../../Utilities/toast";
import Loader from "../../Components/Loader";
import * as CommonStyled from "../../Common/commonStyled";
import * as Styled from "./styled";
import * as XLSX from "xlsx";
import SecondaryButton from "../../Components/SecondaryButton";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [actionUser, setActionUser] = useState(null);
  const [actionValue, setActionValue] = useState(null);
  const [userError, setUserError] = useState({});

  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(10);

  const [searchText, setSearchText] = useState("");
  const [searchRole, setSearchRole] = useState("");
  const [managerList, setManagerList] = useState([]);

  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [phoneno, setPhoneno] = useState(null);
  const [employeeCode, setEmployeeCode] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [selectedManager, setSelectedUserManager] = useState("");
  const [selectedZone, setSelectedZone] = useState("");

  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const navigate = useNavigate();
  const zonesList = [
    { id: "All", name: "All Zones" },
    { id: "Zone1", name: "Zone 1" },
    { id: "Zone2", name: "Zone 2" },
    { id: "Zone3", name: "Zone 3" },
  ];

  useEffect(() => {
    setPageNo(1);
    getUsersList(1);
  }, [pageSize, searchRole, searchText]);

  useEffect(() => {
    if (selectedUserType) {
      getManagersList();
      if (selectedUserType !== "ZONAL_OPERATIONS_HEAD") {
        setSelectedZone("");
      }
    }
  }, [selectedUserType]);

  const getManagersList = async () => {
    let res = await fetchData("get", `admin-auth/manager/${selectedUserType}`);
    let list = [];
    res?.data?.map((manager) => {
      manager.name = `${manager.name}(${manager.email})`;
      list.push(manager);
    });
    setManagerList(list || []);
  };

  const getUsersList = async (page) => {
    setFetching(true);
    let searchParams = "";
    searchText && (searchParams += `&name=${searchText}`);
    searchRole &&
      searchRole !== "All" &&
      (searchParams += `&role=${searchRole}`);
    let res = await fetchData(
      "get",
      `admin-auth/get-all-users?pageNo=${page}&limit=${pageSize}${searchParams}`
    );
    setAllUsers(res?.data?.users);
    setTotalUsers(res?.data?.count);
    setFetching(false);
  };

  const deleteUser = (userId) => {
    setLoading(true);
    setFetching(true);

    fetchData("get", `admin-auth/delete-user/${userId}`);

    setAllUsers(allUsers?.filter((user) => user?.id !== userId));
    setFetching(false);
    setLoading(false);
    toastError("The user has been deleted successfully!");
  };

  const changeUserAccess = (userId) => {
    setLoading(true);

    fetchData("post", `admin-auth/revoke-user/${userId}`, {
      is_revoke: !actionValue,
    });

    setLoading(false);
    toastError(
      !actionValue
        ? "The user access has been revoked successfully!"
        : "The user has been granted access successfully!"
    );
  };

  const onChangeAllUsers = (id, key, value) => {
    const objIndex = allUsers.findIndex((obj) => obj.id === id);
    const changedUsers = allUsers;
    changedUsers[objIndex][key] = !value;
    setAllUsers(changedUsers);
  };

  const onRevoke = (row, value) => {
    setActionUser(row);
    setActionValue(value);
    setShowRevokeModal(true);
  };

  const onConfirmRevoke = () => {
    onChangeAllUsers(actionUser.id, "is_revoked", actionValue);
    changeUserAccess(actionUser?.id, actionValue);
    setShowRevokeModal(false);
  };

  const onDelete = (row) => {
    setActionUser(row);
    setShowDeleteModal(true);
  };

  const onConfirmDelete = () => {
    setAllUsers(allUsers?.filter((user) => user.id !== actionUser?.id));
    deleteUser(actionUser?.id);
    setShowDeleteModal(false);
  };

  const onEdit = (row) => {
    setSelectedUserManager(row?.manager_id);
    setSelectedUserType(row?.role);
    setSelectedZone(row?.zone);
    setName(row?.name);
    setEmail(row?.email);
    setPhoneno(row?.phone_number);
    setEmployeeCode(row?.employee_id);
    setPincode(row?.pincode);
    setActionUser(row);
    setShowEditModal(true);
  };

  const validateInviteUser = (mode) => {
    let isValid = true;
    const error = { ...userError };
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email?.match(emailRegex)) {
      error.email = "Enter valid email";
      isValid = false;
    }
    if (!name || name?.trim() === "") {
      error.name = "Enter valid name";
      isValid = false;
    }
    if (phoneno && phoneno?.trim() !== "" && phoneno.length !== 10) {
      error.phoneno = "Enter valid phone number";
      isValid = false;
    }
    if (!selectedManager || selectedManager?.trim() === "") {
      error.selectedManager = "Select manager";
      isValid = false;
    }
    if (!selectedUserType || selectedUserType?.trim() === "") {
      error.selectedUserType = "Select role";
      isValid = false;
    }
    if (
      selectedUserType === "ZONAL_OPERATIONS_HEAD" &&
      (!selectedZone || selectedZone?.trim() === "")
    ) {
      error.selectedZone = "Select zone";
      isValid = false;
    }

    setUserError(error);

    if (isValid) {
      if (mode === "invite") {
        inviteUser();
      } else {
        editUser();
      }
    }
  };

  const inviteUser = async () => {
    setFetching(true);
    let res = await fetchData("post", `admin-auth/create-user`, {
      name: name?.trim(),
      email: email?.trim(),
      phone_number: phoneno?.trim(),
      employee_id: employeeCode?.trim(),
      manager_id: selectedManager?.trim(),
      role: selectedUserType?.trim(),
    });

    if (res?.data) {
      setAllUsers([res?.data?.user, ...allUsers]);
      setTotalUsers(totalUsers + 1);
      hideModal();
      toastError("The invitation has been sent successfully!");
    }
    setFetching(false);
  };

  const editUser = async () => {
    if (
      email?.trim()?.length === 0 ||
      name?.trim()?.length === 0 ||
      !selectedManager ||
      !selectedUserType
    ) {
      toastError("Provide all mandatory details");
      return;
    }
    let res = await fetchData(
      "post",
      `admin-auth/edit-user/${actionUser?.id}`,
      {
        name: name?.trim() || "",
        email: email?.trim() || "",
        phone_number: phoneno?.trim() || "",
        employee_id: employeeCode?.trim() || "",
        manager_id: selectedManager?.trim() || "",
        role: selectedUserType?.trim() || "",
        pincode: pincode?.trim() || "",
      }
    );

    if (res?.data) {
      setShowEditModal(false);
      resetState();
      toastError("The user details has been edited successfully!");
      getUsersList();
    }
  };

  const onPageChange = (value) => {
    setPageNo(value);
    getUsersList(value);
  };

  const headings = [
    { name: "Name", key: "name", type: "text", sortBy: true },
    { name: "Email", key: "email", type: "email", sortBy: true },
    { name: "Role", key: "role", type: "text", sortBy: true },
    { name: "Employee ID", key: "employee_id", type: "text", sortBy: true },
    { name: "Last Login", key: "last_login", type: "date", sortBy: true },
    {
      name: "Action",
      type: "action",
      actions: {
        edit: { onClick: onEdit, toolText: "Edit user" },
        delete: { onClick: onDelete, toolText: "Delete user" },
        toggle: {
          key: "is_revoked",
          onChange: onRevoke,
          toolText: (value) => (value ? "Grant Access" : "Revoke Access"),
        },
      },
    },
  ];

  const resetState = () => {
    setEmail("");
    setName("");
    setPhoneno("");
    setActionUser("");
    setSelectedUserType("");
    setSelectedZone("");
    setEmployeeCode("");
    setPincode("");
    setSelectedUserManager("");
  };

  const hideModal = () => {
    setShowRevokeModal(false);
    setShowDeleteModal(false);
    setShowEditModal(false);
    setShowAddModal(false);
    resetState();
    setUserError({});
  };

  const getTotalExistingUsers = async () => {
    try {
      setLoading(true);
      let allData = [];
      const promises = [];

      for (
        let currentPage = 1;
        currentPage <= Math.ceil(totalUsers / pageSize);
        currentPage++
      ) {
        let fullUrl = `admin-auth/get-all-users?pageNo=${currentPage}&limit=${pageSize}`;
        let searchParams = "";
        if (searchText) {
          searchParams += `&name=${searchText}`;
        }
        if (searchRole && searchRole !== "All") {
          searchParams += `&role=${searchRole}`;
        }
        fullUrl += searchParams;
        promises.push(fetchData("get", fullUrl));
      }

      const results = await Promise.all(promises);
      results.forEach((result) => {
        allData = allData.concat(result?.data?.users || []);
      });

      // Transform data for Excel export
      const excelData = allData.map((user) => ({
        Id: user.id || "",
        Name: user.name || "",
        Email: user.email || "",
        Role: user.role || "",
        "Employee ID": user.employee_id || "",
        "Phone No": user.phone_number || "",
        //'Status': user.is_revoked ? 'Revoked' : 'Active',
        // ... existing code ...
        "Last Login": user.last_login
          ? new Date(user.last_login).toLocaleString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
          : "",
        // ... existing code ...
        Zone: user.zone || "",
        Pincode: user.pincode || "",
      }));

      // Create workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, "Users");

      // Generate Excel file
      XLSX.writeFile(wb, "Users.xlsx");
    } catch (error) {
      console.error("Export failed:", error);
      toastError("Export failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout
      activePage="Users"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Loader loading={loading} />

      <Modal
        show={showRevokeModal}
        hide={hideModal}
        okText="Revoke"
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/revoke-modal-icon.svg" />
          <CommonStyled.VerifyModalHeading>{`${
            actionValue ? "Grant" : "Revoke"
          } Access`}</CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>
            {actionValue
              ? "Are you sure you want to grant the access to this user?"
              : "Are you sure you want to revoke the access of this user?"}
          </CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onConfirmRevoke}>{`${
              actionValue ? "Grant" : "Revoke"
            }`}</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      <Modal
        show={showDeleteModal}
        hide={hideModal}
        okText="Delete"
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/delete-modal-icon.svg" />
          <CommonStyled.VerifyModalHeading>
            Delete User
          </CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>
            Are you sure you want to delete this user?
          </CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onConfirmDelete}>Delete</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      <Modal
        show={showEditModal}
        hide={hideModal}
        hideFooter={true}
        width="446px"
        heading="Edit User"
      >
        <Styled.ColumnDiv>
          <Styled.InputWrapDiv iserror={userError.name}>
            <Styled.AddEditTitleDiv notEmpty>Name</Styled.AddEditTitleDiv>
            <Input
              id="name"
              placeholder="Enter user name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setUserError({ ...userError, name: null });
              }}
              error={userError.name}
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.email}>
            <Styled.AddEditTitleDiv notEmpty>
              Email Address
            </Styled.AddEditTitleDiv>
            <input
              disabled
              style={{ backgroundColor: "#E5E5E5" }}
              type="text"
              placeholder="Enter email address"
              value={email}
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.phoneno}>
            <Styled.AddEditTitleDiv>Phone No.</Styled.AddEditTitleDiv>
            <Input
              id="phoneno"
              placeholder="Enter phone no."
              value={phoneno}
              onChange={(e) => {
                setPhoneno(e.target.value);
                setUserError({ ...userError, phoneno: null });
              }}
              error={userError.phoneno}
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.employeeCode}>
            <Styled.AddEditTitleDiv>Employee Code</Styled.AddEditTitleDiv>
            <Input
              id="employeeCode"
              placeholder="Enter employee code"
              value={employeeCode}
              onChange={(e) => {
                setEmployeeCode(e.target.value);
                setUserError({ ...userError, employeeCode: null });
              }}
              error={userError.employeeCode}
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.selectedUserType}>
            <Styled.AddEditTitleDiv notEmpty>Role</Styled.AddEditTitleDiv>
            <SingleSelect
              data={userTypes?.filter((user) => user?.id !== "All")}
              placeholder="Select Role"
              selectedId={selectedUserType}
              onSelect={(id) => {
                setSelectedUserType(id);
                setSelectedUserManager("");
                setUserError({ ...userError, selectedUserType: null });
              }}
              style={{ width: "100%" }}
              error={userError.selectedUserType}
              direction="up"
            />
          </Styled.InputWrapDiv>
          {selectedUserType === "ZONAL_OPERATIONS_HEAD" ? (
            <Styled.InputWrapDiv iserror={userError.selectedZone}>
              <Styled.AddEditTitleDiv notEmpty>Zone</Styled.AddEditTitleDiv>
              <SingleSelect
                data={zonesList?.filter((user) => user?.id !== "All")}
                placeholder="Select Zone"
                selectedId={selectedZone}
                onSelect={(id) => {
                  setSelectedZone(id);
                  setUserError({ ...userError, selectedZone: null });
                }}
                style={{ width: "100%" }}
                error={userError.selectedZone}
                direction="up"
              />
            </Styled.InputWrapDiv>
          ) : (
            ""
          )}
          <Styled.InputWrapDiv iserror={userError.selectedManager}>
            <Styled.AddEditTitleDiv notEmpty>Manager</Styled.AddEditTitleDiv>
            <SingleSelect
              data={managerList}
              placeholder="Select Manager"
              selectedId={selectedManager}
              onSelect={(id) => {
                setSelectedUserManager(id);
                setUserError({ ...userError, selectedManager: null });
              }}
              style={{ width: "100%" }}
              error={userError.selectedManager}
              direction="up"
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.pincode}>
            <Styled.AddEditTitleDiv>Pin Code</Styled.AddEditTitleDiv>
            <Input
              id="pincode"
              placeholder="Enter pin code"
              value={pincode}
              onChange={(e) => {
                if (e.target.value?.length < 7) {
                  setPincode(e.target.value);
                }
                setUserError({ ...userError, pincode: null });
              }}
              type="number"
              error={userError.pincode}
            />
          </Styled.InputWrapDiv>

          <Styled.AddButtonsDiv>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={() => validateInviteUser("edit")}>Save</button>
          </Styled.AddButtonsDiv>
        </Styled.ColumnDiv>
      </Modal>

      <Modal
        show={showAddModal}
        hide={hideModal}
        hideFooter={true}
        width="446px"
        heading="Invite New User"
      >
        <Styled.ColumnDiv>
          <Styled.InputWrapDiv iserror={userError.name}>
            <Styled.AddEditTitleDiv notEmpty>Name</Styled.AddEditTitleDiv>
            <Input
              id="name"
              placeholder="Enter user name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setUserError({ ...userError, name: null });
              }}
              error={userError.name}
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.email}>
            <Styled.AddEditTitleDiv notEmpty>
              Email Address
            </Styled.AddEditTitleDiv>
            <Input
              id="name"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setUserError({ ...userError, email: null });
              }}
              error={userError.email}
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.phoneno}>
            <Styled.AddEditTitleDiv>Phone No.</Styled.AddEditTitleDiv>
            <Input
              id="phoneno"
              placeholder="Enter phone no."
              value={phoneno}
              onChange={(e) => {
                setPhoneno(e.target.value);
                setUserError({ ...userError, phoneno: null });
              }}
              type="number"
              error={userError.phoneno}
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.employeeCode}>
            <Styled.AddEditTitleDiv>Employee Code</Styled.AddEditTitleDiv>
            <Input
              id="employeeCode"
              placeholder="Enter employee code"
              value={employeeCode}
              onChange={(e) => {
                setEmployeeCode(e.target.value);
                setUserError({ ...userError, employeeCode: null });
              }}
              error={userError.employeeCode}
            />
          </Styled.InputWrapDiv>
          <Styled.InputWrapDiv iserror={userError.selectedUserType}>
            <Styled.AddEditTitleDiv notEmpty>Role</Styled.AddEditTitleDiv>
            <SingleSelect
              data={userTypes?.filter((user) => user?.id !== "All")}
              placeholder="Select Role"
              selectedId={selectedUserType}
              onSelect={(id) => {
                setSelectedUserType(id);
                setSelectedUserManager("");
                setUserError({ ...userError, selectedUserType: null });
              }}
              style={{ width: "100%" }}
              error={userError.selectedUserType}
              direction="up"
            />
          </Styled.InputWrapDiv>
          {selectedUserType === "ZONAL_OPERATIONS_HEAD" ? (
            <Styled.InputWrapDiv iserror={userError.selectedZone}>
              <Styled.AddEditTitleDiv notEmpty>Zone</Styled.AddEditTitleDiv>
              <SingleSelect
                data={zonesList?.filter((user) => user?.id !== "All")}
                placeholder="Select Zone"
                selectedId={selectedZone}
                onSelect={(id) => {
                  setSelectedZone(id);
                  setUserError({ ...userError, selectedZone: null });
                }}
                style={{ width: "100%" }}
                error={userError.selectedZone}
                direction="up"
              />
            </Styled.InputWrapDiv>
          ) : (
            ""
          )}
          <Styled.InputWrapDiv style={{ marginBottom: "0px" }}>
            <Styled.AddEditTitleDiv notEmpty>Manager</Styled.AddEditTitleDiv>
            <SingleSelect
              data={managerList}
              placeholder="Select Manager"
              selectedId={selectedManager}
              onSelect={(id) => {
                setSelectedUserManager(id);
                setUserError({ ...userError, selectedManager: null });
              }}
              style={{ width: "100%" }}
              error={userError.selectedManager}
              direction="up"
            />
          </Styled.InputWrapDiv>

          <Styled.AddButtonsDiv>
            <button onClick={hideModal}>Cancel</button>
            <button
              onClick={() => validateInviteUser("invite")}
              style={{ padding: "0px 28px" }}
            >
              Invite User
            </button>
          </Styled.AddButtonsDiv>
        </Styled.ColumnDiv>
      </Modal>

      <Heading
        heading="All Users"
        subHeading="This is where you can view the listing of all the users and send the invitation to new users"
      >
        <div style={{ display: "flex" }}>
          <SecondaryButton
            title="Update Users"
            style={{
              marginLeft: "20px",
              borderRadius: "40px",
              color: "#118d8b",
            }}
            onClick={() => navigate("/bulk-edit-users")}
          />
          <PrimaryButton
            title="Invite New User"
            style={{ marginLeft: "20px" }}
            onClick={() => setShowAddModal(true)}
          />

          <PrimaryButton
            title="Export"
            style={{ marginLeft: "20px" }}
            onClick={getTotalExistingUsers}
          />
        </div>
      </Heading>

      <CommonStyled.FilterDiv>
        <SingleSelect
          data={userTypes}
          placeholder="Roles"
          selectedId={searchRole}
          onSelect={(id) => {
            setFetching(true);
            setSearchRole(id);
          }}
        />

        <SearchInput
          onInputChange={(e) => {
            setFetching(true);
            setSearchText(e.target.value);
          }}
          placeholder="Search by name"
        />
      </CommonStyled.FilterDiv>

      {!allUsers?.length && selectedUserType === "All" && !searchRole ? (
        <>
          {!fetching ? (
            <CommonStyled.NoDataFoundDiv>
              <img src="/users-color-icon.svg" alt="" />
              <div>No user added so far</div>
              <PrimaryButton title="Invite New User" />
            </CommonStyled.NoDataFoundDiv>
          ) : (
            ""
          )}
        </>
      ) : (
        <Table
          rows={allUsers}
          headings={headings}
          page={pageNo}
          limit={pageSize}
          count={totalUsers}
          onPageChange={onPageChange}
          onLimitChange={(value) => setPageSize(value)}
          noDataImage="/no-user-found-icon.svg"
          noDataText="No user found"
          loading={fetching}
        />
      )}
    </Layout>
  );
};

export default Users;
