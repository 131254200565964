import { withRouter } from "../../Utilities/withRouter";

import { useEffect, useRef, useState } from "react";
import JSZip from "jszip";
import { read, utils } from "xlsx";
import Heading from "../../Components/Heading";
import Layout from "../../Components/Layout";
import SecondaryButton from "../../Components/SecondaryButton";
import PrimaryButton from "../../Components/PrimaryButton";
import { fetchData, uploadMedia } from "../../Utilities/handleRequest";
import { toastError } from "../../Utilities/toast";
import * as Styled from "./styled";

const BulkUpdateElements = () => {
  const [file, setFile] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [remainingFiles, setRemainingFiles] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [errorFiles, setErrorFiles] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [allBrands, setAllBrands] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allExecutionCategories, setAllExecutionCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const fileInput = useRef(null);

//   const [elementData, setElementData] = useState({
//     title: '',
//     sku: '',
//     description: '',
//     category_id: '',
//     category_name: '',
//     subcategory_id: '',
//     subcategory_name: '',
//     execution_category: [],
//     size: {
//       length: '',
//       width: '',
//       height: '',
//     },
//     brands: [],
//     misc: [],
//     finishing: [],
//     mrp: '',
//     unit: [],
//     procurement_cost: '',
//     bx_price: '',
//     items_per_box: 1,
//     wastage: 0,
//     service_cost_type: '',
//     service_cost: '',
//     element_image: [],
//     model_url: '',
//     ios_model_url: '',
//     webgl_model_url: ''
//   });

  const texts = {
    subHeading: 'Upload the ZIP file of the models, images and xlsx.',
    upload: 'File format: .zip',
    notes: [
      'The .zip file may contain 3D models, images and .xlsx file including all required data.',
      'The models will be imported in the background and their respective statuses will be updated once the import is completed.'
    ]
  }

  const elementObject = [
    { key: 'title', name: 'Title', type: 'string' },
    { key: 'sku', name: 'SKU Code', type: 'string' },
    { key: 'description', name: 'Description', type: 'string' },
    { key: 'category_name', name: 'Category Name', type: 'string' },
    { key: 'subcategory_name', name: 'Subcategory Name', type: 'string' },
    { key: 'execution_category', name: 'Execution Category', type: 'array' },
    { key: 'length', name: 'Length', type: 'string' },
    { key: 'width', name: 'Width', type: 'string' },
    { key: 'height', name: 'Height', type: 'string' },
    { key: 'brands', name: 'Brands', type: 'string' },
    { key: 'misc', name: 'Misc', type: 'string' },
    { key: 'finishing', name: 'Finishing', type: 'string' },
    { key: 'mrp', name: 'MRP', type: 'string' },
    { key: 'unit', name: 'Unit', type: 'string' },
    { key: 'procurement_cost', name: 'Procurement Cost', type: 'string' },
    { key: 'bx_price', name: 'BX Price', type: 'string' },
    { key: 'service_cost_type', name: 'Service Cost Type', type: 'string' },
    { key: 'service_cost', name: 'Service Cost', type: 'string' },
    { key: 'element_image', name: 'Element Images', type: 'array' },
    { key: 'model_url', name: 'Android Model File', type: 'string' },
    { key: 'ios_model_url', name: 'IOS Model File', type: 'string' },
    { key: 'webgl_model_url', name: 'WEBGL Model File', type: 'string' }
  ];

  useEffect(() => {
    getCategories();
    getBrands();
  }, []);

  const getBrands = async () => {
    let res = await fetchData('get', 'element/get-elements-utils');
    setAllExecutionCategories(res?.data?.executionCategory || []);

    const apiBrands = res?.data?.brands || [];
    const elementBrands = [];

    if (apiBrands && apiBrands.length) {
      for (let x = 0; x < apiBrands.length; x++) {
        elementBrands.push({ id: apiBrands[x].name, name: apiBrands[x].name });
      }
    }

    setAllBrands(elementBrands);
  }

  const getCategories = async () => {
    let res = await fetchData('get', 'element/get-all-category');
    setAllCategories(res?.data?.category || []);
  }

  const getSubCategories = async (categoryId) => {
    if (!categoryId) return;
    let res = await fetchData('get', `element/get-all-subcategory?category_id=${categoryId}`);
    if (!res?.data?.subcategory?.length) {
      return false;
    } else {
      return res?.data?.subcategory;
    }
  }

  const addNewExecutionCategory = async (payload, files, zipContent, index) => {
    let data = { ...payload };

    const key = 'execution_category';
    for (let x = 0; x < data[key].length; x++) {
      const arrFilter = allExecutionCategories.filter((e) => e.name && data[key][x] && e.name.trim().toLowerCase() == data[key][x].trim().toLowerCase())[0];

      if (arrFilter) {
        data[key][x] = arrFilter.name;
      } else {
        await fetchData('post', 'element/add-execution-category', { name: data[key][x] });
      }
    }

    delete data.category_name;
    delete data.subcategory_name;

    setProgressPercentage(50);
    await uploadElementImages(data, files, zipContent, index);
  }


const uploadElementImages = async (newData, files, zipContent, index) => {
    const uploadKeys = ['element_image', 'model_url', 'ios_model_url', 'webgl_model_url'];
    let data = { ...newData };

    for (let y = 0; y < uploadKeys.length; y++) {
        const key = uploadKeys[y];
        let mediaUrl = '';

        if (key === 'element_image') {
            // Initialize imageUrlArr with existing URLs or an empty array
            const imageUrlArr = [...(data[key] || [])]; // Start with existing URLs or an empty array

            // Check if there are any files to upload
            const filesToUpload = files.filter((filename) => 
                filename.endsWith('.png') || 
                filename.endsWith('.jpg') || 
                filename.endsWith('.jpeg')
            );

            // If there are files to upload, process them
            if (filesToUpload.length > 0) {
                for (let x = 0; x < filesToUpload.length; x++) {
                    const fileToUpload = filesToUpload[x];
                    const imageContent = await zipContent.files[fileToUpload].async('blob');
                    const newFile = new File([imageContent], fileToUpload); // Use the file name directly
                    mediaUrl = await uploadMedia(newFile); // Upload the file and get the media URL
                    imageUrlArr.push(mediaUrl); // Append the generated media URL
                }
            } else {
                // If no files were found, log a message
                console.log("No image files found to upload.");
            }

            // Update data with the new array of image URLs
            data = { ...data, [key]: imageUrlArr }; 
        } else {
            // For other keys (model_url, ios_model_url, webgl_model_url)
            if (!data[key]?.includes('http')) {
                const fileToUpload = files.find((filename) => filename.endsWith(`/${data[key]}`));
                if (fileToUpload) {
                    const imageContent = await zipContent.files[fileToUpload].async('blob');
                    const newFile = new File([imageContent], data[key]);
                    mediaUrl = await uploadMedia(newFile); // Upload the file and get the media URL
                    data = { ...data, [key]: mediaUrl }; // Update the data with the new URL
                } else {
                    toastError(`File not found (${data[key]})`);
                }
            }
        }
    }

    setProgressPercentage(75);
    await createElement(data, index);
}

  const createElement = async (data, index) => {
    try {

      let res = await fetchData('post', `element/update-elements/${data.id}`, data);
      if (res?.data) {
        setUploadedFiles((prev) => prev + 1);
        if (remainingFiles === 1) {
          toastError('The element has been updated successfully!');
        }
      } else {
        let createError = '';
        if (res?.error?.data?.message?.includes(' * - ')) {
          createError = `Element ${index + 1}: ${res?.error?.data?.message.split(' * - ')[1]}`;
        } else {
          createError = `Element ${index + 1}: Something went wrong while updating`;
        }
        setErrorFiles((prev) => [...prev, createError]);
        toastError(createError);
      }
    } catch (err) {
      setErrorFiles((prev) => [...prev, `Element ${index + 1}: Something went wrong while updating`]);
    }
    setProgressPercentage(100);
    setRemainingFiles((prev) => prev - 1);
  }

  const isEmpty = (str) => {
    if (typeof str === 'string') {
      return str.trim() === "";
    } else {
      if (str === null || typeof str === 'undefined') {
        return true;
      } else {
        return false;
      }
    }
  }

  const onUpload = async () => {
    setProgressPercentage(0);
    if (loading) {
      return;
    }
    if (!file) {
      toastError('Please select zip file!');
      return;
    }
    setLoading(true);
    setIsVisible(true);

    const zip = new JSZip();

    try {
      const zipContent = await zip.loadAsync(file);
      const files = Object.keys(zipContent.files).filter(
        (fileName) => !fileName.includes('__MACOSX') && !fileName.includes('/.')
      );

      const excelFileName = files.find((fileName) => fileName.endsWith('.xlsx'));

      if (excelFileName) {
        try {
          const excelFile = await zipContent.files[excelFileName].async('blob');

          const ab = await excelFile.arrayBuffer();
          const wb = read(ab);
          const ws = wb.Sheets[wb.SheetNames[0]];
          const fileData = utils.sheet_to_json(ws);

          if (!fileData?.length) {
            toastError('Excel file is empty.');
            setLoading(false);
            return;
          } else {
            setRemainingFiles(fileData.length);
          }

          for (let x = 0; x < fileData.length; x++) {
            const payload = {};
            let payloadError = '';
            const currentLine = fileData[x];

            for (let y = 0; y < elementObject.length; y++) {
              const obj = elementObject[y];

              if (obj.type === 'array') {
                payload[obj.key] = currentLine?.[obj.name]?.toString()?.split(',')?.map(str => str.trim()) || [];
                if (!payload?.[obj.key]?.[0] && obj.key !== 'element_image') {
                  payloadError = obj.name;
                  break;
                }
              } else if (obj.key === 'length' || obj.key === 'width' || obj.key === 'height') {
                if (!payload.size) {
                  payload.size = {};
                }
                if (isEmpty(currentLine?.[obj.name])) {
                  payloadError = obj.name;
                  break;
                }
                payload.size[obj.key] = typeof currentLine[obj.name] === 'string' ? currentLine[obj.name].trim() : currentLine[obj.name];
              } else {
                if (obj.key !== 'description' && isEmpty(currentLine?.[obj.name])) {
                  payloadError = obj.name;
                  break;
                }
                payload[obj.key] = typeof currentLine[obj.name] === 'string' ? currentLine[obj.name].trim() : currentLine[obj.name] || null;
              }
            }

            if (payloadError === '' && payload?.category_name && allCategories) {
              const selectedCategory = allCategories.filter((cat) => cat.name === payload.category_name)[0];
              if (selectedCategory?.id) {
                payload.category_id = selectedCategory.id;
                const allSubCategories = await getSubCategories(selectedCategory.id);
                const selectedSubCategory = allSubCategories.filter((subcat) => subcat.name === payload.subcategory_name)[0];

                if (selectedSubCategory?.id) {
                  payload.subcategory_id = selectedSubCategory.id;
                }
              }
            }

            if (currentLine?.ID) {
              payload.id = currentLine.ID;
            }


            if (payloadError === '') {
               
              setProgressPercentage(25);
              await addNewExecutionCategory(payload, files, zipContent, x);
            } else {
               
              setErrorFiles((prev) => [...prev, `Element ${x + 1}: '${payloadError}' not found in element data`]);
              setRemainingFiles((prev) => prev - 1);
            }
            
          
        }
        } catch (uploadError) {
          toastError('Excel file is invalid.');
        }
      } else {
        toastError('Excel file not found in the ZIP.');
        setLoading(false);
        return;
      }
    } catch (err) {
      toastError('Failed to unzip the file.');
    }
    setLoading(false);
  }

  const onFileSelect = (e) => {
    if (!e?.target?.files?.length) {
      if (fileInput?.current) {
        fileInput.current.value = ''
      }
      return;
    }
    afterFileSelect(e.target.files[0]);
  }

  const onDropFile = (e) => {
    e.preventDefault();
    if (!e?.dataTransfer?.files?.length) {
      if (fileInput?.current) {
        fileInput.current.value = ''
      }
      return;
    }
    const droppedFiles = Array.from(e.dataTransfer.files);
    afterFileSelect(droppedFiles[0]);
  }

  const afterFileSelect = (selectedFile) => {
    const fileArr = selectedFile.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];

    if (fileExt !== 'zip') {
      toastError('File type not supported');
      if (fileInput?.current) {
        fileInput.current.value = ''
      }
      return;
    }

    setFile(selectedFile);
    setRemainingFiles(0);
    setUploadedFiles(0);
    setErrorFiles([]);
  }

  const onUploadAgain = () => {
    setFile(null);
    setRemainingFiles(0);
    setUploadedFiles(0);
    setErrorFiles([]);
  }

  return (
    <Layout activePage="Elements">
      <Heading
        heading="Update Element"
        subHeading={texts.subHeading}
      />

      <Styled.CardDiv>
        {loading ?
          <Styled.LoadingDiv>
            <div />
            <Styled.ImportWrapDiv>
              <Styled.ImportHeading>Element Uploading...</Styled.ImportHeading>
              <Styled.ProgressWrapDiv>
                <Styled.ImportDetail>{progressPercentage}% Complete</Styled.ImportDetail>
                <Styled.ProgressDiv>
                  <div style={{ width: `${progressPercentage}%` }} />
                </Styled.ProgressDiv>
              </Styled.ProgressWrapDiv>

              <Styled.RemainingDiv>
                <div />
                {remainingFiles} Remaining
              </Styled.RemainingDiv>
              <Styled.CompletedDiv>
                <div />
                {uploadedFiles} Uploaded
              </Styled.CompletedDiv>
              <Styled.ResultErrorDiv>
                <div />
                {errorFiles?.length || 0} Error
              </Styled.ResultErrorDiv>
            </Styled.ImportWrapDiv>
          </Styled.LoadingDiv>
          :
          uploadedFiles || errorFiles?.length ?
            <Styled.ResultDiv>
              <Styled.Text1Div>{`${uploadedFiles} Element${uploadedFiles > 1 ? 's' : ''} updated successfully.`}</Styled.Text1Div>
              {errorFiles.length ?
                <Styled.ErrorDiv>
                  <div>{`Error occurred in following ${errorFiles.length} elements:`}</div>
                  {errorFiles.map((item) => (
                    <div>{item}</div>
                  ))}
                </Styled.ErrorDiv>
                : ''
              }
            </Styled.ResultDiv>
            :
            <Styled.InputWrapLabel onDrop={onDropFile}>
              <Styled.UploadImgDiv style={{ margin: '0px 0px 12px 0px' }}>
                <img src="/upload-image-icon.svg" />
              </Styled.UploadImgDiv>

              <Styled.TextWrapDiv style={{ alignItems: 'center' }}>
                <Styled.Text1Div>Choose file to upload</Styled.Text1Div>
                <Styled.Text2Div>or drag and drop them here</Styled.Text2Div>
                <Styled.Text3Div>{file?.name || texts.upload}</Styled.Text3Div>
              </Styled.TextWrapDiv>
              <input type="file" onChange={onFileSelect} ref={fileInput} />
            </Styled.InputWrapLabel>
        }

        <Styled.DownloadCSVA href="https://bathxpertz.s3.ap-south-1.amazonaws.com/admin/Sample_excel_to_upload_elements.xlsx">
          <img src="/download-icon.svg" />
          Sample .xlsx File
        </Styled.DownloadCSVA>

        <Styled.NotesDiv>
          Note:
          <ul>
            {texts.notes.map((item) => <li>{item}</li>)}
          </ul>
        </Styled.NotesDiv>

        <Styled.BottomDiv>
          <SecondaryButton title="Back" to="/elements" />
          {uploadedFiles || errorFiles?.length ?
            <PrimaryButton
              title="Import Again"
              style={{ borderRadius: "8px", marginLeft: "20px" }}
              onClick={onUploadAgain}
            />
            :
            <PrimaryButton
              title="Update Now"
              style={{ borderRadius: "8px", marginLeft: "20px" }}
              onClick={onUpload}
            />
          }
        </Styled.BottomDiv>

      </Styled.CardDiv>

    </Layout>
  )
}



export default withRouter(BulkUpdateElements);