import styled from "styled-components";

export const MainWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: ${({active}) => active === 'true' ? '16px' : '16px'} 20px;
  margin: 28px 0px 0px 0px;
  border: 1px solid ${({active}) => active === 'true' ? '#000000' : '#bfbfbf'};
  transition: all 0.3s;
`;
export const HeadWrapDiv = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    display: ${({ showArrow }) => showArrow === 'true' ? 'block' : 'none'};
    right: 0px;
    top: ${({active}) => active === 'true' ? '8px' : '3px'};
    transform: ${({active}) => active === 'true' ? 'rotate(-135deg)' : 'rotate(45deg)'};
    height: 8px;
    width: 8px;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
    transition: all 0.3s;
  }
`;
export const HeadingDiv = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: ${({active}) => active === 'true' ? '600' : '400'};
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div:last-child {
    margin-right: 30px;
  }
`;
export const CompletedDiv = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: #118D8B;
  margin-left: 12px;
  position: relative;
  flex-shrink: 0;
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 8px;
    height: 10px;
    width: 5px;
    transform: rotate(45deg);
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
`;
export const ContentWrapDiv = styled.div`
  max-height: ${({active, maxHeight}) => active === 'true' ? maxHeight : '0px'};
  transition: all 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
export const ContentDiv = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
`;
export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ColumnDiv = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`;
export const InputLabelDiv = styled.div`
  margin: 24px 0px 12px 0px;
`;
