import styled from "styled-components";

export const CardDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
`;
export const InputWrapLabel = styled.label`
  min-height: 172px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  border: 1px dashed #bfbfbf;
  border-radius: 8px;
  padding: 0px 30px;
  text-align: center;
  cursor: pointer;
  margin: 32px;
  & > input {
    display: none;
  }
`;
export const UploadImgDiv = styled.div`
  width: 40px;
  height: 30px;
  background-color: #118D8B;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 16px;
    height: 15px;
  }
`;
export const TextWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Text1Div = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #118D8B;
  margin-bottom: 7px;
`;
export const Text2Div = styled.div`
  font-size: 12px;
  margin-bottom: 7px;
`;
export const Text3Div = styled.div`
  font-size: 12px;
  color: #808080;
`;
export const NotesDiv = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin: 0px 32px 32px 32px;
  & ul {
    margin: 0px;
    padding-left: 16px;
  }
`;
export const BottomDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #bfbfbf;
  padding: 32px;
`;
export const DownloadCSVA = styled.a`
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
  margin: 0px 0px 24px 32px;
  align-self: flex-start;
  & img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;
export const LoadingDiv = styled.div`
  min-height: 172px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  border: 1px dashed #bfbfbf;
  border-radius: 8px;
  padding: 12px 30px;
  text-align: center;
  margin: 32px 32px 20px 32px;
  & > div:first-child {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #118D8B;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    margin-right: 12px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
export const ResultDiv = styled.div`
  min-height: 172px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  border: 1px dashed #bfbfbf;
  border-radius: 8px;
  padding: 12px 30px;
  text-align: center;
  margin: 32px 32px 20px 32px;
`;
export const ErrorDiv = styled.div`
  color: red;
  font-size: 12px;
  text-align: left;
  & > div:first-child {
    font-size: 16px;
    font-weight: 600;
  }
`;
export const ImportWrapDiv = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  padding: 0px 18px 0px 18px;
  overflow: hidden;
  flex-shrink: 0;
  transition: all 0.3s;
  background-color: #ffffff;
`;
export const ImportHeading = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #118d8b;
  position: relative;
  cursor: pointer;
  padding: 12px 0px;
`;
export const ImportDetail = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;
export const ProgressWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
`;
export const ProgressDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 8px;
  border-radius: 8px;
  background-color: #c3e3e2;
  overflow: hidden;
  flex-grow: 1;
  & div {
    height: 8px;
    border-radius: 8px;
    background-color: #118D8B;
  }
`;
export const RemainingDiv = styled.div`
  font-size: 12px;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & div {
    height: 12px;
    width: 12px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 2px solid #b5b5b5;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      height: 6px;
      width: 6px;
      border-radius: 6px;
      background-color: #b5b5b5;
    }
  }
`;
export const CompletedDiv = styled.div`
  font-size: 12px;
  color: #118d8b;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  & div {
    height: 16px;
    width: 16px;
    background-color: #118d8b;
    border-radius: 16px;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      height: 7px;
      width: 3px;
      border-bottom: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      position: absolute;
      transform: rotate(45deg);
      margin-bottom: 2px;
    }
  }
`;
export const ResultErrorDiv = styled.div`
  font-size: 12px;
  color: red;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  & div {
    height: 16px;
    width: 16px;
    background-color: red;
    border-radius: 16px;
    margin-right: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: '';
      height: 10px;
      width: 2px;
      background-color: #ffffff;
      position: absolute;
      transform: rotate(45deg);
    }
    &:after {
      content: '';
      height: 10px;
      width: 2px;
      background-color: #ffffff;
      position: absolute;
      transform: rotate(-45deg);
    }
  }
`;
