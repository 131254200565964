import { useState, useRef, useEffect } from "react";
import JSZip from "jszip";
import { read, utils } from "xlsx";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import { fetchData, uploadMedia } from "../../Utilities/handleRequest";
import { toastError } from "../../Utilities/toast";
import * as Styled from "./styled";
import { withRouter } from "../../Utilities/withRouter";


const BulkUpdateThemes = () => {
  const [file, setFile] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [remainingFiles, setRemainingFiles] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [errorFiles, setErrorFiles] = useState([]);
  const [themeCategories, setThemeCategories] = useState([]);
  const [themeBrands, setThemeBrands] = useState([]);
  const [loading, setLoading] = useState(false);

  const fileInput = useRef(null);

  const texts = {
    subHeading: 'Upload the ZIP file of the models, images and xlsx.',
    upload: 'File format: .zip',
    notes: [
      'The .zip file must contain 3D models, images and .xlsx file including all required data.',
      'The models will be imported in the background and their respective statuses will be updated once the import is completed.'
    ]
  }

  const themeObject = [
    { key: 'title', name: 'Title', type: 'string' },
    { key: 'sku', name: 'SKU Code', type: 'string' },
    { key: 'description', name: 'Description', type: 'string' },
    { key: 'meta', name: 'Meta Data', type: 'string' },
    { key: 'category', name: 'Category', type: 'array' },
    { key: 'brands', name: 'Brands', type: 'array' },
    { key: 'shape', name: 'Shape', type: 'string' },
    { key: 'length', name: 'Length', type: 'string' },
    { key: 'width', name: 'Width', type: 'string' },
    { key: 'height', name: 'Height', type: 'string' },
    { key: 'cornerLength', name: 'Corner Length', type: 'string' },
    { key: 'cornerWidth', name: 'Corner Width', type: 'string' },
    { key: 'execution_bx_price', name: 'Execution BX Price', type: 'string' },
    { key: 'execution_bx_cost', name: 'Execution BX Cost', type: 'string' },
    { key: 'total_bx_price', name: 'Total BX Price', type: 'string' },
    { key: 'total_bx_cost', name: 'Total BX Cost', type: 'string' },
    { key: 'ios_model_url', name: 'IOS Model File', type: 'string' },
    { key: 'model_url', name: 'Android Model File', type: 'string' },
    { key: 'webgl_model_url', name: 'WEBGL Model File', type: 'string' },
    { key: 'theme_image', name: 'Theme Image Files', type: 'array' },
    { key: 'theme_catalogue_image', name: 'Catalogue Image Files', type: 'array' },
    { key: 'add_elements', name: 'Add Elements', type: 'array' },
    { key: 'product_bx_price', name: 'Product BX Price', type: 'string' },
    { key: 'product_bx_cost', name: 'Product BX Cost', type: 'string' },
  ];

  useEffect(() => {
    getThemeCategoriesBrands();
  }, []);

  const getThemeCategoriesBrands = async () => {
    let res = await fetchData('get', 'theme/get-theme-utils');
    setThemeCategories(res?.data?.category || []);
    setThemeBrands(res?.data?.brand || []);
  }

  const addNewCategoryBrand = async (themeData, files, zipContent, index) => {
    const keysToAdd = ['category', 'brands'];
    let data = {...themeData};

    for (let y = 0; y < keysToAdd.length; y++) {
      const key = keysToAdd[y];
      for (let x = 0; x < data[key].length; x++) {
        let arrFilter = null;
        let url = '';

        if (keysToAdd[y] === 'category') {
          arrFilter = themeCategories.filter((e) => e.name && data[key][x] && e.name.trim().toLowerCase() == data[key][x].trim().toLowerCase())[0];
          url = 'theme/add-category';
        } else {
          arrFilter = themeBrands.filter((e) => e.name && data[key][x] && e.name.trim().toLowerCase() == data[key][x].trim().toLowerCase())[0];
          url = 'theme/add-brand';
        }

        if (arrFilter) {
          data[key][x] = arrFilter.name;
        } else {
          const res = await fetchData('post', url, { name: data[key][x] });
          data[key][x] = res?.data?.name;
        }
      }
    }

    // setProgressPercentage(50);
    await uploadThemeImages(data, files, zipContent, index);
  }

  const uploadThemeImages = async (newData, files, zipContent, index) => {
    const uploadKeys = ['theme_image', 'theme_catalogue_image', 'model_url', 'ios_model_url', 'webgl_model_url'];
    let data = {...newData};

    for (let y = 0; y < uploadKeys.length; y++) {
      const key = uploadKeys[y];
      let mediaUrl = '';

      if (key === 'theme_image' || key === 'theme_catalogue_image') {
        const imageUrlArr = [];

        for (let x = 0; x < data[key].length; x++) {
          if (!data[key][x]?.includes('http')) {
            if (data[key] && data[key][x]) {
              const fileToUpload = files.find((filename) => filename.endsWith(`/${data[key][x]}`));

              if (fileToUpload) {
                const imageContent = await zipContent.files[fileToUpload].async('blob');
                const newFile = new File([imageContent], data[key][x]);
                mediaUrl = await uploadMedia(newFile);
              } else {
                toastError(`File not found (${data[key][x]})`);
              }
              imageUrlArr.push(mediaUrl);
            }
          } else {
            imageUrlArr.push(data[key][x]);
          }
        }

        data = { ...data, [key]: imageUrlArr };
      } else {
        if (!data[key]?.includes('http')) {
          const fileToUpload = files.find((filename) => filename.endsWith(`/${data[key]}`));

          if (fileToUpload) {
            const imageContent = await zipContent.files[fileToUpload].async('blob');
            const newFile = new File([imageContent], data[key]);
            mediaUrl = await uploadMedia(newFile);
            data = { ...data, [key]: mediaUrl };
          } else {
            toastError(`File not found (${data[key]})`);
          }
        } else {
          data = { ...data, [key]: data[key] };
        }
      }
    }

    // setProgressPercentage(75);
    await createTheme(data, index);
  }

  const createTheme = async (data, index) => {
    
    
    try {
      //let res = await fetchData('post', 'theme/create-theme', data);
      console.log("Data to upload is --------->",data,data.id);
      let res = await fetchData('post', `theme/update-theme/${data.id}`, data);
      //let res = await fetchData('post', `theme/update-theme/${data.id}`, data);
      if (res?.data) {
        setUploadedFiles((prev) => prev + 1);
        if (remainingFiles === 1) {
          toastError('The themes has been updated successfully!');
        }
      } else {
        let createError = '';
        if (res?.error?.data?.message?.includes(' * - ')) {
          createError = `Theme ${index + 1}: ${res?.error?.data?.message.split(' * - ')[1]}`;
        } else {
          createError = `Theme ${index + 1}: Something went wrong while updating`;
        }
        toastError(createError);
        setErrorFiles((prev) => [...prev, createError]);
      }
    } catch (err) {
      setErrorFiles((prev) => [...prev, `Theme ${index + 1}: Something went wrong while updating`]);
    }
    // setProgressPercentage(100);
    setRemainingFiles((prev) => prev - 1);
  }

  const isEmpty = (str) => {
    if (typeof str === 'string') {
      return str.trim() === "";
    } else {
      if (str === null || typeof str === 'undefined') {
        return true;
      } else {
        return false;
      }
    }
  }

  const onUpload = async () => {
    setProgressPercentage(0);
    if (loading) {
      return;
    }
    if (!file) {
      toastError('Please select zip file!');
      return;
    }
    setLoading(true);
    
    const zip = new JSZip();

    try {
      const zipContent = await zip.loadAsync(file);
      const files = Object.keys(zipContent.files).filter(
        (fileName) => !fileName.includes('__MACOSX') && !fileName.includes('/.')
      );

      const excelFileName = files.find((fileName) => fileName.endsWith('.xlsx'));
      
      if (excelFileName) {
        try {
          const excelFile = await zipContent.files[excelFileName].async('blob');
          
          const ab = await excelFile.arrayBuffer();
          const wb = read(ab);
          const ws = wb.Sheets[wb.SheetNames[0]];
          const fileData = utils.sheet_to_json(ws);
          
          if (!fileData?.length) {
            toastError('Excel file is empty.');
            setLoading(false);
            return;
          } else {
            setRemainingFiles(fileData.length);
          }
          
          for (let x = 0; x < fileData.length; x++) {
            const payload = {};
            let payloadError = '';
            const currentLine = fileData[x];

            for (let y = 0; y < themeObject.length; y++) {
              const obj = themeObject[y];

              if (obj.type === 'array') {
                const currentArray = currentLine?.[obj.name]?.toString()?.split(',')?.map(str => str?.trim() || '') || [];
  
                if (obj.key === 'add_elements') {
                  const addedElements = currentArray;
                  payload.add_elements = [];
                  payload.product_bx_cost = 0;
                  payload.product_bx_price = 0;
  
                  for (let z = 0; z < addedElements.length; z++) {
                    const selectedElement = await fetchData('get', `element/get-element-by-sku/${addedElements[z]}`);
                    if (selectedElement?.data?.id) {
                      payload.add_elements.push(selectedElement.data.id);
                      payload.product_bx_cost += selectedElement.data.mrp;
                      payload.product_bx_price += selectedElement.data.bx_price;
                    }
                  }
                } else {
                  payload[obj.key] = currentArray;
                }
  
                if (!payload?.[obj.key]?.[0]) {
                  payloadError = `'${obj.name}' not found in theme data`;
                  break;
                }
              } else if (obj.key === 'meta') {
                try {
                  JSON.parse(currentLine[obj.name]);
                } catch (e) {
                  payloadError = `'Meta Data' has invalid JSON in theme data`;
                  break;
                }
                payload.meta = currentLine[obj.name];
              } else if (obj.key === 'length' || obj.key === 'width' || obj.key === 'height') {
                if (!payload.size) {
                  payload.size = {};
                }
                if (isEmpty(currentLine?.[obj.name])) {
                  payloadError = `'${obj.name}' not found in theme data`;
                  break;
                }
                payload.size[obj.key] = typeof currentLine[obj.name] === 'string' ? currentLine[obj.name].trim() : currentLine[obj.name];
              } else if (obj.key === 'cornerLength' || obj.key === 'cornerWidth') {
                if (!currentLine?.['Shape'] === 'L Shape') {
                  payload.size[obj.key] = '';
                } else {
                  if (!payload.size) {
                    payload.size = {};
                  }
  
                  if (isEmpty(currentLine?.[obj.name])) {
                    payloadError = `'${obj.name}' not found in theme data`;
                    break;
                  }
                  payload.size[obj.key] = typeof currentLine[obj.name] === 'string' ? currentLine[obj.name].trim() : currentLine[obj.name];
                }
              } else {
                if (isEmpty(currentLine?.[obj.name])) {
                  payloadError = `'${obj.name}' not found in theme data`;
                  break;
                }
                payload[obj.key] = typeof currentLine[obj.name] === 'string' ? currentLine[obj.name].trim() : currentLine[obj.name] || null;
              }
            }
            
            if (currentLine?.ID) {
              payload.id = currentLine.ID;
            }

            if (payloadError === '') {
              // setProgressPercentage(25);
              await addNewCategoryBrand(payload, files, zipContent, x);
            } else {
              setErrorFiles((prev) => [...prev, `Theme ${x + 1}: ${payloadError}`]);
              setRemainingFiles((prev) => prev - 1);
            }
            setProgressPercentage(Math.ceil((100 / fileData.length) * (x + 1)));
          }
        } catch (uploadError) {
          toastError('Excel file is invalid.');
        }
      } else {
        toastError('Excel file not found in the ZIP.');
        setLoading(false);
        return;
      }
    } catch (err) {
      console.log(err)
      toastError('Failed to unzip the file.');
    }
    setLoading(false);
  }

  const onFileSelect = (e) => {
    if (!e?.target?.files?.length) {
      if (fileInput?.current) {
        fileInput.current.value = ''
      }
      return;
    }
    afterFileSelect(e.target.files[0]);
  }

  const onDropFile = (e) => {
    e.preventDefault();
    if (!e?.dataTransfer?.files?.length) {
      if (fileInput?.current) {
        fileInput.current.value = ''
      }
      return;
    }
    const droppedFiles = Array.from(e.dataTransfer.files);
    afterFileSelect(droppedFiles[0]);
  }

  const afterFileSelect = (selectedFile) => {
    const fileArr = selectedFile.name.split('.');
    const fileExt = fileArr[fileArr.length - 1];
    
    if (fileExt !== 'zip') {
      toastError('File type not supported');
      if (fileInput?.current) {
        fileInput.current.value = ''
      }
      return;
    }

    setFile(selectedFile);
    setRemainingFiles(0);
    setUploadedFiles(0);
    setErrorFiles([]);
  }

  const onUploadAgain = () => {
    setFile(null);
    setRemainingFiles(0);
    setUploadedFiles(0);
    setErrorFiles([]);
  }

  return (
    <Layout activePage="Themes">
      <Heading
        heading="Update Theme"
        subHeading={texts.subHeading}
      />

      <Styled.CardDiv>
        { loading ?
          <Styled.LoadingDiv>
            <div />
            <Styled.ImportWrapDiv>
              <Styled.ImportHeading>Theme Uploading...</Styled.ImportHeading>
              <Styled.ProgressWrapDiv>
                <Styled.ImportDetail>{progressPercentage}% Complete</Styled.ImportDetail>
                <Styled.ProgressDiv>
                  <div style={{ width: `${progressPercentage}%` }} />
                </Styled.ProgressDiv>
              </Styled.ProgressWrapDiv>

              <Styled.RemainingDiv>
                <div />
                {remainingFiles} Remaining
              </Styled.RemainingDiv>
              <Styled.CompletedDiv>
                <div />
                {uploadedFiles} Uploaded
              </Styled.CompletedDiv>
              <Styled.ResultErrorDiv>
                <div />
                {errorFiles?.length || 0} Error
              </Styled.ResultErrorDiv>
            </Styled.ImportWrapDiv>
          </Styled.LoadingDiv>
        :
          uploadedFiles || errorFiles?.length ?
          <Styled.ResultDiv>
            <Styled.Text1Div>{`${uploadedFiles} Theme${uploadedFiles > 1 ? 's' : ''} updated successfully.`}</Styled.Text1Div>
            { errorFiles.length ?
              <Styled.ErrorDiv>
                <div>{`Error occurred in following ${errorFiles.length} themes:`}</div>
                { errorFiles.map((item) => (
                  <div>{item}</div>
                ))}
              </Styled.ErrorDiv>
            : ''
            }
          </Styled.ResultDiv>
          :
          <Styled.InputWrapLabel onDrop={onDropFile}>
            <Styled.UploadImgDiv style={{ margin: "0px 0px 12px 0px" }}>
              <img src="/upload-image-icon.svg" />
            </Styled.UploadImgDiv>

            <Styled.TextWrapDiv style={{ alignItems: "center" }}>
              <Styled.Text1Div>Choose file to upload</Styled.Text1Div>
              <Styled.Text2Div>or drag and drop them here</Styled.Text2Div>
              <Styled.Text3Div>{file?.name || texts.upload}</Styled.Text3Div>
            </Styled.TextWrapDiv>
            <input type="file" onChange={onFileSelect} ref={fileInput} />
          </Styled.InputWrapLabel>
        }

        <Styled.DownloadCSVA href="https://bathxpertz.s3.ap-south-1.amazonaws.com/admin/Sample_excel_to_upload_elements.xlsx">
          <img src="/download-icon.svg" />
          Sample .xlsx File
        </Styled.DownloadCSVA>

        <Styled.NotesDiv>
          Note:
          <ul>
            {texts.notes.map((item) => <li>{item}</li>)}
          </ul>
        </Styled.NotesDiv>
        <Styled.BottomDiv>
          <SecondaryButton to="/themes" title="Back" />
          { uploadedFiles || errorFiles?.length ?
            <PrimaryButton
              title="Import Again"
              style={{ borderRadius: "8px", marginLeft: "20px" }}
              onClick={onUploadAgain}
            />
          :
            <PrimaryButton
              title="Import Now"
              style={{ borderRadius: "8px", marginLeft: "20px" }}
              onClick={onUpload}
            />
          }
        </Styled.BottomDiv>
      </Styled.CardDiv>
    </Layout>
  );
};

export default withRouter(BulkUpdateThemes);