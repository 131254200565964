import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import SearchInput from "../../Components/SearchInput";
import Table from "../../Components/Table";
import Modal from "../../Components/Modal";
import { withRouter } from "../../Utilities/withRouter";
import * as CommonStyled from "../../Common/commonStyled";
import { fetchData } from "../../Utilities/handleRequest";
import PrimaryButton from "../../Components/PrimaryButton";
import Loader from "../../Components/Loader";
import * as XLSX from 'xlsx'; // Add this import
import { toastError } from "../../Utilities/toast";
import SecondaryButton from "../../Components/SecondaryButton";


const Appointments = ({ navigate }) => {
  const [searchText, setSearchText] = useState('');
  const [appointmentMessage, setAppointmentMessage] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalAppointments, setTotalAppointments] = useState(0);

  const [loading, setLoading] = useState(true);
  const [fetching,setFetching] = useState(false);


  useEffect(() => {
    setPageNo(1);
    getAppointments(1);
  }, [searchText, pageSize]);

  const getAppointments = async (activePage) => {
    setLoading(true);
    let searchParams = '';
    searchText && (searchParams += `&search=${searchText}`);
    let res = await fetchData('get', `appointment/get-appointments?pageNo=${activePage}&limit=${pageSize}${searchParams}`);
    setAppointments(res?.data?.appointments)
    setTotalAppointments(res?.data?.count);
    setLoading(false);
  }

  const hideModal = () => {
    setShowMessageModal(false);
    setAppointmentMessage(null);
  }

  const onPageChange = (value) => {
    getAppointments(value);
    setPageNo(value);
  }

  const onMessageView = (row) => {
    setAppointmentMessage(row?.message);
    setShowMessageModal(true);
  }

  const getTotalExistingAppointments = async () => {
    try {
      setFetching(true);
      let allData = [];
      const promises = [];

      for (let currentPage = 1; currentPage <= Math.ceil(totalAppointments / pageSize); currentPage++) {
        let fullUrl = `appointment/get-appointments?pageNo=${currentPage}&limit=${pageSize}`;
        if (searchText) {
          fullUrl += `&search=${searchText}`;
        }
        promises.push(fetchData('get', fullUrl));
      }

      const results = await Promise.all(promises);
      results.forEach(result => {
        allData = allData.concat(result?.data?.appointments || []);
      });

      // Transform data for Excel export
      
      const excelData = allData.map(appointment => ({
        'Id':appointment.id || "",
        'Client Id':appointment.client_user_id || "",
        'Customer Name': appointment.name || "",
        'Email': appointment.email || "",
        'Mobile No': appointment.phone_number || "",
        'Address': appointment.address || "",
        //'Date & Time':`${appointment.date}T${appointment.time}`,
        // ... existing code ...
        'Date & Time': (() => {
          // Combine date and time correctly
          const combinedDateTime = `${appointment.date.split('T')[0]}T${appointment.time}`;
          const dateObject = new Date(combinedDateTime);
          return isNaN(dateObject) ? "Invalid Date" : dateObject.toLocaleString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
          }).replace(',', ''); // Remove the comma between date and time
      })(),
// ... existing code ...
        'Message': appointment.message || ""
      }));

      // Create workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Appointments');

      // Generate Excel file
      XLSX.writeFile(wb, 'Appointments.xlsx');

    } catch (error) {
      console.error('Export failed:', error);
      toastError('Export failed. Please try again.');
    } finally {
      setFetching(false);
    }
  }

  const headings = [
    { name: 'Customer Name', key: 'name', type: 'wrapText', sortBy: false },
    { name: 'Email', key: 'email', type: 'email', sortBy: false },
    { name: 'Mobile', key: 'phone_number', type: 'text', sortBy: false },
    { name: 'Address', key: 'address', type: 'wrapText', sortBy: false },
    { name: 'Date & Time', datekey: 'date', timekey: 'time', type: 'dateTime', sortBy: false },
    { name: 'Message', type: 'action', actions: { view: { onClick: onMessageView, icon : '/message-view-icon.svg', activeIcon: '/message-view-active.svg', toolText:'View message'} } }
  ];

  return (
    <Layout activePage="Appointments" style={{ display: 'flex', flexDirection: 'column' }}>
      <Modal
        show={showMessageModal}
        hide={hideModal}
        heading={"Message"}
        hideFooter={true}
        width="446px"
      >
        {appointmentMessage}
      </Modal>

      <Heading
        heading="Appointments"
        subHeading="This is where you can view the list of the appointments requested by the customers."
      >
        <div style={{ display: 'flex' }}>
          <Loader loading={fetching} />
          <SecondaryButton
            title="Update Appointments"
            style={{ marginLeft: '20px',borderRadius: '40px', color: '#118d8b', }}
            onClick={() => navigate('/bulk-edit-appointments')}
          />
          <PrimaryButton
            title="Export"
            style={{ marginLeft: '20px' }}
            onClick={getTotalExistingAppointments}
          />
        </div>
      
      </Heading>

      <CommonStyled.FilterDiv style={{flexDirection: 'row-reverse'}}>
        <SearchInput
          onInputChange={(e) => setSearchText(e.target.value)}
          placeholder={'Search from name'} 
        />
      </CommonStyled.FilterDiv>

      {!appointments?.length && !searchText ?
        <>
          {!loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/appointments-color-icon.svg" alt="" />
              <div>No appointment booked so far</div>
            </CommonStyled.NoDataFoundDiv>
            : ''
          }
        </>
        :
        <Table
          rows={appointments}
          headings={headings}
          page={pageNo}
          limit={pageSize}
          count={totalAppointments}
          onPageChange={onPageChange}
          onLimitChange={(value) => setPageSize(value)}
          noDataImage="/no-appointment-found-icon.svg"
          noDataText="No appointment found"
          loading={loading}
        />
      }

    </Layout>
  )
}

export default withRouter(Appointments);