import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import SingleSelect from "../../Components/SingleSelect";
import Multiselect from "../../Components/MultiSelect";
import UploadImage from "../../Components/UploadImage";
import Upload3DModal from "../../Components/Upload3DModal";
import Loader from "../../Components/Loader";
import Input from "../../Components/Input";
import Textarea from "../../Components/Textarea";
import Table from "../../Components/ThemeTable";
import SearchInput from "../../Components/SearchInput";
import { shapes } from "../../StaticData/databases";
import { numberWithCommas } from "../../Utilities/utilsFunctions";
import { fetchData, uploadMedia } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import * as CommonStyled from "../../Common/commonStyled";
import * as Styled from "./styled";
import { toastError } from "../../Utilities/toast";

const EditThemes = ({ params, navigate, location }) => {
  const pathname = location.pathname.split('/')[1];

  const [themeData, setThemeData] = useState({
    title: '',
    sku: '',
    description: '',
    category: [],
    brands: [],
    execution_bx_price: '',
    execution_bx_cost: '',
    total_bx_price: '',
    theme_image: [],
    meta: '',
    size: {
      length: '',
      width: '',
      height: '',
      cornerLength: '',
      cornerWidth: ''
    },
    model_url: '',
    ios_model_url: '',
    webgl_model_url: '',
    product_bx_price: '',
    product_bx_cost: '',
    total_bx_cost: '',
    theme_catalogue_image: []
  });
  const [loading, setLoading] = useState(false);
  const [themeError, setThemeError] = useState({});
  const [themeCategories, setThemeCategories] = useState([]);
  const [themeBrands, setThemeBrands] = useState([]);

  const [filteredElements, setFilteredElements] = useState([]);

  const [elements, setElements] = useState([]);
  const [allElements, setAllElements] = useState([]);

  const [activeTab, setActiveTab] = useState('all');
  const [elementLoading, setElementLoading] = useState(false);

  const [elementCategories, setElementCategories] = useState([]);
  const [elementBrands, setElementBrands] = useState([]);
  const [elementSubCategories, setElementSubCategories] = useState([]);
  const [themeElementSubCategories, setThemeElementSubCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [searchText, setSearchText] = useState('');

  const [selectedElementCategory, setSelectedElementCategory] = useState('');
  const [selectedElementSubCategory, setSelectedElementSubCategory] = useState('');
  const [selectedElementBrand, setSelectedElementBrand] = useState('');
  const [elementSearchText, setElementSearchText] = useState('');

  const [elementPage, setElementPage] = useState(1);
  const [elementLimit, setElementLimit] = useState(10);
  const [elementCount, setElementCount] = useState(0);

  const numberTypeId = ['execution_bx_price', 'execution_bx_cost', 'total_bx_price', 'product_bx_price', 'product_bx_cost', 'total_bx_cost', 'length', 'width', 'height'];

  useEffect(() => {
    if (params.id) {
      getThemes(params.id);
    }
    getThemeCategoriesBrands();
    getAllElements();
    getElementCategories();
    getElementBrands();
  }, [params]);

  useEffect(() => {
    setAllElements([...allElements]);
    const data = {...themeData};
    data.product_bx_cost = getTotalOfBundleElements('mrp');
    data.product_bx_price = getTotalOfBundleElements('bx_price');
    data.procurement_cost = getTotalOfBundleElements('procurement_cost');
    setThemeData(data);

    const elementsFilter = elements?.filter((e) => (
      (!selectedCategory || e.category_id === selectedCategory) &&
      (!selectedSubCategory || e.subcategory_id === selectedSubCategory) &&
      (!selectedBrand || e.brands === selectedBrand) &&
      (!searchText || e.title.toLowerCase().includes(searchText.toLowerCase()))
    ));

    setFilteredElements(elementsFilter);
  }, [elements]);

  useEffect(() => {
    const elementsFilter = elements?.filter((e) => (
      (!selectedCategory || e.category_id === selectedCategory) &&
      (!selectedSubCategory || e.subcategory_id === selectedSubCategory) &&
      (!selectedBrand || e.brands === selectedBrand) &&
      (!searchText || e.title.toLowerCase().includes(searchText.toLowerCase()))
    ));

    setFilteredElements(elementsFilter);
  }, [selectedCategory, selectedSubCategory, selectedBrand, searchText]);

  useEffect(() => {
    setElementPage(1);
    getAllElements(1);
  }, [selectedElementCategory, selectedElementSubCategory, selectedElementBrand, elementSearchText, elementLimit]);

  useEffect(() => {
    if (selectedElementCategory) {
      getElementSubCategories('element', selectedElementCategory);
    }
  }, [selectedElementCategory]);

  useEffect(() => {
    if (selectedCategory) {
      getElementSubCategories('theme', selectedCategory);
    }
  }, [selectedCategory]);

  const getThemeCategoriesBrands = async () => {
    let res = await fetchData('get', 'theme/get-theme-utils');
    setThemeCategories(res?.data?.category || []);
    setThemeBrands(res?.data?.brand || []);
  }

  const getElementCategories = async () => {
    let res = await fetchData('get', 'element/get-all-category');
    setElementCategories([{ id: '', name: 'All Categories' }, ...res?.data?.category || []]);
  }

  const getElementBrands = async () => {
    let res = await fetchData('get', 'element/get-elements-utils');
    if (res?.data?.brands) {
      const brandsArray = res.data.brands.map((b) => ({ id: b.name, name: b.name }));
      setElementBrands([{ id: '', name: 'All Brands' }, ...brandsArray]);
    }
  }

  const getElementSubCategories = async (mode, categoryId) => {
    if (!categoryId) return;
    let res = await fetchData('get', `element/get-all-subcategory?category_id=${categoryId}`);
    if (res?.data?.subcategory && !res?.data?.subcategory?.length) {
      toastError('No subcategory found!');
    } else {
      if (mode === 'theme') {
        setThemeElementSubCategories([{ id: '', name: 'All SubCategories' }, ...res?.data?.subcategory || []]);
      } else {
        setElementSubCategories([{ id: '', name: 'All SubCategories' }, ...res?.data?.subcategory || []]);
      }
    }
  }

  const getThemes = async (id) => {
    setLoading(true);
    let thm = await fetchData('get', `theme/get-theme/${id}`);

    if (thm?.data?.meta) {
      thm.data.meta = JSON.stringify(thm.data.meta);
    } else {
      thm.data.meta = '';
    }

    if (thm?.data?.elements) {
      console.log("All the available elements in the theme are -------->",thm?.data?.elements);
      setElements(thm.data.elements);
    }
    
    setThemeData(thm?.data);
    setLoading(false);
  }

  const getAllElements = async (pageNo) => {
    setElementLoading(true);
    let url = `element/get-all-elements?pageNo=${pageNo || elementPage}&limit=${elementLimit}`;
    if (selectedElementCategory) {
      url += `&category_id=${selectedElementCategory}`;
    }
    if (selectedElementSubCategory) {
      url += `&subcategory_id=${selectedElementSubCategory}`;
    }
    if (selectedElementBrand) {
      url += `&brand=${selectedElementBrand}`;
    }
    if (elementSearchText) {
      url += `&title=${elementSearchText}`;
    }

    let res = await fetchData('get', url);
    //console.log("Searching for the element here------>",res);
    setAllElements(res?.data?.elements || []);
    setElementCount(res?.data?.count || 0);
    setElementLoading(false);
  }

  const getInput = (id, placeholder) => {
    let inputType = null;
    let disabled = false;

    if (id === 'product_bx_price' || id === 'product_bx_cost') {
      disabled = true;
    }

    if (id === 'title') {
      inputType = 'noSymbol';
    } else if (numberTypeId.includes(id)) {
      inputType = 'number';
    }

    return <Input
      id={id}
      value={themeData[id]}
      onChange={(e) => onInputChange(e)}
      placeholder={placeholder}
      error={themeError[id]}
      type={inputType}
      disabled={disabled}
    />
  }

  const getTotalOfBundleElements = (name) => {
    if (!elements || !elements.length) return 0;
    let value = 0;
    for (let x = 0; x < elements.length; x+= 1) {
      value += Number(elements[x][name]) || 0;
    }
    return value;
  }

  const onInputChange = (e) => {
    if (!e || !e.target) return;
    const { id, value } = e.target;
    if (id === 'height' || id === 'length' || id === 'width' || id === 'cornerLength' || id === 'cornerWidth') {
      if (value === '' || (/^\d+$/.test(value) && value !== '0')) {
        setThemeData({ ...themeData, size: {...themeData.size, [id]: value} });
      }
    } else {
      setThemeData({ ...themeData, [id]: value });
    }
    removeFromError(id);
  }

  const onImageSelect = (value, id) => {
    setThemeData({ ...themeData, [id]: value });
    removeFromError(id);
  }

  const onMultiSelect = (value, id) => {
    setThemeData({ ...themeData, [id]: [...themeData[id], value] });
    removeFromError(id);
  }

  const onMultiSelectRemove = (value, id) => {
    const selectedArr = themeData[id].filter((val) => val !== value);
    setThemeData({ ...themeData, [id]: selectedArr });
  }

  const onSingleSelect = (value, id) => {
    let data = {...themeData};
    data.size.cornerLength = '';
    data.size.cornerWidth = '';

    setThemeData({ ...data, [id]: value });
    removeFromError(id);
  }

  const removeFromError = (id) => {
    if (themeError[id]) {
      const errorData = {...themeError};
      delete errorData[id];
      setThemeError(errorData);
    }
  }

  const onElementsPageChange = (value) => {
    getAllElements(value);
    setElementPage(value);
  }

  const onRowAdd = (row) => {
    const list = [...elements];
    const index = list.findIndex(e => e.id === row.id);
    if (index === -1) {
      list.push(row);
    }
    setElements([...list]);
  }

  const onRowRemove = (row) => {
    const list = [...elements];
    const index = list.findIndex(e => e.id === row.id);
    if (index > -1) {
      list.splice(index, 1);
    }
    setElements(list);
  }

  const onElementCategoryChange = (value) => {
    setSelectedElementCategory(value);
    setSelectedElementSubCategory('');
    setElementSubCategories([]);
  }

  const onCategoryChange = (value) => {
    setSelectedCategory(value);
    setSelectedSubCategory('');
    setElementSubCategories([]);
  }

  const getErrorMessage = (id, val) => {
    const value = typeof val === 'string' ? val.trim() : val;

    if (id === 'title') {
      if (!value) return 'Enter a title';
    } else if (id === 'sku') {
      if (!value) return 'Enter sku code';
    } else if (id === 'description') {
      if (!value) return 'Enter description';
    } else if (id === 'meta') {
      try {
        JSON.parse(value);
      } catch (e) {
        return 'Enter valid json object';
      }
    } else if (id === 'category') {
      if (!value || !value.length) return 'Select minimum 1 category';
    } else if (id === 'brands') {
      if (!value || !value.length) return 'Select minimum 1 brand';
    } else if (id === 'execution_bx_price') {
      if (!value) return 'Enter execution BX price';
    } else if (id === 'execution_bx_cost') {
      if (!value) return 'Enter execution BX cost';
    } else if (id === 'total_bx_price') {
      if (!value) return 'Enter total BX price';
    } else if (id === 'theme_image') {
      if (!value || !value.length) return 'Select minimum 1 theme image';
    } else if (id === 'length') {
      if (!value) return 'Enter a valid length';
    } else if (id === 'width') {
      if (!value) return 'Enter a valid width';
    } else if (id === 'height') {
      if (!value) return 'Enter a valid height';
    } else if (id === 'cornerLength') {
      if (themeData.shape === 'L Shape' && !value) return 'Enter a valid corner length';
    } else if (id === 'cornerWidth') {
      if (themeData.shape === 'L Shape' && !value) return 'Enter a valid corner width';
    } else if (id === 'shape') {
      if (!value) return 'Select shape';
    } else if (id === 'model_url') {
      if (!value) return 'Select android unity package';
    } else if (id === 'ios_model_url') {
      if (!value) return 'Select IOS unity package';
    } else if (id === 'webgl_model_url') {
      if (!value) return 'Select WebGL unity package';
    } else if (id === 'product_bx_price') {
      if (!value) return 'Enter product BX price';
    } else if (id === 'product_bx_cost') {
      if (!value) return 'Enter product BX cost';
    } else if (id === 'total_bx_cost') {
      if (!value) return 'Enter total BX cost';
    } else if (id === 'theme_catalogue_image') {
      if (!value || !value.length) return 'Select minimum 1 theme catalogue image';
    }
    return '';
  }

  const validateData = () => {
    if (!elements?.length) {
      toastError('Select minimum 1 element');
      return;
    }

    const errorData = {};

    for (var key in themeData) {
      if (themeData.hasOwnProperty(key)) {
        if (key === 'size') {
          for (var sizeKey in themeData.size) {
            if (themeData.size.hasOwnProperty(sizeKey)) {
              if (getErrorMessage(sizeKey, themeData.size[sizeKey])) {
                errorData[sizeKey] = getErrorMessage(sizeKey, themeData.size[sizeKey]);
              } else {
                delete errorData[sizeKey];
              }
            }
          }
        } else {
          if (getErrorMessage(key, themeData[key])) {
            errorData[key] = getErrorMessage(key, themeData[key]);
          } else {
            delete errorData[key];
          }
        }
      }
    }

    if (Object.keys(errorData).length === 0) {
      setLoading(true);
      addNewCategoryBrand();
    } else {
      setThemeError(errorData);
    }
  }

  const addNewCategoryBrand = async () => {
    const keysToAdd = ['category', 'brands'];
    let data = {...themeData};

    for (let y = 0; y < keysToAdd.length; y++) {
      const key = keysToAdd[y];
      for (let x = 0; x < data[key].length; x++) {
        let arrFilter = null;
        let url = '';

        if (keysToAdd[y] === 'category') {
          arrFilter = themeCategories.filter((e) => e.name && data[key][x] && e.name.trim().toLowerCase() == data[key][x].trim().toLowerCase())[0];
          url = 'theme/add-category';
        } else {
          arrFilter = themeBrands.filter((e) => e.name && data[key][x] && e.name.trim().toLowerCase() == data[key][x].trim().toLowerCase())[0];
          url = 'theme/add-brand';
        }

        if (arrFilter) {
          data[key][x] = arrFilter.name;
        } else {
          const res = await fetchData('post', url, { name: data[key][x] });
          data[key][x] = res?.data?.name;
        }
      }
    }

    uploadThemeImages(data);
  }

  const uploadThemeImages = async (newData) => {
    console.log("Data to update is ----->",newData)
    const uploadKeys = ['theme_image', 'theme_catalogue_image', 'model_url', 'ios_model_url', 'webgl_model_url'];
    let data = {...newData};

    for (let y = 0; y < uploadKeys.length; y++) {
      const key = uploadKeys[y];
      let mediaUrl = '';

      if (key === 'theme_image' || key === 'theme_catalogue_image') {
        const imageUrlArr = [];

        for (let x = 0; x < data[key].length; x++) {
          if (data[key] && data[key][x]) {
            mediaUrl = await uploadMedia(data[key][x]);
            imageUrlArr.push(mediaUrl);
          }
        }
        data = { ...data, [key]: imageUrlArr };
      } else {
        mediaUrl = await uploadMedia(data[key]);
        data = { ...data, [key]: mediaUrl };
      }
    }

    adjustElements(data);
  }

  const adjustElements = (dataParams) => {
    if (!dataParams) {
      setLoading(false);
      return;
    }

    const data = {...dataParams};

    const element_ids = [...dataParams?.element_ids || []];
    const add_elements = [];

    for (let x = 0; x < elements?.length; x++) {
      if (!element_ids?.includes(elements[x].id)) {
        add_elements.push(elements[x].id);
      } else {
        const index = element_ids.indexOf(elements[x].id);
        element_ids.splice(index, 1);
      }
    }

    if (element_ids?.length) {
      data.delete_elements = [...element_ids];
    }

    if (add_elements?.length) {
      data.add_elements = add_elements;
    }

    delete data.element_ids;
    delete data.elements;
    delete data.procurement_cost;

    if (pathname === 'add-themes') {
      createTheme(data);
    } else if (pathname === 'edit-themes') {
      console.log("Hello guyz the updated theme values are---------->",data)
      updateTheme(data);
    }
  }

  const createTheme = async (data) => {
    let res = await fetchData('post', 'theme/create-theme', data);
    if (res?.data) {
      toastError('The theme has been added successfully!');
      navigate('/themes');
    } else {
      toastError(res?.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const updateTheme = async (data) => {
    console.log("Data in the fiels is ------>",data)
    let res = await fetchData('post', `theme/update-theme/${data.id}`, data);
    if (res?.data) {
      toastError('The theme has been updated successfully!');
      navigate('/themes');
    } else {
      toastError(res?.message || 'Something went wrong');
    }
    setLoading(false);
  }

  const headings = [
    { name: 'Element Title', key: 'title', type: 'text', sortBy: true },
    { name: 'SKU Code', key: 'sku', type: 'text', sortBy: true },
    { name: 'Brand', key: 'brands', type: 'text', sortBy: true },
    { name: 'Finish', key: 'finishing', type: 'text', sortBy: true },
    { name: 'MRP', key: 'mrp', type: 'rupees', sortBy: true },
    { name: 'BX Price', key: 'bx_price', type: 'rupees', sortBy: true },
    { name: 'Procurement Cost', key: 'procurement_cost', type: 'rupees', sortBy: true },
    { name: 'Action', type: 'action', actions: { addRow: {onClick: onRowAdd, toolText: 'Add element'}, removeRow: {onClick: onRowRemove, toolText: 'Remove element'}}}
  ];

  return (
    <Layout activePage="Themes" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Heading
        heading={pathname === 'add-themes' ? 'Add New Theme' : 'Update Theme'}
        subHeading={pathname === 'add-themes' ? 'This is where you can add new bathroom theme.' : 'This is where you can update bathroom theme.'}
        backTo={{ text: 'Back to all themes', url: '/themes' }}
      />

      <Styled.CardDiv>
        <Styled.CardContentDiv>
          <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
            <Styled.ColumnDiv style={{ width: '48%' }}>
              <Styled.TitleDiv notEmpty>Theme Title</Styled.TitleDiv>
              {getInput('title', 'Enter theme title')}
            </Styled.ColumnDiv>

            <Styled.ColumnDiv style={{ width: '48%' }}>
              <Styled.TitleDiv notEmpty>Theme SKU</Styled.TitleDiv>
              {getInput('sku', 'Enter theme ID')}
            </Styled.ColumnDiv>
          </Styled.RowDiv>

          <Styled.ColumnDiv>
            <Styled.TitleDiv notEmpty>Description</Styled.TitleDiv>
            <Textarea
              id="description"
              value={themeData.description}
              onChange={(e) => onInputChange(e)}
              style={{ height: '117px', padding: '12px 20px' }}
              placeholder="Enter description here"
              error={themeError.description}
            />
          </Styled.ColumnDiv>

          <Styled.ColumnDiv>
            <Styled.TitleDiv notEmpty>JSON Object</Styled.TitleDiv>
            <Textarea
              id="meta"
              value={themeData.meta}
              onChange={(e) => onInputChange(e)}
              style={{ height: '117px', padding: '12px 20px' }}
              placeholder="Enter json object here"
              error={themeError.meta}
            />
          </Styled.ColumnDiv>

          <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
            <Styled.ColumnDiv style={{ width: '48%', flexShrink: '0' }}>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Category</Styled.TitleDiv>
                <Multiselect
                  placeholder="Select category"
                  options={themeCategories}
                  selected={themeData.category}
                  onSelect={(value) => onMultiSelect(value, 'category')}
                  onRemove={(value) => onMultiSelectRemove(value, 'category')}
                  error={themeError.category}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Brand(s)</Styled.TitleDiv>
                <Multiselect
                  placeholder="Select brand(s)"
                  options={themeBrands}
                  selected={themeData.brands}
                  onSelect={(value) => onMultiSelect(value, 'brands')}
                  onRemove={(value) => onMultiSelectRemove(value, 'brands')}
                  error={themeError.brands}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Execution BX Price</Styled.TitleDiv>
                {getInput('execution_bx_price', 'Enter execution BX price (e.g. 100000)')}
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Execution BX Cost</Styled.TitleDiv>
                {getInput('execution_bx_cost', 'Enter execution BX cost (e.g. 100000)')}
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Total BX Price</Styled.TitleDiv>
                {getInput('total_bx_price', 'Enter total BX price (e.g. 100000)')}
              </Styled.ColumnDiv>

              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>IOS Unity Package</Styled.TitleDiv>
                <Upload3DModal
                  selected={themeData.ios_model_url}
                  onSelect={(value) => onImageSelect(value, 'ios_model_url')}
                  error={themeError.ios_model_url}
                />
              </Styled.ColumnDiv>

              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Theme Image</Styled.TitleDiv>
                <UploadImage
                  selected={themeData.theme_image}
                  onSelect={(value) => onImageSelect(value, 'theme_image')}
                  error={themeError.theme_image}
                />
              </Styled.ColumnDiv>
            </Styled.ColumnDiv>

            <Styled.ColumnDiv style={{ width: '48%', flexShrink: '0' }}>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Size</Styled.TitleDiv>
                <Styled.RowDiv style={{ justifyContent: 'space-between' }}>
                  <Styled.SizeDiv style={themeError.length ? { border: '1px solid #DB1616' } : {}}>
                    <div>L</div>
                    <input id="length" value={themeData.size.length} onChange={(e) => onInputChange(e)} placeholder="Length(ft)" />
                  </Styled.SizeDiv>
                  <Styled.SizeDiv style={themeError.width ? { border: '1px solid #DB1616' } : {}}>
                    <div>B</div>
                    <input id="width" value={themeData.size.width} onChange={(e) => onInputChange(e)} placeholder="Width(ft)" />
                  </Styled.SizeDiv>
                  <Styled.SizeDiv style={themeError.height ? { border: '1px solid #DB1616' } : {}}>
                    <div>H</div>
                    <input id="height" value={themeData.size.height} onChange={(e) => onInputChange(e)} placeholder="Height(ft)" />
                  </Styled.SizeDiv>
                </Styled.RowDiv>

                { themeData.shape === 'L Shape' ?
                  <Styled.RowDiv style={{ marginTop: '12px', justifyContent: 'space-between' }}>
                    <Styled.SizeDiv style={themeError.cornerLength ? { border: '1px solid #DB1616', width: '48%' } : {width: '48%'}}>
                      <div>CL</div>
                      <input id="cornerLength" value={themeData.size.cornerLength || ''} onChange={(e) => onInputChange(e)} placeholder="Corner Length(ft)" />
                    </Styled.SizeDiv>
                    <Styled.SizeDiv style={themeError.cornerWidth ? { border: '1px solid #DB1616', marginLeft: '5%', width: '48%' } : {width: '48%', marginLeft: '5%'}}>
                      <div>CB</div>
                      <input id="cornerWidth" value={themeData.size.cornerWidth || ''} onChange={(e) => onInputChange(e)} placeholder="Corner Width(ft)" />
                    </Styled.SizeDiv>
                  </Styled.RowDiv>
                : ''
                }
                { themeError.length || themeError.height || themeError.width ||
                  (themeData.shape === 'L Shape' && themeError.cornerLength) || (themeData.shape === 'L Shape' && themeError.cornerWidth) ?
                  <Styled.ErrorDiv>Enter a valid size</Styled.ErrorDiv>
                : ''
                }
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Shape</Styled.TitleDiv>
                <SingleSelect
                  placeholder="Select shape"
                  onSelect={(value) => onSingleSelect(value, 'shape')}
                  data={shapes}
                  style={{ width: '100%' }}
                  selectedId={themeData.shape}
                  error={themeError.shape}
                />
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Product BX Price</Styled.TitleDiv>
                {getInput('product_bx_price', 'Enter product BX price (e.g. 100000)')}
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Product MRP</Styled.TitleDiv>
                {getInput('product_bx_cost', 'Enter product BX cost (e.g. 100000)')}
              </Styled.ColumnDiv>
              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Total BX Cost</Styled.TitleDiv>
                {getInput('total_bx_cost', 'Enter total BX cost (e.g. 100000)')}
              </Styled.ColumnDiv>

              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Android Unity Package</Styled.TitleDiv>
                <Upload3DModal
                  selected={themeData.model_url}
                  onSelect={(value) => onImageSelect(value, 'model_url')}
                  error={themeError.model_url}
                />
              </Styled.ColumnDiv>

              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>WebGL Unity Package</Styled.TitleDiv>
                <Upload3DModal
                  selected={themeData.webgl_model_url}
                  onSelect={(value) => onImageSelect(value, 'webgl_model_url')}
                  error={themeError.webgl_model_url}
                />
              </Styled.ColumnDiv>

              <Styled.ColumnDiv>
                <Styled.TitleDiv notEmpty>Theme Catalogue Image</Styled.TitleDiv>
                <UploadImage
                  selected={themeData.theme_catalogue_image}
                  onSelect={(value) => onImageSelect(value, 'theme_catalogue_image')}
                  error={themeError.theme_catalogue_image}
                />
              </Styled.ColumnDiv>
            </Styled.ColumnDiv>

          </Styled.RowDiv>

          <Styled.ColumnDiv>
            <Styled.TitleDiv>Theme Elements Details:-</Styled.TitleDiv>
            <Styled.BundleDetailsDiv>
              <p>No. of elements:  <span>{elements?.length || 0}</span></p>
              <div>
                <p>MRP:  <span>{`₹${numberWithCommas(themeData.product_bx_cost || 0)}`}</span></p>
                <p>BX Price:  <span>{`₹${numberWithCommas(themeData.product_bx_price || 0)}`}</span></p>
                <p>Procurement Cost:  <span>{`₹${numberWithCommas(themeData.procurement_cost || 0)}`}</span></p>
              </div>
            </Styled.BundleDetailsDiv>
          </Styled.ColumnDiv>

          <Styled.TabWrapDiv>
            <Styled.TabButton active={activeTab === 'all' ? 'true' : 'false'} onClick={() => setActiveTab('all')}>All Elements</Styled.TabButton>
            <Styled.TabButton active={activeTab === 'theme' ? 'true' : 'false'} onClick={() => setActiveTab('theme')}>{`Elements in Theme (${elements?.length || 0})`}</Styled.TabButton>
          </Styled.TabWrapDiv>

          <Styled.TabContentDiv style={{ display: activeTab === 'all' ? 'flex' : 'none' }}>
            <CommonStyled.FilterDiv>
              <SingleSelect
                data={elementCategories}
                selectedId={selectedElementCategory}
                onSelect={(id) => onElementCategoryChange(id)}
                style={{ width: '18%' }}
                optionKey="all"
                placeholder="Select category"
              />
              <SingleSelect
                data={elementSubCategories}
                selectedId={selectedElementSubCategory}
                onSelect={(id) => setSelectedElementSubCategory(id)}
                style={{ width: '18%' }}
                optionKey="all"
                placeholder="Select subcategory"
              />
              <SingleSelect
                data={elementBrands}
                selectedId={selectedElementBrand}
                onSelect={(id) => setSelectedElementBrand(id)}
                style={{ width: '18%' }}
                optionKey="all"
                placeholder="Select brand"
              />
              <SearchInput
                onInputChange={(e) => setElementSearchText(e.target.value)}
                placeholder="Search with element name and SKU code"
                style={{ width: '38%' }}
              />
            </CommonStyled.FilterDiv>

            { !allElements.length && !selectedElementCategory && !selectedElementSubCategory && !selectedElementBrand && !elementSearchText ?
              <>
                { !elementLoading ?
                  <CommonStyled.NoDataFoundDiv>
                    <img src="/no-element-added-icon.svg" />
                    <div>No element added so far</div>
                    <PrimaryButton title="Upload New Element" to={'/add-elements'} />
                  </CommonStyled.NoDataFoundDiv>
                : ''
                }
              </>
            :
              <Table
                rows={allElements}
                headings={headings}
                page={elementPage}
                limit={elementLimit}
                count={elementCount}
                onPageChange={(value) => onElementsPageChange(value)}
                onLimitChange={(value) => setElementLimit(value)}
                noDataImage="/no-element-found-icon.svg"
                noDataText="No element found"
                loading={elementLoading}
                hideColumnToggle={true}
                addedRows={elements || []}
              />
            }
          </Styled.TabContentDiv>

          <Styled.TabContentDiv style={{ display: activeTab === 'theme' ? 'flex' : 'none' }}>
            <CommonStyled.FilterDiv>
              <SingleSelect
                data={elementCategories}
                selectedId={selectedCategory}
                onSelect={(id) => onCategoryChange(id)}
                style={{ width: '18%' }}
                optionKey="theme"
                placeholder="Select category"
              />
              <SingleSelect
                data={themeElementSubCategories}
                selectedId={selectedSubCategory}
                onSelect={(id) => setSelectedSubCategory(id)}
                style={{ width: '18%' }}
                optionKey="theme"
                placeholder="Select subcategory"
              />
              <SingleSelect
                data={elementBrands}
                selectedId={selectedBrand}
                onSelect={(id) => setSelectedBrand(id)}
                style={{ width: '18%' }}
                optionKey="theme"
                placeholder="Select brand"
              />
              <SearchInput
                onInputChange={(e) => setSearchText(e.target.value)}
                placeholder="Search with element name and SKU code"
                style={{ width: '38%' }}
              />
            </CommonStyled.FilterDiv>

            { !filteredElements.length && !selectedCategory && !selectedSubCategory && !selectedBrand && !searchText ?
              <>
                { !elementLoading ?
                  <CommonStyled.NoDataFoundDiv>
                    <img src="/no-element-added-icon.svg" />
                    <div>No element added so far</div>
                  </CommonStyled.NoDataFoundDiv>
                : ''
                }
              </>
            :
              <Table
                rows={filteredElements || []}
                headings={headings}
                page={1}
                limit={1000}
                count={0}
                onPageChange={() => {}}
                onLimitChange={() => {}}
                noDataImage="/no-element-found-icon.svg"
                noDataText="No element found"
                loading={false}
                hideColumnToggle={true}
                addedRows={filteredElements}
              />
            }
          </Styled.TabContentDiv>

        </Styled.CardContentDiv>

        <Styled.CardFooterDiv>
          <SecondaryButton title="Cancel" onClick={() => navigate('/themes')} />
          <PrimaryButton title={pathname === 'add-themes' ? 'Add Theme' : 'Update Theme'} style={{ borderRadius: '8px', marginLeft: '20px' }} onClick={validateData} />
        </Styled.CardFooterDiv>
      </Styled.CardDiv>

    </Layout>
  )
}

export default withRouter(EditThemes);
