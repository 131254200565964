import styled from "styled-components";

export const MainWrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 48px 60px 48px;
  border-top: 1px solid #bfbfbf;
`;
export const HeadingDiv = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #118D8B;
`;
export const TextDiv = styled.div`
  font-size: 16px;
  margin: 12px 0px 20px 0px;
`;
export const RowDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const ColumnDiv = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`;
export const LabelDiv = styled.div`
  font-size: 16px;
  margin: 28px 0px 8px 0px;
`;
export const RadioButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const DividerDiv = styled.div`
  height: 1px;
  width: 100%;
  background-color: #bfbfbf;
  margin: 30px 0px;
`;
export const InfoDiv = styled.div`
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CouponInputDiv = styled.div`
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  display: flex;
  align-items: center;
  & > input {
    border: none;
    flex-grow: 1;
    &:focus {
      outline: none;
    }
  }
  & > button {
    background: none;
    padding: 10px 24px;
    color: #118D8B;
  }
  & > div {
    padding: 0px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:focus-within {
    border: 1px solid transparent;
    outline-color: #118D8B;
    outline-style: solid;
  }
`;
