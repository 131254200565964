import { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Loader from "../../Components/Loader";
import { boqLayout } from "./boqLayout";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import { numberWithCommas } from "../../Utilities/utilsFunctions";
import * as Styled from "./styled";
import Heading from "../../Components/Heading";

const ProjectExecution = ({ params }) => {
  const [calculation, setCalculation] = useState({});
  const [projectData, setProjectData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (params?.id) {
      getProject(params.id);
      calculatePayment(params.id);
    }
  }, [params]);

  const getProject = async () => {
    setLoading(true);
    let res = await fetchData('get', `projects/get-project/${params.id}`);
    if (res?.data) {
      setProjectData({ ...res?.data });
    }
    setLoading(false);
  }

  const calculatePayment = async () => {
    setLoading(true);
    let res = await fetchData('get', `payments/calculate-payment/${params.id}`);

    if (res?.data) {
      setCalculation({...res?.data});
    }
    setLoading(false);
  }

  const header = (heading, pageNo) => (
    `<div style="display: flex; justify-content: space-between; margin: 0px 30px; padding: 24px 0px 9px 0px; border-bottom: 1px solid #118D8B;">
      <div style="color: #118D8B; font-size: 20px; font-weight: 700;">${heading}</div>
      <div style="fontS-size: 12px;">${pageNo}</div>
    </div>`
  );
  
  const footer = (project) => (
    `<div style="height: 69px; display: flex; align-items: center; justify-content: space-between; background-color: #e2f1f1; padding: 0px 30px; position: absolute; bottom: 0px; right: 0px; left: 0px;">
      <div style="display: flex; flex-direction: column;">
        <img style="width: 150px; height: 24px;" src="https://bathxpertz.s3.ap-south-1.amazonaws.com/admin/boq-logo.svg" />
        <div style="font-size: 12px; font-weight: 600;">www.bathxpertz.com</div>
      </div>
      <div style="display: flex; flex-direction: column; align-items: flex-end">
        <div style="font-size: 12px; font-weight: 600;">${project?.project_name}</div>
        <div style="font-size: 12px; font-weight: 600;">${`BOQ ID: ${project?.bx_code}`}</div>
      </div>
    </div>`
  )

  const productsPages = () => {
    let html = '';
    let page = 0;

    if (calculation?.productCostData?.length) {
      let products = [];
      const elements = calculation.productCostData || [];
      const boqElements = [];
  
      for (let x = 0; x < elements?.length; x++) {
        
        if (!elements?.[x]?.bx_price) {
          continue;
        }
        
        const skuIndex = boqElements.findIndex(e => e.sku === elements?.[x]?.sku);
  
        if (skuIndex > -1) {
          boqElements[skuIndex].qty += elements?.[x]?.qty || 1;
          boqElements[skuIndex].bx_price = Number(boqElements[skuIndex].bx_price) + Number(elements?.[x]?.bx_price);
        } else {
          boqElements.push({...elements?.[x]});
        }
      }
  
      for (let x = 0; x < boqElements?.length; x++) {
        products.push(boqElements[x]);
  
        if (products.length === 6 || x === (boqElements.length - 1)) {
          html += `<div style="height: 842px; width: 595px; background-color: #ffffff; position: relative; margin: 0px;">
            ${header('Products', page += 1)}
            <div style="height: 712px; padding: 1px 30px 69px 30px;">
              <div style="display: flex; flex-direction: column; padding: 5px 0px;">`
  
          for (let x = 0; x < 6; x++) {
            if (products?.[x]) {
              html += `<div style="display: flex; padding: 15px 0px;">
                <img src=${products?.[x]?.element_image} style="height: 80px; width: 80px; border-radius: 4px; margin-right: 15px; flex-shrink: 0;" />
    
                <div style="display: flex; flex-direction: column;">
                  <div style="font-size: 12px; font-weight: 700; line-height: 16px; margin-bottom: 3px;">${products?.[x]?.title}</div>
                  <div style="display: flex; flex-wrap: wrap;">
                    <div style="width: 50%; display: flex;">
                      <div style="font-size: 10px; line-height: 14px;">${`${products?.[x]?.size?.length} / ${products?.[x]?.size?.width} / ${products?.[x]?.size?.height}`}</div>
                    </div>
    
                    <div style="width: 50%; display: flex;">
                      <div style="font-size: 10px; line-height: 14px; color: #666666; margin-right: 4px;">Brand:</div>
                      <div style="font-size: 10px; line-height: 14px;">${products?.[x]?.brands}</div>
                    </div>
    
                    <div style="width: 50%; display: flex; margin: 3px 0px;">
                      <div style="font-size: 10px; line-height: 14px; color: #666666; margin-right: 4px;">Cost:</div>
                      <div style="font-size: 10px; line-height: 14px;">₹${products?.[x]?.bx_price}</div>
                    </div>
    
                    <div style="width: 50%; display: flex; margin: 3px 0px;">
                      <div style="font-size: 10px; line-height: 14px; color: #666666; margin-right: 4px;">Finish:</div>
                      <div style="font-size: 10px; line-height: 14px;">${products?.[x]?.finishing}</div>
                    </div>
    
                    <div style="width: 50%; display: flex;">
                      <div style="font-size: 10px; line-height: 14px; color: #666666;">
                        SKU Code: <span style="font-size: 10px; line-height: 14px; color: #000000; margin-left: 4px;">${products?.[x]?.sku}</span>
                      </div>
                    </div>
  
                    <div style="width: 50%; display: flex;">
                      <div style="font-size: 10px; line-height: 14px; color: #666666; margin-right: 4px;">Quantity:</div>
                      <div style="font-size: 10px; line-height: 14px;">${products?.[x]?.qty}</div>
                    </div>
                  </div>
                </div>
              </div>`
            }
          }
  
          html += `</div>
            </div>
            ${footer(projectData)}
          </div>`;
  
          products = [];
        }
      }
    }
    return <div dangerouslySetInnerHTML={{__html: html}} />;
  }

  const executionPages = () => {
    const getPrice = (parent, child) => {
      let calcObj = [];
      const executionArray = calculation?.executionCostData || [];

      if (child) {
        calcObj = executionArray?.filter((item) => item.child_name === child);
      } else {
        calcObj = executionArray?.filter((item) => item.parent_name === parent);
      }

      const totalPrice = calcObj.reduce((prev, cur) => prev + cur.total_amount, 0);
      return totalPrice;
    }

    let currentContent = '';
    let tempContent = '';
    let html = '';
    let page = 0;
    const pageHeight = 690;
    let contentHeight = 0;

    for (let x = 0; x < boqLayout?.length; x++) {
      const executionItem = boqLayout[x];
      const executionPrice = getPrice(executionItem.parent_name, executionItem.child_name);

      if (!executionPrice) {
        continue;
      }

      let proposedHeight = contentHeight + executionItem.height;

      if (executionItem.type === 'heading' && boqLayout?.[x + 1]?.height) {
        proposedHeight += boqLayout[x + 1].height;
      }

      if (proposedHeight > pageHeight) {
        html += `<div style="height: 842px; width: 595px; background-color: #ffffff; position: relative; margin: 0px;">
            ${header('Execution', page += 1)}
            <div style="height: 712px; padding: 1px 30px 69px 30px;">
              ${tempContent}
            </div>
            ${footer(projectData)}
          </div>`
        tempContent = '';
        contentHeight = 0;
      } else {
        contentHeight += executionItem.height;
      }

      if (executionItem.type === 'heading') {
        tempContent += `<div style="display: flex; align-items: center; font-size: 14px; font-weight: 700; color: #118D8B; padding-top: 20px;">
            <div>${executionItem.title}</div>
            <div style="flex-grow: 1; height: 1px; margin: 0px 12px; background-color: #118D8B;"></div>
            <div>${`₹${numberWithCommas(executionPrice)}`}</div>
          </div>`;
      }

      if (executionItem.type === 'description') {
        tempContent += `<div style="display: flex; justify-content: space-between; font-size: 12px; font-weight: 500; margin: 15px 0px 5px 15px;">
            <div style="font-size: 12px; font-weight: 500; margin-right: 100px;">${executionItem.title}</div>
            <div style="font-size: 12px; font-weight: 500;">₹${numberWithCommas(executionPrice)}</div>
          </div>`;

        if (executionItem.description) {
          tempContent += `<div style="font-size: 10px; font-weight: 400; color: #808080; margin: 0px 100px 0px 15px;">${executionItem.description}</div>`;
        }

        if (executionItem.list) {
          tempContent += `<div style="display: flex; flex-direction: column; margin-top: ${ executionItem.description ? '8px' : '0px'};">`;
          for (let y = 0; y < executionItem.list.length; y++) {
            tempContent += `<div style="display: flex; margin-bottom: 12px;">
              <div style="flex-shrink: 0; height: 4px; width: 4px; border-radius: 4px; background-color: #808080; margin: 4px 6px 4px 23px;"></div>
              <div style="font-size: 10px; font-weight: 400; color: #808080; margin-right: 100px; flex-grow: 1; line-height: 14px;">${executionItem.list[y].description}</div>
              <div style="font-size: 10px; font-weight: 400; color: #808080; flex-shrink: 0;">₹${numberWithCommas(executionItem.list[y].amount)}</div>
            </div>`
          }
          tempContent += `</div>`;
        }
      }

      currentContent = tempContent;
    }

    html += `<div style="height: 842px; width: 595px; background-color: #ffffff; position: relative; margin: 0px;">
        ${header('Execution', page += 1)}
        <div style="height: 712px; padding: 1px 30px 69px 30px;">
          ${currentContent}
        </div>
        ${footer(projectData)}
      </div>`

      return <div dangerouslySetInnerHTML={{__html: html}} />;
  }

  return (
    <Layout activePage="Projects">
      <Loader loading={loading} />
      <Heading
        heading={projectData?.project_name}
        backTo={{ text: 'Back to project details', url: `/projects/${params.id}` }}
      >
      </Heading>

      <Styled.MainWrapDiv>
        { productsPages() }
        { executionPages() }
      </Styled.MainWrapDiv>
      
    </Layout>
  )
}

export default withRouter(ProjectExecution);
