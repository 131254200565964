export const MilestoneLayout = (data) => (
  [
    {
      name: 'visit_the_site',
      title: 'Visit The Site',
      submit: { text: 'Save & Next' },
      layout: [
        [{ label: 'Date', element: 'input', name: 'date', value: data?.date, min: new Date().toISOString().split('T')[0] }],
        [{ label: 'Add Instructions for Supervisor', element: 'textarea', name: 'instructions', value: data?.instructions }],
        [
          { label: 'Upload File', element: 'uploadFile', name: 'files', value: data?.files },
          { label: 'Site Visit Commencement Checklist', text: 'Click here for site visit commencement checklist', element: 'button', name: 'visitChecklist', listLength: 11 }
        ],
        [{
          element: 'checklist',
          name: 'visitChecklist',
          heading: 'Site Visit Commencement Checklist',
          list: [
            {
              label: 'Space for malbe storage',
              checked: data?.visitChecklist?.[0]
            },
            {
              label: 'Space for malbe disposal, its distance and provision',
              checked: data?.visitChecklist?.[1]
            },
            {
              label: 'Space for material storage',
              checked: data?.visitChecklist?.[2]
            },
            {
              label: 'Area for tile cutting',
              checked: data?.visitChecklist?.[3]
            },
            {
              label: 'If the tile cutting area has power, water and ventilation available',
              checked: data?.visitChecklist?.[4]
            },
            {
              label: 'Electric connection from DB',
              checked: data?.visitChecklist?.[5]
            },
            {
              label: 'Adjacent room and furniture in it',
              checked: data?.visitChecklist?.[6]
            },
            {
              label: 'Permissible working hours',
              checked: data?.visitChecklist?.[7]
            },
            {
              label: 'Any permission required from building management',
              checked: data?.visitChecklist?.[8]
            },
            {
              label: 'Provision for material lifting, if lift is available',
              checked: data?.visitChecklist?.[9]
            },
            {
              label: 'POC from client',
              checked: data?.visitChecklist?.[10]
            }
          ]
        }]
      ]
    },
    {
      name: 'site_measurement',
      title: 'Site Measurement',
      submit: { text: 'Save & Next' },
      layout: [
        [
          { label: 'Date', element: 'input', name: 'date', value: data?.date, min: new Date().toISOString().split('T')[0] },
          { label: 'Site Measurement Checklist', text: 'Click here for site measurement checklist', element: 'button', name: 'measurementDetails', listLength: 10 }
        ],
        [{ label: 'Remarks', element: 'textarea', name: 'remarks', value: data?.remarks }],
        [
          { label: 'Upload File', element: 'uploadFile', name: 'files', value: data?.files },
          {
            label: 'Site Measurement Commencement Checklist',
            text: 'Click here for site measurement commencement checklist',
            element: 'button',
            name: 'measurementChecklist',
            listLength: 11,
            condition: 'measurementDetails',
            conditionListLength: 10
          }
        ],
        [{
          element: 'checklist',
          name: 'measurementChecklist',
          heading: 'Site Measurement Commencement Checklist',
          list: [
            {
              label: 'Check for any visual seepage on walls',
              checked: data?.measurementChecklist?.[0]
            },
            {
              label: 'Check if existing plumbing is of GI or PVC',
              checked: data?.measurementChecklist?.[1]
            },
            {
              label: 'Check if the Bath room is on sunken slab, raised floor or core cut system',
              checked: data?.measurementChecklist?.[2]
            },
            {
              label: 'Check drain point provision, if only till wall or till building manhole',
              checked: data?.measurementChecklist?.[3]
            },
            {
              label: 'Check if the wash basin counter is of RCC or modular',
              checked: data?.measurementChecklist?.[4]
            },
            {
              label: 'Check the existing partition if 9inch brick wall, 4.5 in brick wall, block wall or any RCC wall',
              checked: data?.measurementChecklist?.[5]
            },
            {
              label: 'Check for provision of ventilation, if in shaft, or in open or distant from toilet',
              checked: data?.measurementChecklist?.[6]
            },
            {
              label: 'Measure all elevations',
              checked: data?.measurementChecklist?.[7]
            },
            {
              label: 'Measure floor',
              checked: data?.measurementChecklist?.[8]
            },
            {
              label: 'Measure any projection',
              checked: data?.measurementChecklist?.[9]
            },
            {
              label: 'Floor to false ceiling height',
              checked: data?.measurementChecklist?.[10]
            }
          ]
        }],
        [{
          element: 'measurementDetailsChecklist',
          name: 'measurementDetails',
          layoutList: [
            [
              [{ element: 'label', text: 'Washroom Measurement' }],
              [
                { element: 'inputGroup', group: 'L', placeholder: 'Length(ft)', name: 'washroom_measurement_length', checklist: 1, value: data?.measurement_checklist_1?.washroom_measurement_length },
                { element: 'inputGroup', group: 'B', placeholder: 'Breadth(ft)', name: 'washroom_measurement_breadth', checklist: 1, value: data?.measurement_checklist_1?.washroom_measurement_breadth }
              ],
              [{ element: 'label', text: 'No. of Walls' }],
              [{
                element: 'dropdown',
                name: 'no_of_walls',
                checklist: 1,
                placeholder: 'Select no. of walls',
                data: [{ id: 1, name: 1 }, { id: 2, name: 2 }, { id: 3, name: 3 }, { id: 4, name: 4 }, { id: 5, name: 5 }, { id: 6, name: 6 }],
                value: data?.measurement_checklist_1?.no_of_walls
              }],
              [{ element: 'label', text: 'Wall 1' }],
              [
                { element: 'inputGroup', group: 'H', placeholder: 'Height(ft)', name: 'wall_1_height', checklist: 1, value: data?.measurement_checklist_1?.wall_1_height },
                { element: 'inputGroup', group: 'W', placeholder: 'Width(ft)', name: 'wall_1_width', checklist: 1, value: data?.measurement_checklist_1?.wall_1_width }
              ],
              [{ element: 'label', text: 'Wall 2' }],
              [
                { element: 'inputGroup', group: 'H', placeholder: 'Height(ft)', name: 'wall_2_height', checklist: 1, value: data?.measurement_checklist_1?.wall_2_height },
                { element: 'inputGroup', group: 'W', placeholder: 'Width(ft)', name: 'wall_2_width', checklist: 1, value: data?.measurement_checklist_1?.wall_2_width }
              ],
              [{ element: 'label', text: 'Wall 3' }],
              [
                { element: 'inputGroup', group: 'H', placeholder: 'Height(ft)', name: 'wall_3_height', checklist: 1, value: data?.measurement_checklist_1?.wall_3_height },
                { element: 'inputGroup', group: 'W', placeholder: 'Width(ft)', name: 'wall_3_width', checklist: 1, value: data?.measurement_checklist_1?.wall_3_width }
              ],
              [{ element: 'label', text: 'Wall 4' }],
              [
                { element: 'inputGroup', group: 'H', placeholder: 'Height(ft)', name: 'wall_4_height', checklist: 1, value: data?.measurement_checklist_1?.wall_4_height },
                { element: 'inputGroup', group: 'W', placeholder: 'Width(ft)', name: 'wall_4_width', checklist: 1, value: data?.measurement_checklist_1?.wall_4_width }
              ],
              [{ element: 'label', text: 'Wall 5' }],
              [
                { element: 'inputGroup', group: 'H', placeholder: 'Height(ft)', name: 'wall_5_height', checklist: 1, value: data?.measurement_checklist_1?.wall_5_height },
                { element: 'inputGroup', group: 'W', placeholder: 'Width(ft)', name: 'wall_5_width', checklist: 1, value: data?.measurement_checklist_1?.wall_5_width }
              ],
              [{ element: 'label', text: 'Wall 6' }],
              [
                { element: 'inputGroup', group: 'H', placeholder: 'Height(ft)', name: 'wall_6_height', checklist: 1, value: data?.measurement_checklist_1?.wall_6_height },
                { element: 'inputGroup', group: 'W', placeholder: 'Width(ft)', name: 'wall_6_width', checklist: 1, value: data?.measurement_checklist_1?.wall_6_width }
              ]
            ],
            [
              [{ element: 'label', text: 'Wall Material' }],
              [{
                element: 'radio',
                name: 'wall_material',
                values: [{ id: 'RCC', name: 'RCC' }, { id: 'Brick wall', name: 'Brick wall' }],
                checklist: 2,
                value: data?.measurement_checklist_2?.wall_material
              }],
              [{ element: 'label', text: 'Wall Thickness' }],
              [{
                element: 'radio',
                name: 'wall_thickness',
                values: [{ id: '100 mm', name: '100 mm' }, { id: '110 mm', name: '110 mm' }, { id: '200 mm', name: '200 mm' }, { id: '230 mm', name: '230 mm' }],
                checklist: 2,
                value: data?.measurement_checklist_2?.wall_thickness
              }],
              [{ element: 'label', text: 'Comments:' }],
              [{ element: 'textarea', name: 'comments_on_walls', checklist: 2, value: data?.measurement_checklist_2?.comments_on_walls, label: 'Remarks', placeholder: 'If there is any extra wall/beam or any other details you want to mention', style: { height: '260px' } }]
            ],
            [
              [{ element: 'label', text: 'Details of Column' }],
              [{
                element: 'radio',
                name: 'details_of_column',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                checklist: 2,
                value: data?.measurement_checklist_3?.details_of_column
              }],
              [
                { element: 'input', name: 'details_of_column_size', value: data?.measurement_checklist_3?.details_of_column_size, label: 'Size', condition: 'details_of_column', trueCondition: true, type: 'number', placeholder: 'Enter size(ft)' },
                { element: 'input', name: 'details_of_column_location', value: data?.measurement_checklist_3?.details_of_column_location, label: 'Location', condition: 'details_of_column', trueCondition: true, placeholder: 'Enter location' },
              ],
              [{ element: 'label', text: 'Details of Beam' }],
              [{
                element: 'radio',
                name: 'details_of_beam',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                checklist: 2,
                value: data?.measurement_checklist_3?.details_of_beam
              }],
              [
                { element: 'input', name: 'details_of_beam_size', value: data?.measurement_checklist_3?.details_of_beam_size, label: 'Size', condition: 'details_of_beam', trueCondition: true, type: 'number', placeholder: 'Enter size(ft)' },
                { element: 'input', name: 'details_of_beam_location', value: data?.measurement_checklist_3?.details_of_beam_location, label: 'Location', condition: 'details_of_beam', trueCondition: true, placeholder: 'Enter location' },
              ],
              [{ element: 'label', text: 'Window' }],
              [{
                element: 'radio',
                name: 'window',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_3?.window
              }],
              [
                { element: 'input', name: 'window_location', value: data?.measurement_checklist_3?.window_location, label: 'Location', condition: 'window', trueCondition: true, placeholder: 'Enter location' },
              ],
              [
                { element: 'input', name: 'window_length', value: data?.measurement_checklist_3?.window_length, label: 'Length', condition: 'window', trueCondition: true, type: 'number', placeholder: 'Enter length(ft)' },
                { element: 'input', name: 'window_height', value: data?.measurement_checklist_3?.window_height, label: 'Height', condition: 'window', trueCondition: true, type: 'number', placeholder: 'Enter height(ft)' },
              ],
            ],
            [
              [{ element: 'label', text: 'Ventilator' }],
              [{
                element: 'radio',
                name: 'ventilator',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_4?.ventilator
              }],
              [
                { element: 'input', name: 'ventilator_size', value: data?.measurement_checklist_4?.ventilator_size, label: 'Size', condition: 'ventilator', trueCondition: true, type: 'number', placeholder: 'Enter size(ft)' },
                { element: 'input', name: 'ventilator_location', value: data?.measurement_checklist_4?.ventilator_location, label: 'Location', condition: 'ventilator', trueCondition: true, placeholder: 'Enter location' },
              ],
              [
                { element: 'input', name: 'ventilator_length', value: data?.measurement_checklist_4?.ventilator_length, label: 'Length', condition: 'ventilator', trueCondition: true, type: 'number', placeholder: 'Enter length(ft)' },
                { element: 'input', name: 'ventilator_height', value: data?.measurement_checklist_4?.ventilator_height, label: 'Height', condition: 'ventilator', trueCondition: true, type: 'number', placeholder: 'Enter height(ft)' },
              ],
              [{ element: 'label', text: 'Door' }],
              [
                { element: 'input', name: 'door_size', value: data?.measurement_checklist_4?.door_size, label: 'Size', type: 'number', placeholder: 'Enter size(ft)' },
                { element: 'input', name: 'door_location', value: data?.measurement_checklist_4?.door_location, label: 'Location', placeholder: 'Enter location' },
              ],
              [
                { element: 'input', name: 'door_length', value: data?.measurement_checklist_4?.door_length, label: 'Length', type: 'number', placeholder: 'Enter length(ft)' },
                { element: 'input', name: 'door_height', value: data?.measurement_checklist_4?.door_height, label: 'Height', type: 'number', placeholder: 'Enter height(ft)' },
              ]
            ],
            [
              [{ element: 'label', text: 'Details of Niche' }],
              [{
                element: 'radio',
                name: 'details_of_niche',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_5?.details_of_niche
              }],
              [
                { element: 'input', name: 'details_of_niche_size', value: data?.measurement_checklist_5?.details_of_niche_size, label: 'Size', condition: 'details_of_niche', trueCondition: true, type: 'number', placeholder: 'Enter size(ft)' },
                { element: 'input', name: 'details_of_niche_location', value: data?.measurement_checklist_5?.details_of_niche_location, label: 'Location', condition: 'details_of_niche', trueCondition: true, placeholder: 'Enter location' },
              ],
              [
                { element: 'input', name: 'details_of_niche_length', value: data?.measurement_checklist_5?.details_of_niche_length, label: 'Length', condition: 'details_of_niche', trueCondition: true, type: 'number', placeholder: 'Enter length(ft)' },
                { element: 'input', name: 'details_of_niche_height', value: data?.measurement_checklist_5?.details_of_niche_height, label: 'Height', condition: 'details_of_niche', trueCondition: true, type: 'number', placeholder: 'Enter height(ft)' },
              ],
              [
                { element: 'input', name: 'details_of_niche_depth', value: data?.measurement_checklist_5?.details_of_niche_depth, label: 'Depth', condition: 'details_of_niche', trueCondition: true, type: 'number', placeholder: 'Enter depth(ft)' },
              ],
              [{ element: 'label', text: 'Removal of Electrical Lights' }],
              [{ element: 'text', text: 'Same are to be used' }],
              [{
                element: 'radio',
                name: 'same_light_to_be_used',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_5?.same_light_to_be_used
              }],
              [{ element: 'text', text: 'Replacement of lights' }],
              [{
                element: 'radio',
                name: 'replacement_of_lights',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_5?.replacement_of_lights
              }],
            ],
            [
              [{ element: 'label', text: 'Electrical Work' }],
              [{ element: 'text', text: 'Existing switches' }],
              [
                { element: 'input', name: 'existing_switches_6_amp', value: data?.measurement_checklist_6?.existing_switches_6_amp, label: '6 Amp', type: 'number', placeholder: 'Nos' },
                { element: 'input', name: 'existing_switches_16_amp', value: data?.measurement_checklist_6?.existing_switches_16_amp, label: '16 Amp', type: 'number', placeholder: 'Nos' },
              ]
            ],
            [
              [{ element: 'label', text: 'Plumbing Fixtures' }],
              [{ element: 'label', text: 'Washbasin' }],
              [{
                element: 'radio',
                name: 'washbasin',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_7?.washbasin
              }],
              [{ element: 'label', text: 'Wash Basin Mixer' }],
              [{
                element: 'radio',
                name: 'washbasin_mixer',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_7?.washbasin_mixer
              }],
              [{ element: 'label', text: 'Washing Area Tap' }],
              [{
                element: 'radio',
                name: 'washing_area_tap',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_7?.washing_area_tap
              }],
              [{ element: 'label', text: 'Shower' }],
              [{
                element: 'radio',
                name: 'shower',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_7?.shower
              }],
              [{ element: 'label', text: 'Mirror' }],
              [{
                element: 'radio',
                name: 'mirror',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_7?.mirror
              }],
            ],
            [
              [{ element: 'label', text: 'Plumbing Fixtures' }],
              [{ element: 'label', text: 'Vanity' }],
              [{
                element: 'radio',
                name: 'vanity',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_8?.vanity
              }],
              [
                { element: 'input', name: 'vanity_dimensions', value: data?.measurement_checklist_8?.vanity_dimensions, label: 'Dimensions', condition: 'vanity', trueCondition: true, type: 'number', placeholder: 'Enter dimensions(ft)' },
                { element: 'input', name: 'vanity_location', value: data?.measurement_checklist_8?.vanity_location, label: 'Location', condition: 'vanity', trueCondition: true, placeholder: 'Enter location' },
              ],
              [
                { element: 'input', name: 'vanity_length', value: data?.measurement_checklist_8?.vanity_length, label: 'Length', condition: 'vanity', trueCondition: true, type: 'number', placeholder: 'Enter length(ft)' },
                { element: 'input', name: 'vanity_breadth', value: data?.measurement_checklist_8?.vanity_breadth, label: 'Breadth', condition: 'vanity', trueCondition: true, type: 'number', placeholder: 'Enter breadth(ft)' },
              ],
              [{ element: 'label', text: 'Water Closet (WC)' }],
              [{
                element: 'radio',
                name: 'water_closet_wc',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_8?.water_closet_wc
              }],
              [
                { element: 'input', name: 'type_of_water_closet_wc', value: data?.measurement_checklist_8?.type_of_water_closet_wc, label: 'Type of WC', condition: 'water_closet_wc', trueCondition: true, placeholder: 'Enter WC type' },
                { element: 'input', name: 'water_closet_wc_offset_from_wall', value: data?.measurement_checklist_8?.water_closet_wc_offset_from_wall, label: 'Offset from wall', condition: 'water_closet_wc', trueCondition: true, placeholder: 'Enter offset from wall' },
              ],
              [
                { element: 'input', name: 'water_closet_wc_angle_valves_nos', value: data?.measurement_checklist_8?.water_closet_wc_angle_valves_nos, label: 'Angle valves no.s', condition: 'water_closet_wc', trueCondition: true, type: 'number', placeholder: 'Enter no.s of valves' }
              ],
            ],
            [
              [{ element: 'label', text: 'Glass Partition' }],
              [{
                element: 'radio',
                name: 'glass_partition',
                values: [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }],
                value: data?.measurement_checklist_9?.glass_partition
              }],
              [
                { element: 'input', name: 'glass_partition_size', value: data?.measurement_checklist_9?.glass_partition_size, label: 'Size', condition: 'glass_partition', trueCondition: true, type: 'number', placeholder: 'Enter size(ft)' },
                { element: 'input', name: 'glass_partition_location', value: data?.measurement_checklist_9?.glass_partition_location, label: 'Location', condition: 'glass_partition', trueCondition: true, placeholder: 'Enter location' },
              ],
              [
                { element: 'input', name: 'glass_partition_length', value: data?.measurement_checklist_9?.glass_partition_length, label: 'Length', condition: 'glass_partition', trueCondition: true, type: 'number', placeholder: 'Enter length(ft)' },
                { element: 'input', name: 'glass_partition_breadth', value: data?.measurement_checklist_9?.glass_partition_breadth, label: 'Breadth', condition: 'glass_partition', trueCondition: true, type: 'number', placeholder: 'Enter breadth(ft)' },
              ],
              [{ element: 'label', text: 'Change of Water Line' }],
              [{
                element: 'radio',
                name: 'water_line_change',
                values: [{ id: 'Partially change', name: 'Partially change details' }, { id: 'Total replacement', name: 'Total replacement of water line pipe' }],
                value: data?.measurement_checklist_9?.water_line_change
              }],
              [{ element: 'conditionalTextarea', label: 'Detail:', name: 'partially_change_details', value: data?.measurement_checklist_9?.partially_change_details, placeholder: 'Enter detail here...', condition: 'water_line_change', trueCondition: 'Partially change', }],
            ],
            [
              [{ element: 'label', text: 'Drain Pipe Details' }],
              [
                { element: 'input', name: 'drain_pipe_height_from_floor', value: data?.measurement_checklist_10?.drain_pipe_height_from_floor, label: 'Height from floor', type: 'number', placeholder: 'Enter height(ft)' },
                { element: 'input', name: 'drain_pipe_offset_from_wall', value: data?.measurement_checklist_10?.drain_pipe_offset_from_wall, label: 'Offset from wall', type: 'number', placeholder: 'Enter offset' },
              ],
              [{ element: 'label', text: 'Details of Shower', style: { marginBottom: '0px' } }],
              [
                { element: 'input', name: 'shower_height_from_floor', value: data?.measurement_checklist_10?.shower_height_from_floor, label: 'Height from floor', type: 'number', placeholder: 'Enter height(ft)' },
                { element: 'input', name: 'shower_offset_from_wall', value: data?.measurement_checklist_10?.shower_offset_from_wall, label: 'Offset from wall', type: 'number', placeholder: 'Enter offset' },
              ],
              [{ element: 'label', text: 'Details of Taps', style: { marginBottom: '0px' } }],
              [
                { element: 'input', name: 'taps_height_from_floor', value: data?.measurement_checklist_10?.taps_height_from_floor, label: 'Height from floor', type: 'number', placeholder: 'Enter height(ft)' },
                { element: 'input', name: 'taps_offset_from_wall', value: data?.measurement_checklist_10?.taps_offset_from_wall, label: 'Offset from wall', type: 'number', placeholder: 'Enter offset' },
              ],
              [{ element: 'label', text: 'Any Comments' }],
              [{ element: 'textarea', name: 'any_comments', value: data?.measurement_checklist_10?.any_comments, placeholder: 'Enter comment here...', style: { height: '133px' } }]
            ]
          ]
        }]
      ]
    },
    {
      name: 'design_validation',
      title: 'Design Validation',
      submit: { text: 'Save & Next' },
      layout: [
        [{ label: 'Date', element: 'input', name: 'date', value: data?.date, min: new Date().toISOString().split('T')[0] }],
        [{ label: 'Remarks', element: 'textarea', name: 'remarks', value: data?.remarks }],
        [{ label: 'Upload File', name: 'files', value: data?.files, detail: 'Update elements and execution cost if required & upload the updated PI and other documents', element: 'uploadFile' }]
      ]
    },
    {
      name: 'first_payment_request',
      title: 'Request Payment',
      type: 'payment',
      text: 'Request for the second installment of the project',
      value: data?.payment_requested,
      submit: { text: 'Request For Payment' }
    },
    {
      name: 'detailed_design_deck',
      title: 'Detailed Design Deck',
      submit: { text: 'Save & Next' },
      layout: [
        [{ label: 'Date', element: 'input', name: 'date', value: data?.date,min: new Date().toISOString().split('T')[0] }],
        [{ label: 'Remarks', element: 'textarea', name: 'remarks', value: data?.remarks }],
        [{ label: 'Upload File', name: 'files', value: data?.files, detail: 'Update elements and execution cost if required & upload the updated PI and other documents', element: 'uploadFile' }]
      ]
    },
    {
      name: 'demolition',
      title: 'Demolition',
      layout: [
        [
          { label: 'Start Date', element: 'input', name: 'start_date', value: data?.start_date, placeholder: 'Start date', max: data?.end_date, min: new Date().toISOString().split('T')[0] },
          { label: 'End Date', element: 'input', name: 'end_date', value: data?.end_date, placeholder: 'End date', min: data?.start_date }
        ],
        [{ label: 'Before Works Commencement Checklist', text: 'Click here for before works commencement checklist', element: 'button', name: 'beforeDemolitionChecklist', listLength: 10 }],
        [{
          name: 'covering_of_work_area',
          title: 'Covering & Packing of Work Area',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'removing_fittings',
          title: 'Removing Old CP/Sanitary/Electrical & Accessories Fittings',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'dismantling_of_wall_tiles',
          title: 'Dismantling of Wall Tiles',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'demolition_of_floor_tiles',
          title: 'Demolition of Floor Tiles',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'dismantling_for_niches',
          title: 'Dismantling for Niches',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          label: 'After Works Completion Checklist',
          text: 'Click here for after works completion checklist',
          element: 'button',
          name: 'afterDemolitionChecklist',
          listLength: 5,
          condition: 'beforeDemolitionChecklist',
          conditionListLength: 10
        }],
        [{
          element: 'checklist',
          name: 'beforeDemolitionChecklist',
          heading: 'Before Works Commencement Checklist',
          list: [
            {
              label: 'Ensure that cleint is informed and ready for the activity to commence',
              checked: data?.beforeDemolitionChecklist?.[0]
            },
            {
              label: 'Check if all water supply and drain points are disconnetced',
              checked: data?.beforeDemolitionChecklist?.[1]
            },
            {
              label: 'Check if all electrical connections are disconnected',
              checked: data?.beforeDemolitionChecklist?.[2]
            },
            {
              label: 'Remove all fittings and fixtures',
              checked: data?.beforeDemolitionChecklist?.[3]
            },
            {
              label: 'Handover all fittings and fixtures to client',
              checked: data?.beforeDemolitionChecklist?.[4]
            },
            {
              label: 'Check for availability or required gunny bags',
              checked: data?.beforeDemolitionChecklist?.[5]
            },
            {
              label: 'Check for availability of required tools and tackels',
              checked: data?.beforeDemolitionChecklist?.[6]
            },
            {
              label: 'Check if the area for malba storage is clear for storing the filled gunny bags',
              checked: data?.beforeDemolitionChecklist?.[7]
            },
            {
              label: 'If the malba transporter is aligned',
              checked: data?.beforeDemolitionChecklist?.[8]
            },
            {
              label: 'If all the furniture and passage is covered with plastic',
              checked: data?.beforeDemolitionChecklist?.[9]
            }
          ]
        }],
        [{
          element: 'checklist',
          name: 'afterDemolitionChecklist',
          heading: 'After Works Completion Checklist',
          list: [
            {
              label: 'After each packing up on each day, check if the effected areas are properly clean',
              checked: data?.afterDemolitionChecklist?.[0]
            },
            {
              label: 'All gunny bags are palced in the designated area',
              checked: data?.afterDemolitionChecklist?.[1]
            },
            {
              label: "Share site photos and next day's plan in group",
              checked: data?.afterDemolitionChecklist?.[2]
            },
            {
              label: 'If material is to be removed in daily basis, the trolley person is aligned for malba removal',
              checked: data?.afterDemolitionChecklist?.[3]
            },
            {
              label: 'In case of any site challenge seen or if any delay is anticipated, if the same is informed to PM / Lead',
              checked: data?.afterDemolitionChecklist?.[4]
            }
          ]
        }]
      ]
    },
    {
      name: 'second_payment_request',
      title: 'Request Payment',
      type: 'payment',
      text: 'Request for the third installment of the project',
      value: data?.payment_requested,
      submit: { text: 'Request For Payment' }
    },
    {
      name: 'internal_plumbing_works',
      title: 'Internal Plumbing Works',
      layout: [
        [
          { label: 'Start Date', element: 'input', name: 'start_date', value: data?.start_date, placeholder: 'Start date', max: data?.end_date, min: new Date().toISOString().split('T')[0] },
          { label: 'End Date', element: 'input', name: 'end_date', value: data?.end_date, placeholder: 'End date', min: data?.start_date }
        ],
        [{ label: 'Before Works Commencement Checklist', text: 'Click here for before works commencement checklist', element: 'button', name: 'beforePlumbingChecklist', listLength: 8 }],
        [{
          name: 'chase_cutting_on_walls_for_cpvc_pipes',
          title: 'Chase Cutting on Walls for CPVC Pipes',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'dismantling_of_upvc_pipes',
          title: 'Dismantling for UPVC Pipes',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'installation_of_cpvc_pipes',
          title: 'Installation of CPVC Pipes',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'installation_of_upvc_pipes',
          title: 'Installation of UPVC Pipes',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          label: 'After Works Completion Checklist',
          text: 'Click here for after works completion checklist',
          element: 'button',
          name: 'afterPlumbingChecklist',
          listLength: 10,
          condition: 'beforePlumbingChecklist',
          conditionListLength: 8
        }],
        [{
          element: 'checklist',
          name: 'beforePlumbingChecklist',
          heading: 'Before Works Commencement Checklist',
          list: [
            {
              label: 'Check if floor marking is completed',
              checked: data?.beforePlumbingChecklist?.[0]
            },
            {
              label: 'Check if all drain and supply points are marked as per drawing',
              checked: data?.beforePlumbingChecklist?.[1]
            },
            {
              label: 'Check if floor levels are marked',
              checked: data?.beforePlumbingChecklist?.[2]
            },
            {
              label: 'Installation of supports as per designed slope',
              checked: data?.beforePlumbingChecklist?.[3]
            },
            {
              label: 'Check if all required material is at site and is as per agreed specifications',
              checked: data?.beforePlumbingChecklist?.[4]
            },
            {
              label: 'Check is required tools and tackles are in place',
              checked: data?.beforePlumbingChecklist?.[5]
            },
            {
              label: 'Check if the man power is aligned',
              checked: data?.beforePlumbingChecklist?.[6]
            },
            {
              label: 'Check if wall chiseling is done as per drawing for conduits and water supply line',
              checked: data?.beforePlumbingChecklist?.[7]
            }
          ]
        }],
        [{
          element: 'checklist',
          name: 'afterPlumbingChecklist',
          heading: 'After Works Completion Checklist',
          list: [
            {
              label: 'If all pipes and conduits are visually straight',
              checked: data?.afterPlumbingChecklist?.[0]
            },
            {
              label: 'If all joints are sealed properly',
              checked: data?.afterPlumbingChecklist?.[1]
            },
            {
              label: 'If saddels and supports are properly fixed and provided',
              checked: data?.afterPlumbingChecklist?.[2]
            },
            {
              label: 'All bends have supports',
              checked: data?.afterPlumbingChecklist?.[3]
            },
            {
              label: 'Inlet and out let points are closed properly',
              checked: data?.afterPlumbingChecklist?.[4]
            },
            {
              label: 'Run pressure check for 4 hours for 3.5 - 4Kgs in all plumbing pipes',
              checked: data?.afterPlumbingChecklist?.[5]
            },
            {
              label: 'If hot water lines are insulated post pressure test',
              checked: data?.afterPlumbingChecklist?.[6]
            },
            {
              label: 'If the pressure test report is shared in group with client along with photo',
              checked: data?.afterPlumbingChecklist?.[7]
            },
            {
              label: 'If the site is clean from all waste and dust after completion',
              checked: data?.afterPlumbingChecklist?.[8]
            },
            {
              label: 'Share site photos and work updates in client group',
              checked: data?.afterPlumbingChecklist?.[9]
            }
          ]
        }]
      ]
    },
    {
      name: 'electrical_works',
      title: 'Electrical Works',
      layout: [
        [
          { label: 'Start Date', element: 'input', name: 'start_date', value: data?.start_date, placeholder: 'Start date', max: data?.end_date, min: new Date().toISOString().split('T')[0] },
          { label: 'End Date', element: 'input', name: 'end_date', value: data?.end_date, placeholder: 'End date', min: data?.start_date }
        ],
        [{ label: 'Before Works Commencement Checklist', text: 'Click here for before works commencement checklist', element: 'button', name: 'beforeElectricalChecklist', listLength: 8 }],
        [{
          name: 'chase_cutting_on_walls_for_conduiting',
          title: 'Chase Cutting on Walls for Conduiting',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'piping_and_wiring',
          title: 'Piping & Wiring',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          label: 'After Works Completion Checklist',
          text: 'Click here for after works completion checklist',
          element: 'button',
          name: 'afterElectricalChecklist',
          listLength: 10,
          condition: 'beforeElectricalChecklist',
          conditionListLength: 8
        }],
        [{
          element: 'checklist',
          name: 'beforeElectricalChecklist',
          heading: 'Before Works Commencement Checklist',
          list: [
            {
              label: 'Check if floor marking is completed',
              checked: data?.beforeElectricalChecklist?.[0]
            },
            {
              label: 'Check if all drain and supply points are marked as per drawing',
              checked: data?.beforeElectricalChecklist?.[1]
            },
            {
              label: 'Check if floor levels are marked',
              checked: data?.beforeElectricalChecklist?.[2]
            },
            {
              label: 'Installation of supports as per designed slope',
              checked: data?.beforeElectricalChecklist?.[3]
            },
            {
              label: 'Check if all required material is at site and is as per agreed specifications',
              checked: data?.beforeElectricalChecklist?.[4]
            },
            {
              label: 'Check is required tools and tackles are in place',
              checked: data?.beforeElectricalChecklist?.[5]
            },
            {
              label: 'Check if the man power is aligned',
              checked: data?.beforeElectricalChecklist?.[6]
            },
            {
              label: 'Check if wall chiseling is done as per drawing for conduits and water supply line',
              checked: data?.beforeElectricalChecklist?.[7]
            }
          ]
        }],
        [{
          element: 'checklist',
          name: 'afterElectricalChecklist',
          heading: 'After Works Completion Checklist',
          list: [
            {
              label: 'If all pipes and conduits are visually straight',
              checked: data?.afterElectricalChecklist?.[0]
            },
            {
              label: 'If all joints are sealed properly',
              checked: data?.afterElectricalChecklist?.[1]
            },
            {
              label: 'If saddels and supports are properly fixed and provided',
              checked: data?.afterElectricalChecklist?.[2]
            },
            {
              label: 'All bends have supports',
              checked: data?.afterElectricalChecklist?.[3]
            },
            {
              label: 'Inlet and out let points are closed properly',
              checked: data?.afterElectricalChecklist?.[4]
            },
            {
              label: 'Run pressure check for 4 hours for 3.5 - 4Kgs in all plumbing pipes',
              checked: data?.afterElectricalChecklist?.[5]
            },
            {
              label: 'If hot water lines are insulated post pressure test',
              checked: data?.afterElectricalChecklist?.[6]
            },
            {
              label: 'If the pressure test report is shared in group with client along with photo',
              checked: data?.afterElectricalChecklist?.[7]
            },
            {
              label: 'If the site is clean from all waste and dust after completion',
              checked: data?.afterElectricalChecklist?.[8]
            },
            {
              label: 'Share site photos and work updates in client group',
              checked: data?.afterElectricalChecklist?.[9]
            }
          ]
        }]
      ]
    },
    {
      name: 'civil_works',
      title: 'Civil Works',
      layout: [
        [
          { label: 'Start Date', element: 'input', name: 'start_date', value: data?.start_date, placeholder: 'Start date', max: data?.end_date, min: new Date().toISOString().split('T')[0] },
          { label: 'End Date', element: 'input', name: 'end_date', value: data?.end_date, placeholder: 'End date', min: data?.start_date }
        ],
        [{ label: 'Before Works Commencement Checklist', text: 'Click here for before works commencement checklist', element: 'button', name: 'beforeCivilChecklist', listLength: 7 }],
        [{
          name: 'ledge_walls',
          title: 'Ledge Wall',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'plaster_and_pcc_repairs',
          title: 'Plaster & PCC Repairs',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'waterproofing',
          title: 'Waterproofing',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          label: 'After Works Completion Checklist',
          text: 'Click here for after works completion checklist',
          element: 'button',
          name: 'afterCivilChecklist',
          listLength: 13,
          condition: 'beforeCivilChecklist',
          conditionListLength: 7
        }],
        [{
          element: 'checklist',
          name: 'beforeCivilChecklist',
          heading: 'Before Works Commencement Checklist',
          list: [
            {
              label: 'Check of the required material availability in cluding cement and fine aggregate',
              checked: data?.beforeCivilChecklist?.[0]
            },
            {
              label: 'Ensure proper covering of all affected areas prior to commencement of works',
              checked: data?.beforeCivilChecklist?.[1]
            },
            {
              label: 'If bricks are being water soaked',
              checked: data?.beforeCivilChecklist?.[2]
            },
            {
              label: 'Check for the mortar mixture as per specification',
              checked: data?.beforeCivilChecklist?.[3]
            },
            {
              label: 'Check for availability of all tools and tackels',
              checked: data?.beforeCivilChecklist?.[4]
            },
            {
              label: 'Check for the availability of man power',
              checked: data?.beforeCivilChecklist?.[5]
            },
            {
              label: 'Check if the cistern is fixed at its place',
              checked: data?.beforeCivilChecklist?.[6]
            }
          ]
        }],
        [{
          element: 'checklist',
          name: 'afterCivilChecklist',
          heading: 'After Works Completion Checklist',
          list: [
            {
              label: 'Check with the leveller for vertical straightness of the wall',
              checked: data?.afterCivilChecklist?.[0]
            },
            {
              label: 'Check with perpendiclar for horizontal straightness of the wall',
              checked: data?.afterCivilChecklist?.[1]
            },
            {
              label: 'Ensure proper curing of the wall',
              checked: data?.afterCivilChecklist?.[2]
            },
            {
              label: 'Check for surface cleanliness to take waterproofing coat',
              checked: data?.afterCivilChecklist?.[3]
            },
            {
              label: 'Check if all floor pipe joints are properly supported',
              checked: data?.afterCivilChecklist?.[4]
            },
            {
              label: 'Apply water proofing coat on complete floor, under all pipes and upto 1.5 feet on all vertical surfaces',
              checked: data?.afterCivilChecklist?.[5]
            },
            {
              label: 'Once the waterproofing coat is dried, stand water in the area for 48 hours and mark the top level of water',
              checked: data?.afterCivilChecklist?.[6]
            },
            {
              label: 'Check for any level drop in water after 24 hours and if there is a drop, check with the floor below if there is any leakage',
              checked: data?.afterCivilChecklist?.[7]
            },
            {
              label: 'If the water level drop is within the range after 48 hours, prepare report of water proofing testing and share the same in group with photos',
              checked: data?.afterCivilChecklist?.[8]
            },
            {
              label: 'Give go ahead for plastering works and check if the mortar mixing ratio',
              checked: data?.afterCivilChecklist?.[9]
            },
            {
              label: 'Check the vertical and horizontal level of plastered walls in same way as of brick work',
              checked: data?.afterCivilChecklist?.[10]
            },
            {
              label: 'Ensure proper curing of plaster for one day',
              checked: data?.afterCivilChecklist?.[11]
            },
            {
              label: 'Update completion of milestone to cleint and PM / Lead',
              checked: data?.afterCivilChecklist?.[12]
            }
          ]
        }]
      ]
    },
    {
      name: 'third_payment_request',
      title: 'Request Payment',
      type: 'payment',
      text: 'Request for the final installment of the project',
      value: data?.payment_requested,
      submit: { text: 'Request For Payment' }
    },
    {
      name: 'tiling_works',
      title: 'Tiling Works',
      layout: [
        [
          { label: 'Start Date', element: 'input', name: 'start_date', value: data?.start_date, placeholder: 'Start date', max: data?.end_date, min: new Date().toISOString().split('T')[0] },
          { label: 'End Date', element: 'input', name: 'end_date', value: data?.end_date, placeholder: 'End date', min: data?.start_date }
        ],
        [{ label: 'Before Works Commencement Checklist', text: 'Click here for before works commencement checklist', element: 'button', name: 'beforeTilingChecklist', listLength: 8 }],
        [{
          name: 'tile_laying_on_walls',
          title: 'Tile Laying on Walls',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'tile_laying_on_floor',
          title: 'Tile Laying on Floor',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'false_ceiling_works',
          title: 'False Ceiling Works',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          label: 'After Works Completion Checklist',
          text: 'Click here for after works completion checklist',
          element: 'button',
          name: 'afterTilingChecklist',
          listLength: 14,
          condition: 'beforeTilingChecklist',
          conditionListLength: 8
        }],
        [{
          element: 'checklist',
          name: 'beforeTilingChecklist',
          heading: 'Before Works Commencement Checklist',
          list: [
            {
              label: 'Check for availability of material at site',
              checked: data?.beforeTilingChecklist?.[0]
            },
            {
              label: 'Check for availability of required tools and tackles',
              checked: data?.beforeTilingChecklist?.[1]
            },
            {
              label: 'Check for the availability of man power',
              checked: data?.beforeTilingChecklist?.[2]
            },
            {
              label: 'Ensure that client has seen all type of tiles prior to start of works',
              checked: data?.beforeTilingChecklist?.[3]
            },
            {
              label: 'Mark the start point on floor',
              checked: data?.beforeTilingChecklist?.[4]
            },
            {
              label: 'Send the photo of adhesive and tiles on the group',
              checked: data?.beforeTilingChecklist?.[5]
            },
            {
              label: 'Inform client about the noise and dust impact of tiling works in advance',
              checked: data?.beforeTilingChecklist?.[6]
            },
            {
              label: 'Ensure proper covering of all adjacent areas',
              checked: data?.beforeTilingChecklist?.[7]
            }
          ]
        }],
        [{
          element: 'checklist',
          name: 'afterTilingChecklist',
          heading: 'After Works Completion Checklist',
          list: [
            {
              label: 'Check if spacers are there at every joint',
              checked: data?.afterTilingChecklist?.[0]
            },
            {
              label: 'Check if all grooves are straight in line',
              checked: data?.afterTilingChecklist?.[1]
            },
            {
              label: 'Check if the wall and floor tile grooves are meeting as per design',
              checked: data?.afterTilingChecklist?.[2]
            },
            {
              label: 'Check for cut tile fixing',
              checked: data?.afterTilingChecklist?.[3]
            },
            {
              label: 'Check for finishing of cut outs for drain point',
              checked: data?.afterTilingChecklist?.[4]
            },
            {
              label: 'Check the finishing of cut outs for WC, flush and diverter points',
              checked: data?.afterTilingChecklist?.[5]
            },
            {
              label: 'Check if the marking for clean out is done on the floor',
              checked: data?.afterTilingChecklist?.[6]
            },
            {
              label: 'Take hammer test and get photos of the same. In case tile fails in hammer test, get the same refixed',
              checked: data?.afterTilingChecklist?.[7]
            },
            {
              label: 'Check perpendicular',
              checked: data?.afterTilingChecklist?.[8]
            },
            {
              label: 'Check the level with spirit level',
              checked: data?.afterTilingChecklist?.[9]
            },
            {
              label: 'Visually check the slope',
              checked: data?.afterTilingChecklist?.[10]
            },
            {
              label: 'After all checks, cover the floor tile area',
              checked: data?.afterTilingChecklist?.[11]
            },
            {
              label: 'Send photos of tiled floors, all tests reports and test conducting photos in client group',
              checked: data?.afterTilingChecklist?.[12]
            },
            {
              label: 'Ensure the area is cleaned at the time of pack up',
              checked: data?.afterTilingChecklist?.[13]
            }
          ]
        }]
      ]
    },
    {
      name: 'installation',
      title: 'Installation',
      layout: [
        [
          { label: 'Start Date', element: 'input', name: 'start_date', value: data?.start_date, placeholder: 'Start date', max: data?.end_date, min: new Date().toISOString().split('T')[0] },
          { label: 'End Date', element: 'input', name: 'end_date', value: data?.end_date, placeholder: 'End date', min: data?.start_date }
        ],
        [{ label: 'Before Works Commencement Checklist', text: 'Click here for before works commencement checklist', element: 'button', name: 'beforeInstallationChecklist', listLength: 7 }],
        [{
          name: 'electrical_fittings_installation',
          title: 'Electrical Fittings Installation',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'paint',
          title: 'Paint',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'vanity_installation',
          title: 'Vanity Installation',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'sanitary_and_cp_installation',
          title: 'Sanitary & CP Installation',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'tile_grouting',
          title: 'Tile Grouting',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'shower_partition_installation',
          title: 'Shower Partition Installation',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'mirror_and_accessories_installation',
          title: 'Mirror & Accessories Intsallation',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          label: 'After Works Completion Checklist',
          text: 'Click here for after works completion checklist',
          element: 'button',
          name: 'afterInstallationChecklist',
          listLength: 9,
          condition: 'beforeInstallationChecklist',
          conditionListLength: 7
        }],
        [{
          element: 'checklist',
          name: 'beforeInstallationChecklist',
          heading: 'Before Works Commencement Checklist',
          list: [
            {
              label: 'Check for availability of material at site',
              checked: data?.beforeInstallationChecklist?.[0]
            },
            {
              label: 'Check for availability of required tools and tackles',
              checked: data?.beforeInstallationChecklist?.[1]
            },
            {
              label: 'Check for the availability of man power',
              checked: data?.beforeInstallationChecklist?.[2]
            },
            {
              label: 'Ensure that client has seen all type of fitting - WC, Flush, Lights, Switch sockets, Glass frame, Exhaust fan, CP fittings etc prior to start of works',
              checked: data?.beforeInstallationChecklist?.[3]
            },
            {
              label: 'Check if the light marking is done on the false ceiling',
              checked: data?.beforeInstallationChecklist?.[4]
            },
            {
              label: 'Check if all the wiring is properly done with required connectors and gang boxes',
              checked: data?.beforeInstallationChecklist?.[5]
            },
            {
              label: 'Check if the false ceiling cuts are done for light fixtures',
              checked: data?.beforeInstallationChecklist?.[6]
            }
          ]
        }],
        [{
          element: 'checklist',
          name: 'afterInstallationChecklist',
          heading: 'After Works Completion Checklist',
          list: [
            {
              label: 'Check the straightness of lights, WC, flush plate, Switch socket',
              checked: data?.afterInstallationChecklist?.[0]
            },
            {
              label: 'Switch on the power and check if all lights are working and if there is power in socket',
              checked: data?.afterInstallationChecklist?.[1]
            },
            {
              label: 'Check for water supply through flush tank, and taps at wash basin, shower area, health faucet at WC',
              checked: data?.afterInstallationChecklist?.[2]
            },
            {
              label: 'Ensure that there is no leakage at any fitting',
              checked: data?.afterInstallationChecklist?.[3]
            },
            {
              label: 'Check is exhaust fan is working properly',
              checked: data?.afterInstallationChecklist?.[4]
            },
            {
              label: 'Check if the shower are glass has any scratches',
              checked: data?.afterInstallationChecklist?.[5]
            },
            {
              label: 'After all above checks give go ahead for painting works',
              checked: data?.afterInstallationChecklist?.[6]
            },
            {
              label: 'Ensure that all fittings and glass is properly covered before start of painting works',
              checked: data?.afterInstallationChecklist?.[7]
            },
            {
              label: 'Send photos of all installation in group with update',
              checked: data?.afterInstallationChecklist?.[8]
            }
          ]
        }]
      ]
    },
    {
      name: 'cleaning_and_handover',
      title: 'Cleaning & Handover',
      layout: [
        [
          { label: 'Start Date', element: 'input', name: 'start_date', value: data?.start_date, placeholder: 'Start date', max: data?.end_date,min: new Date().toISOString().split('T')[0] },
          { label: 'End Date', element: 'input', name: 'end_date', value: data?.end_date, placeholder: 'End date', min: data?.start_date }
        ],
        [{
          name: 'cleaning',
          title: 'Cleaning',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'handover_document_signed',
          title: 'Handover Document Signed',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'bills_handover_to_client',
          title: 'Bills Handover to Client',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{
          name: 'review_or_rating_by_client',
          title: 'Review/Rating by Client',
          element: 'subCollapse',
          submit: { text: 'Save & Next' },
          layout: [
            [
              { label: 'Start Date', element: 'input', name: 'start_date', placeholder: 'Start date' },
              { label: 'End Date', element: 'input', name: 'end_date', placeholder: 'End date' }
            ],
            [{ label: 'Remarks', element: 'textarea', name: 'remarks' }],
            [{ label: 'Upload File', element: 'uploadFile', name: 'files' }]
          ]
        }],
        [{ label: 'Handover Commencement Checklist', text: 'Click here for after handover commencement checklist', element: 'button', name: 'completion_checklist', listLength: 9 }],
        [{
          element: 'checklist',
          name: 'completion_checklist',
          heading: 'After Handover Checklist',
          list: [
            {
              label: 'Remove the tile covering',
              checked: data?.completion_checklist?.[0]
            },
            {
              label: 'Ensure that grouting done properly and is cleaned from spill areas',
              checked: data?.completion_checklist?.[1]
            },
            {
              label: 'Remove covering from all fittings',
              checked: data?.completion_checklist?.[2]
            },
            {
              label: 'Check the finishing of vanity counter',
              checked: data?.completion_checklist?.[3]
            },
            {
              label: 'Check the paint quality for any visible brush marks or paint marks on wall tiles',
              checked: data?.completion_checklist?.[4]
            },
            {
              label: 'Check the slope of the floor by splashing a bucket of water on it',
              checked: data?.completion_checklist?.[5]
            },
            {
              label: 'Post all checks click photos and inform PM / Lead to professional photography of site',
              checked: data?.completion_checklist?.[6]
            },
            {
              label: 'Send photos of all installation in group with update',
              checked: data?.completion_checklist?.[7]
            },
            {
              label: 'Declare handover of the project to client and ask for their testimonial',
              checked: data?.completion_checklist?.[8]
            }
          ]
        }]
      ]
    }
  ]
);

export const TabLayout = [
  {
    name: 'milestone',
    title: 'Milestones',
    icon: '/projects-milestones.svg',
    activeIcon: '/projects-milestones-active.svg',
  },
  {
    name: 'elements',
    title: 'Elements',
    icon: '/projects-elements.svg',
    activeIcon: '/projects-elements-active.svg'
  },
  {
    name: 'execution',
    title: 'Execution',
    icon: '/projects-executions.svg',
    activeIcon: '/projects-executions-active.svg'
  }
];

export const TotalProgressFields = {
  visit_the_site: 14,
  site_measurement: 24,
  design_validation: 3,
  first_payment_request: 1,
  detailed_design_deck: 3,
  demolition: 37,
  second_payment_request: 1,
  internal_plumbing_works: 36,
  electrical_works: 28,
  civil_works: 34,
  third_payment_request: 1,
  tiling_works: 36,
  installation: 46,
  cleaning_and_handover: 27
};

export const DesignFields = [
  'visit_the_site',
  'site_measurement',
  'design_validation',
  'first_payment_request',
  'detailed_design_deck'
];

export const ExecuteFields = [
  'demolition',
  'second_payment_request',
  'internal_plumbing_works',
  'electrical_works',
  'civil_works',
  'third_payment_request',
  'tiling_works',
  'installation',
  'cleaning_and_handover'
];

export const Notifications = {
  visit_the_site: {
    title: 'Visit the site',
    body: 'Your site has been visited.'
  },
  site_measurement: {
    title: 'Site measurement',
    body: 'The site measurement of your bathroom has been completed.'
  },
  design_validation: {
    title: 'Design validation',
    body: 'The design has been validated for your bathroom.'
  },
  first_payment_request: {
    title: 'Request Payment',
    body: 'The payment is requested for your bathroom.'
  },
  detailed_design_deck: {
    title: 'Detailed Design Deck',
    body: 'The detailed design deck has been completed for your bathroom.'
  },
  demolition: {
    title: 'Demolition',
    body: 'The demolition of your bathroom has been completed.'
  },
  second_payment_request: {
    title: 'Request Payment',
    body: 'The payment is requested for your bathroom.'
  },
  internal_plumbing_works: {
    title: 'Internal Plumbing Works',
    body: 'The internal plumbing works for your bathroom has been completed.'
  },
  electrical_works: {
    title: 'Electrical Works',
    body: 'The electrical works for your bathroom has been completed.'
  },
  civil_works: {
    title: 'Civil Works',
    body: 'The civil works for your bathroom has been completed.'
  },
  third_payment_request: {
    title: 'Request Payment',
    body: 'The payment is requested for your bathroom.'
  },
  tiling_works: {
    title: 'Tiling Works',
    body: 'The tiling works for your bathroom has been completed.'
  },
  installation: {
    title: 'Installation',
    body: 'The installation works for your bathroom has been completed.'
  },
  cleaning_and_handover: {
    title: 'Cleaning & Handover',
    body: 'The cleaning & handover for your bathroom has been completed.'
  }
}
