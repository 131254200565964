import { useEffect, useState } from "react";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import * as Styled from "./styled";

const RedirectToApp = () => {
  const [text, setText] = useState('Please wait.');

  useEffect(() => {
    if (isMobile) {
      if (isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.defaultcompany.bathxpertz';
      } else if (isIOS) {
        setText('You will be redirected to App Store.')
      }
    } else {
      window.location.href = 'https://bath-webgl.s3.ap-south-1.amazonaws.com/index.html';
    }
  }, []);

  return (
    <Styled.MainWrapDiv>
      <div>Redirecting...</div>
      <div>{text}</div>
    </Styled.MainWrapDiv>
  )
}

export default RedirectToApp;
