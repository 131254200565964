export const boqLayout = [
  {
    type: 'heading',
    title: 'Demolition',
    child_name: null,
    parent_name: 'Demolition',
    height: 39
  },
  {
    type: 'description',
    title: 'Covering and Packing',
    child_name: 'Covering & Packing',
    parent_name: 'Demolition',
    description: 'Covering and Packing of the entire working area/pathway with bubble wrap,plastic sheet or PVC sheet as per Site requirement and maintaining the same in said area till Completion of Project. Only Floor Area of Bathroom to be measured.',
    height: 77
  },
  {
    type: 'description',
    title: 'Only CP and Sanitary removal',
    child_name: 'Only CP and Sanitary Removal',
    parent_name: 'Demolition',
    description: 'Removing OLD Sanitary & CP Fittings including WC (Wallhung/Floor Mounted), Washbasin (Under Counter/Counter Top/Wall Mounted & Floor Standing), any typer of shower except body shower with jets, CP fiiting incl basin mixer, diverter,faucets and mirror,towel rod,loose fittings etc',
    height: 91
  },
  {
    type: 'description',
    title: 'Complete Demolition',
    child_name: 'Complete Demolition',
    parent_name: 'Demolition',
    description: 'Removing OLD Sanitary & CP Fittings including WC (Wallhung/Floor Mounted), Washbasin (Under Counter/Counter Top/Wall Mounted & Floor Standing), any typer of shower except body shower with jets, CP fiiting incl basin mixer, diverter,faucets and mirror,towel rod,loose fittings etc. Including dismantling of wall & floor tiles and plaster on wall upto 15mm and on Floor upto 50mm and disposing off malba outside the flat/residence will be charged extra as per Municipal coproration on actual to be beared by the client',
    height: 117
  },
  {
    type: 'description',
    title: 'Removal of bathtub/jacuzzi',
    child_name: 'Removal of Bathtub/Jacuzi',
    parent_name: 'Demolition',
    height: 37
  },
  {
    type: 'heading',
    title: 'Internal Plumbing Works',
    child_name: null,
    parent_name: 'Plumbing Work',
    height: 39
  },
  {
    type: 'description',
    title: 'Installations of Sanitary & CP Fittings',
    child_name: 'Installations of Sanitary & CP Fittings',
    parent_name: 'Plumbing Work',
    description: 'Indian/Wester Comode with Health Faucet 1nos. Shower Set _ Overhead rain Shower with Spout and Hand Shower with/without rail (Multiflow rain Shower not Incl.) 1 Set. Basin Mixer _ Washbain & Basin Mixer(wall mount/Counter mount) with bottle trap & angle valves 1 nos. Installation of Angle valves and stop cock as per dwg/as per existing bathroom upto 4 nos.',
    height: 91
  },
  {
    type: 'description',
    title: 'Internal CPVC Plumbing',
    child_name: 'Internal CPVC Plumbing',
    parent_name: 'Plumbing Work',
    description: 'Providing and laying of 22mm internal dia CPVC pipes for Hot & Cold water supply inside bathroom including chase cutting and making, bending, cutting, jointing, testing and Commisioning.',
    height: 77
  },
  {
    type: 'description',
    title: 'Internal UPVC Plumbing',
    child_name: 'Internal UPVC Plumbing',
    parent_name: 'Plumbing Work',
    description: "Providing and laying of 4' internal dia PVC pipes for Soil waste & drain inside bathroom including dismantling of PCC and making/repairing, cutting,bending & jointinng,testing & Commissioning.",
    height: 77
  },
  {
    type: 'description',
    title: 'Plumbing for Bathtub/Jacuzzi',
    child_name: 'Plumbing for Bathtub/Jacuzi',
    parent_name: 'Plumbing Work',
    description: 'Making Plumbing Provision for Water supply and Drainage for Bathtub/jacuzzi',
    height: 51
  },
  {
    type: 'heading',
    title: 'Electrical Works',
    child_name: null,
    parent_name: 'Electrical Works',
    height: 39
  },
  {
    type: 'description',
    title: 'Installations of Electrical Fittings inside Bathroom (Cost of Products and wiring not included)',
    child_name: 'Electrical Fitting Installation',
    parent_name: 'Electrical Works',
    description: 'Installation of Lights upto 4, 5/8 Module Switch plate with Switch & Socket 1nos, 16A point for Geyser 1nos, Installation of Ceiling Fan 1 Nos, Installation of Exhaust Fan 1 Nos, Installation of Cove Lights/Strip Lights Upto 10feet',
    height: 94
  },
  {
    type: 'description',
    title: 'Electrical Package',
    child_name: 'Electrical Package',
    parent_name: 'Electrical Works',
    description: 'Providing and laying conduits, wire, switch, socket incl chase cutting and making and installation of fittings. (Products eg Light, Fans, Exhaust etc Cost not included) Ceiling light points, Wall light point, Exhaust fan point, Ceiling Fan point, 5Amp Switch/Socket, 16Amp Switch Socket, Ceiling Loops, Wall Loops',
    height: 91
  },
  {
    type: 'heading',
    title: 'Tile Works',
    child_name: null,
    parent_name: 'Tile Works',
    height: 39
  },
  {
    type: 'description',
    title: 'Laying of Vitrified/Ceramics tile on Wall & Floor of Bathroom',
    child_name: 'Tile Laying With Adhesive',
    parent_name: 'Tile Works',
    description: 'Laying Tiles on Wall & Floors upto size of 2 ft X 4 ft and thickenss 10mm with adhesive (Roff/MYK Laticrete 315 and equivalent brand)on floor &  wall with upto 2mm PVC spacer and including Cementious grouting with Hardener in desired shade.',
    height: 77
  },
  {
    type: 'description',
    title: 'Additional for Epoxy Grout for tile size above 2feetx2feet (incl) on Floor and 2 feet x 4 Feet (incl) on Wall',
    child_name: 'Extra for Epoxy Grout',
    parent_name: 'Tile Works',
    height: 54
  },
  {
    type: 'heading',
    title: 'Wall Plaster',
    child_name: null,
    parent_name: 'Wall Plaster',
    height: 39
  },
  {
    type: 'heading',
    title: 'Waterproofing',
    child_name: null,
    parent_name: 'Waterproofing',
    height: 39
  },
  {
    type: 'description',
    title: 'Water Proofing on floor and wall upto 1.5ft height',
    child_name: null,
    parent_name: 'Waterproofing',
    height: 37
  },
  {
    type: 'heading',
    title: 'Ledge Wall',
    child_name: null,
    parent_name: 'Ledge Wall',
    height: 39
  },
  {
    type: 'description',
    title: 'Ledge wall for wall hung WC height (4ft X 3ft, thickness 6inch)',
    child_name: null,
    parent_name: 'Ledge Wall',
    height: 37
  },
  {
    type: 'heading',
    title: 'Material Handling',
    child_name: null,
    parent_name: 'Material Handling Charges',
    height: 39
  },
  {
    type: 'description',
    title: 'Material handling charges according to floor of building',
    child_name: null,
    parent_name: 'Material Handling Charges',
    height: 37
  },
];