import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Table from "../../Components/ThemeTable";
import Modal from "../../Components/Modal";
import { toastError } from "../../Utilities/toast";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import * as CommonStyled from "../../Common/commonStyled";
import SecondaryButton from "../../Components/SecondaryButton";
import * as XLSX from 'xlsx';
import Loader from "../../Components/Loader";


const Themes = ({ navigate }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [allThemes, setAllThemes] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [actionTheme, setActionTheme] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [showImportModal, setShowImportModal] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [filterSearch, setFilterSearch] = useState('');
  const [fetching, setFetching] = useState(false);
  const [elements,setElements] = useState(null);
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setPage(1);
    getThemes(1);
  }, [filterSearch, limit, selectedCategory]);

  const getCategories = async () => {
    let res = await fetchData('get', 'theme/get-theme-utils');
    const categoryArr = res?.data?.category || [];
    const newCategoryArr = [{ id: 'All', name: 'Category' }];

    for (let x = 0; x < categoryArr.length; x++) {
      newCategoryArr.push({ id: categoryArr[x].name, name: categoryArr[x].name });
    }
    setAllCategories(newCategoryArr);
  }

  const getThemes = async (pageNo) => {
    setLoading(true);
    let url = `theme/get-all-themes?pageNo=${pageNo || page}&limit=${limit}`;
    if (selectedCategory && selectedCategory !== 'All') {
      url += `&category=${selectedCategory}`;
    }
    if (filterSearch) {
      url += `&title=${filterSearch}`;
    }

    let res = await fetchData('get', url);
    setAllThemes(res?.data?.themes || []);
    setCount(res?.data?.count || 0);
    setLoading(false);
  }

  const deleteTheme = async (id) => {
    setLoading(true);
    let res = await fetchData('get', `theme/delete-theme/${id}`);
    if (res?.message === 'Successfully Deleted') {
      getThemes();
    }
    toastError('The theme has been deleted successfully!');
    setLoading(false);
  }

  const onDelete = (row) => {
    setActionTheme(row);
    setShowDeleteModal(true);
  }

  const onConfirmDelete = () => {
    const id = `${actionTheme.id}`;
    deleteTheme(id);
    setShowDeleteModal(false);
    setActionTheme(null);
  }

  const onEdit = (row) => {
    navigate(`/edit-themes/${row.id}`);
  }

  const hideModal = () => {
    setActionTheme(null);
    setShowDeleteModal(false);
  }

  const onPageChange = (value) => {
    getThemes(value);
    setPage(value);
  }

  const getElements = async (id) => {
    setLoading(true);
    let thm = await fetchData('get', `theme/get-theme/${id}`);
    let skuArray = [];

    if (thm?.data?.elements) {
      // Extract SKUs from each element in the array
      skuArray = thm.data.elements.map(element => element.sku); // Assuming each element has a 'sku' property
    }
    console.log("All the available elements in the theme are -------->", skuArray);
    setLoading(false);
    return skuArray;
  }

  const getTotalExistingThemes = async () => {
    console.log("All themes is ----->",allThemes)
    try {
      setFetching(true);
      let allData = [];
      const promises = [];

      for (let currentPage = 1; currentPage <= Math.ceil(count / limit); currentPage++) {
        let fullUrl = `theme/get-all-themes?pageNo=${currentPage}&limit=${limit}`;
        if (selectedCategory && selectedCategory !== 'All') {
          fullUrl += `&category=${selectedCategory}`;
        }
        if (filterSearch) {
          fullUrl += `&title=${filterSearch}`;
        }
        promises.push(fetchData('get', fullUrl));
      }

      const results = await Promise.all(promises);
      results.forEach(result => {
        allData = allData.concat(result?.data?.themes || []);
      });
      console.log("All info data is -------->",allData);
      const excelData = await Promise.all(allData.map(async (theme) => ({
        'ID': theme.id || "",
        'Title': theme.title || "",
        'SKU Code': theme.sku || "",
        'Description': theme.description || "",
        'Meta Data': JSON.stringify(theme.meta),
        'Category': theme.category.join(', ') || "",
        'Length': theme.size.length || "",
        'Width': theme.size.width || "",
        'Height': theme.size.height || "",
        'Corner Length': theme.size.cornerLength || "",
        'Corner Width': theme.size.cornerWidth || "",
        'Brands': theme.brands.join(', ') || "",
        'Shape': theme.shape || "",
        'Execution BX Price': theme.execution_bx_price || "",
        'Execution BX Cost': theme.execution_bx_cost || "",
        'Product BX Price': theme.product_bx_price || "",
        'Product BX Cost': theme.product_bx_cost || "",
        'Total BX Price': theme.total_bx_price || "",
        'Total BX Cost': theme.total_bx_cost || "",
        'IOS Model File': theme.ios_model_url || "",
        'WEBGL Model File': theme.webgl_model_url || "",
        'Android Model File': theme.model_url || "",
        'Theme Image Files': theme.theme_image.join(', ') || "",
        'Catalogue Image Files': theme.theme_catalogue_image.join(', ') || "",
        'Add Elements': (await getElements(theme.id)).join(', ') || "",
      })));

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);

      XLSX.utils.book_append_sheet(wb, ws, 'Themes');

      XLSX.writeFile(wb, 'Themes.xlsx');

    } catch (error) {
      console.error('Export failed:', error);
      toastError('Export failed. Please try again.');
    } finally {
      setFetching(false);
    }
  }

  const headings = [
    { name: 'Title', key: 'title', type: 'text', sortBy: true },
    { name: 'Category', key: 'category', type: 'array', sortBy: true },
    { name: 'Theme ID', key: 'sku', type: 'text', sortBy: true },
    { name: 'Size', key: 'size', type: 'size', sortBy: true, style: { whiteSpace: 'nowrap' } },
    { name: 'Product Cost', key: 'product_bx_cost', type: 'text', sortBy: true },
    { name: 'Execution Cost', key: 'execution_bx_cost', type: 'text', sortBy: true },
    { name: 'Total Cost', key: 'total_bx_cost', type: 'text', sortBy: true },
    { name: 'Brands', key: 'brands', type: 'array', sortBy: true },
    { name: 'Action', type: 'action', actions: { edit: { onClick: onEdit, toolText: 'Edit theme' }, delete: { onClick: onDelete, toolText: 'Delete theme' } } }
  ];

  return (
    <Layout activePage="Themes" style={{ display: 'flex', flexDirection: 'column' }}>

      <Modal
        show={showDeleteModal}
        hide={hideModal}
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/delete-modal-icon.svg" />
          <CommonStyled.VerifyModalHeading>Delete Theme</CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>Are you sure you want to delete this theme?</CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onConfirmDelete}>Delete</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      {/* <Modal
        show={showImportModal}
        hide={() => setShowImportModal(false)}
        hideFooter={true}
        width="600px"
        heading="Import Theme"
      >
        <CommonStyled.ImportWrapDiv>
          <CommonStyled.ImportDiv>
            <CommonStyled.ImportLink to="/upload-theme/zip">
              <img src="/unity-icon.png" />
            </CommonStyled.ImportLink>
            <div>3D Models</div>
            <div>or Images</div>
          </CommonStyled.ImportDiv>

          <CommonStyled.ImportDiv>
            <CommonStyled.ImportLink to="/upload-theme/csv">
              <img src="/themes-color-icon.svg" style={{ width: '60px', height: '60px' }} />
            </CommonStyled.ImportLink>
            <div>Theme Details</div>
          </CommonStyled.ImportDiv>
        </CommonStyled.ImportWrapDiv>
      </Modal> */}

      <Heading
        heading="Themes"
        subHeading="This is where you can view the listing of all the themes and can add new theme."
      >
        <div style={{ display: 'flex' }}>
          <SecondaryButton
            title="Import Theme"
            style={{ borderRadius: '40px', color: '#118d8b', border: '1px solid #118d8b' }}
            onClick={() => navigate('/upload-theme')}
          />
          <SecondaryButton
            title="Update Theme"
            style={{ borderRadius: '40px', color: '#118d8b', border: '1px solid #118d8b',marginLeft: '20px' }}
            onClick={() => navigate('/bulk-edit-themes')}
          />
          <PrimaryButton
            title="Add New Theme"
            style={{ marginLeft: '20px' }}
            onClick={() => navigate('/add-themes')}
          />
           <Loader loading={fetching} />
          <PrimaryButton
            title="Export"
            style={{ marginLeft: '20px' }}
            onClick={getTotalExistingThemes}
          />
        </div>
      </Heading>

      <CommonStyled.FilterDiv>
        <div style={{ display: 'flex' }}>
          <SingleSelect
            data={allCategories}
            selectedId={selectedCategory}
            onSelect={(id) => setSelectedCategory(id)}
          />
        </div>

        <SearchInput
          onInputChange={(e) => setFilterSearch(e.target.value)}
          placeholder={'Search with theme name'}
        />
      </CommonStyled.FilterDiv>
      
      {!allThemes.length && selectedCategory === 'All' && !filterSearch ?
        <>
          {!loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/themes-color-icon.svg" />
              <div>No theme added so far</div>
              <PrimaryButton title="Add New Theme" onClick={() => navigate('/add-themes')} />
            </CommonStyled.NoDataFoundDiv>
            : ''
          }
        </>
        :
        <Table
          rows={allThemes}
          headings={headings}
          page={page}
          limit={limit}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setLimit(value)}
          noDataImage="/no-theme-found-icon.svg"
          noDataText="No theme found"
          loading={loading}
        />
      }

    </Layout>
  )
}

export default withRouter(Themes);