import React, { useEffect, useState } from "react";
import moment from "moment";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import SearchInput from "../../Components/SearchInput";
import Table from "../../Components/ThemeTable";
import Modal from "../../Components/Modal";
import Loader from "../../Components/Loader";
import { withRouter } from "../../Utilities/withRouter";
import { fetchData } from "../../Utilities/handleRequest";
import SingleSelect from "../../Components/SingleSelect";
import InputGroup from "../../Components/InputGroup";
import * as CommonStyled from "../../Common/commonStyled";
import * as Styled from "./styled";
import { toastError } from "../../Utilities/toast";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import * as XLSX from 'xlsx';


const Customers = ({navigate}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [customers, setCustomers] = useState([]);
  const [actionRow, setActionRow] = useState(null);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showTrialExtendModal, setShowTrialExtendModal] = useState(false);
  const [extendDays, setExtendDays] = useState('');
  const [extendError, setExtendError] = useState('');

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [fetching,setFetching] = useState(false);
  useEffect(() => {
    setPageNo(1);
    getCustomers(1);
  }, [selectedStatus, searchText, pageSize]);

  const getCustomers = async (activePage) => {
    setLoading(true);
    await getCustomersCall(activePage);
    setLoading(false);
  }

  const getCustomersCall = async (activePage) => {
    let url = `admin-auth/customer-users?pageNo=${activePage}&limit=${pageSize}`;
    if (selectedStatus) {
      url += `&status=${selectedStatus === 'active' ? true : false}`;
    }
    if (searchText) {
      url += `&name=${searchText}`;
    }
    let res = await fetchData('get', url);
    if (res?.data) {
      let users = res.data?.users || [];
      users = res.data?.users.map((e) => {
        if (e.is_paid) {
          e.subscription = 'Monthly'
        } else if (e.is_trial_expired) {
          e.subscription = 'Free Trial Expired'
        } else {
          e.subscription = 'Free Trial'
        }
        return e;
      });
      setCustomers(users);
      setCount(res?.data?.count);
    }
  }

  const hideModal = () => {
    setShowDetailsModal(false);
    setShowTrialExtendModal(false);
    setActionRow(null);
    setExtendDays('');
    setExtendError('');
  }

  const onPageChange = (value) => {
    setLoading(true);
    getCustomers(value);
    setLoading(false);
    setPageNo(value);
  }

  const onAccessChange = async (row) => {
    if (!row) return;
    let res = await fetchData('post', `admin-auth/update-customer-status/${row.id}`, { active: !row.active });

    if (res?.message === 'Successfully updated') {
      getCustomersCall();
      toastError('Status changed successfully');
    } else {
      toastError('Something went wrong!');
    }
  }

  const onView = (row) => {
    setActionRow(row);
    setShowDetailsModal(true);
  }

  const onExtendFreeTrialClick = (row) => {
    setActionRow(row);
    setShowTrialExtendModal(true);
  }

  const onExtendFreeTrial = async () => {
    if (!actionRow) return;
    if (!extendDays) {
      setExtendError('Please enter days');
    } else {
      setLoading(true);
      let date = moment(actionRow.trial_expiry_date, "YYYY-MM-DD").add(extendDays, 'days').format('YYYY-MM-DD');
      date += `T${actionRow.trial_expiry_date?.split('T')[1]}`;

      let res = await fetchData('post', `admin-auth/update-customer-status/${actionRow.id}`, { trial_expiry_date: date });

      if (res?.message === 'Successfully updated') {
        getCustomersCall();
        setShowTrialExtendModal(false);
        setExtendDays('');
        setExtendError('');
        toastError('Free trial extended successfully');
      } else {
        toastError('Something went wrong!')
      }
      setLoading(false);
    }
  }

  const onExtendDaysChange = (e) => {
    setExtendDays(e.target.value);
    setExtendError('');
  }

  const customerDropDown = [
    { name: 'All status', id: '' },
    { name: 'Active', id: 'active' },
    { name: 'Inactive', id: 'inactive' }
  ];

  const headings = [
    { name: 'Customer Name', key: 'name', type: 'text', sortBy: true },
    { name: 'Email', key: 'email', type: 'text', sortBy: true },
    { name: 'Mobile', key: 'phone_number', type: 'text', sortBy: true },
    { name: 'Subscription', key: 'subscription', type: 'text', sortBy: true },
    { name: 'Signed Up on', key: 'created_at', type: 'date', sortBy: true },
    { name: 'Last logged in', key: 'last_login', type: 'date', sortBy: true },
    { name: 'Access', type: 'action', actions: { toggle: { key: 'active', onClick: ()=>{}, onChange: onAccessChange, toolText: (value) => value ? 'Inactive' : 'Active' }}, sortBy: true },
    { name: 'Action', type: 'action', actions: { view: {onClick: onView, toolText: 'View detail'}, settings: {onClick: onExtendFreeTrialClick, toolText: 'Extend free trial'} } }
  ];

  
  const getTotalExistingCustomers = async () => { // Update this function
    try {
      setFetching(true); // Start loading
      let allData = [];
      const promises = [];
  
      for (let currentPage = 1; currentPage <= Math.ceil(count / pageSize); currentPage++) {
        let fullUrl = `admin-auth/customer-users?pageNo=${currentPage}&limit=${pageSize}`;
        if (selectedStatus) {
          fullUrl += `&status=${selectedStatus === 'active' ? true : false}`;
        }
        if (searchText) {
          fullUrl += `&name=${searchText}`;
        }
        promises.push(fetchData('get', fullUrl));
      }
  
      const results = await Promise.all(promises);
      results.forEach(result => {
        allData = allData.concat(result?.data?.users || []);
      });
  
      // Transform data for Excel export
      const excelData = allData.map(customer => ({
        'Id':customer.id || "",
        'Customer Name': customer.name || "",
        'Email': customer.email || "",
        'Mobile No': customer.phone_number || "",
        'Subscription':customer.subscription || "",
        'Address': customer.address || "",
        'Status': customer.active ? 'Active' : 'Inactive', // Simplified
        // 'Signed Up on': customer.created_at ? moment(customer.created_at).format('YYYY-MM-DD') : "",
        // 'Last logged in': customer.last_login ? moment(customer.last_login).format('YYYY-MM-DD') : "",
        'Signed Up on': customer.created_at ? new Date(customer.created_at).toLocaleString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }) : "",
        'Last logged in': customer.last_login ? new Date(customer.last_login).toLocaleString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }) : "",
    }));
  
      // Create workbook and worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);
  
      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Customers');
  
      // Generate Excel file
      XLSX.writeFile(wb, 'Customers.xlsx');
  
    } catch (error) {
      console.error('Export failed:', error);
      toastError('Export failed. Please try again.');
    } finally {
      setFetching(false); // Stop loading
    }
  }
  
  // ... existing code ...

  return (
    <Layout activePage="Customers" style={{ display: 'flex', flexDirection: 'column' }}>
      <Loader loading={loading} />

      <Modal
        show={showDetailsModal}
        hide={hideModal}
        heading={"Customer Details"}
        hideFooter={true}
        contentStyle={{ padding: '18px 28px' }}
        width="446px"
      >
        { actionRow ?
          <Styled.DetailsDiv>
            <p>Name: <strong>{actionRow.name || '-'}</strong></p>
            <p>Mobile: <strong>{actionRow.phone_number || '-'}</strong></p>
            <p>Email Address: <strong>{actionRow.email || '-'}</strong></p>
            <p>Address: <strong>{actionRow.address ? `${actionRow.address.trim()}, ${actionRow.pincode}` : '-'}</strong></p>
            <p>House Type: <strong>{actionRow.house_type || '-'}</strong></p>
            <p>No. of Bathrooms: <strong>{actionRow.no_of_bathrooms || '-'}</strong></p>
          </Styled.DetailsDiv>
        : ''
        }
      </Modal>

      <Modal
        show={showTrialExtendModal}
        hide={hideModal}
        heading={"Extend Free Trial"}
        contentStyle={{ padding: '18px 28px 2px 28px' }}
        okText="Extend Now"
        onOk={onExtendFreeTrial}
        width="446px"
      >
        <Styled.ExtendWrapDiv>
          <Styled.ModalTextDiv>Enter the number of days for the extension</Styled.ModalTextDiv>
          <InputGroup
            placeholder="Enter days"
            type="number"
            groupValue="Days"
            onChange={(e) => onExtendDaysChange(e)}
            value={extendDays}
            groupAtLast={true}
            error={extendError}
          />
        </Styled.ExtendWrapDiv>
      </Modal>

      <Heading
        heading="All Customers"
        subHeading="This is where you can view the listing of all the customers."
      >
       <div style={{ display: 'flex' }}>
          <Loader loading={fetching} />
          <SecondaryButton
            title="Update Customers"
            style={{ marginLeft: '20px',borderRadius: '40px', color: '#118d8b', }}
            onClick={() => navigate('/bulk-edit-customers')}
          />
          <PrimaryButton
            title="Export"
            style={{ marginLeft: '20px' }}
            onClick={getTotalExistingCustomers}
          />
        </div>
        </Heading>
      <CommonStyled.FilterDiv>
        <SingleSelect
          data={customerDropDown}
          selectedId={selectedStatus}
          onSelect={(id) => setSelectedStatus(id)}
          placeholder="Select status"
        />
        <SearchInput
          onInputChange={(e) => setSearchText(e.target.value)}
          placeholder={'Search from name'} 
        />
      </CommonStyled.FilterDiv>

      {!customers?.length && !searchText ?
        <>
          {!loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/no-customer-onboard-icon.svg" alt="" />
              <div>No customer onboarded so far</div>
            </CommonStyled.NoDataFoundDiv>
            : ''
          }
        </>
        :
        <Table
          rows={customers}
          headings={headings}
          page={pageNo}
          limit={pageSize}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setPageSize(value)}
          noDataImage="/no-customer-found-icon.svg"
          noDataText="No customer found"
          loading={loading}
        />
      }

    </Layout>
  )
}

export default withRouter(Customers);