import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SearchInput from "../../Components/SearchInput";
import SingleSelect from "../../Components/SingleSelect";
import Table from "../../Components/ThemeTable";
import Modal from "../../Components/Modal";
import { fetchData } from "../../Utilities/handleRequest";
import { withRouter } from "../../Utilities/withRouter";
import { toastError } from "../../Utilities/toast";
import * as CommonStyled from "../../Common/commonStyled";
import SecondaryButton from "../../Components/SecondaryButton";
import Loader from "../../Components/Loader";

import * as XLSX from 'xlsx';

const Elements = ({ navigate }) => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchText, setSearchText] = useState(() => {
    return localStorage.getItem('searchText') || ''; // Retrieve searchText from local storage
  });

  const [allCategories, setAllCategories] = useState([]);
  const [allElements, setAllElements] = useState([]);

  const [loading, setLoading] = useState(false);
  const [actionElement, setActionElement] = useState(null);
  const [showAvailableModal, setShowAvailableModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(() => {
    return parseInt(localStorage.getItem('pageLimit')) || 10; // Retrieve limit from local storage
  });
  const [count, setCount] = useState(0);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('pageLimit', limit); // Store limit in local storage
  }, [limit, searchText]);

  const getCategories = async () => {
    let res = await fetchData('get', 'element/get-all-category');
    const categoryArr = res?.data?.category || [];
    const newCategoryArr = [{id: 'All', name: 'Select category'}];

    for (let x = 0; x < categoryArr.length; x++) {
      newCategoryArr.push({ id: categoryArr[x].id, name: categoryArr[x].name });
    }
    setAllCategories(newCategoryArr);
  }

  useEffect(() => {
    setPage(1);
    getElements(1);
  }, [searchText, limit, selectedCategory]);

  const getElements = async (pageNo) => {
    setLoading(true);
    let url = `element/get-all-elements?pageNo=${pageNo || page}&limit=${limit}&search`;
    if (selectedCategory && selectedCategory !== 'All') {
      url += `&category_id=${selectedCategory}`;
    }
    if (searchText) {
      url += `&title=${searchText}`;
    }

    let res = await fetchData('get', url);
    setAllElements(res?.data?.elements || []);
    setCount(res?.data?.count || 0);
    setLoading(false);
  }

  const onDelete = (row) => {
    setActionElement(row);
    setShowDeleteModal(true);
  }

  const onConfirmDelete = async () => {
    setLoading(true);
    let res = await fetchData('get', `element/delete-elements/${actionElement.id}`);
    if (res?.message === 'Successfully Deleted') {
      hideModal();
      toastError('The element has been deleted successfully!');
      getElements();
    } else {
      toastError('Something went wrong');
      setLoading(false);
    }
  }

  const onEdit = (row) => {
    const getElement = async (id) => {
   

      let cat = await fetchData('get', 'element/get-all-category');
      const categoriesAll = cat?.data?.category || []
      
      let elm = await fetchData('get', `element/get-element/${row.id}`);
      const data = elm?.data;
      
      let sub = await fetchData('get', `element/get-all-subcategory?category_id=${data?.category_id}`);
      const subcategoriesAll = sub?.data?.subcategory || [];
  
      const categoryArray = categoriesAll.filter(e => e.id === data.category_id)[0];
      const subcategoryArray = subcategoriesAll.filter(e => e.id === data.subcategory_id)[0];
  
      data.category_name = categoryArray?.name || '';
      data.subcategory_name = subcategoryArray?.name || '';
  
    
      // setAllCategories(categoriesAll);
      // setAllSubCategories(subcategoriesAll);
      // setElementData(data);
  
      
    }
    getElement();
    navigate(`/edit-elements/${row.id}`);
  }

  const getElement = async (id) => {
   

    let cat = await fetchData('get', 'element/get-all-category');
    const categoriesAll = cat?.data?.category || []
    
    let elm = await fetchData('get', `element/get-element/${id}`);
    const data = elm?.data;
    
    let sub = await fetchData('get', `element/get-all-subcategory?category_id=${data?.category_id}`);
    const subcategoriesAll = sub?.data?.subcategory || [];

    const categoryArray = categoriesAll.filter(e => e.id === data.category_id)[0];
    const subcategoryArray = subcategoriesAll.filter(e => e.id === data.subcategory_id)[0];

    data.category_name = categoryArray?.name || '';
    data.subcategory_name = subcategoryArray?.name || '';

    
    // setAllCategories(categoriesAll);
    // setAllSubCategories(subcategoriesAll);
    // setElementData(data);

    
  }
  
  const onPageChange = (value) => {
    getElements(value);
    setPage(value);
  }

  const onAvailabilityClick = async (row) => {
    setActionElement(row);
    setShowAvailableModal(true);
  }

  const onAvailabilityConfirm = async() => {
    setLoading(true);
    let res = await fetchData('post', `element/update-elements/${actionElement.id}`, { availability: !actionElement.availability });
    if (res?.data) {
      hideModal();
      toastError('Availability has been changed successfully!');
      getElements();
    } else {
      toastError('Something went wrong');
      setLoading(false);
    }
  }

  const hideModal = () => {
    setActionElement(null);
    setShowAvailableModal(false);
    setShowDeleteModal(false);
  }

  const elementsDropDown = [
    { name: 'Elements', id: 'elements' },
    { name: 'Element Bundles', id: 'elements-bundles' }
  ];

  const headings = [
    { name: 'Title', key: 'title', type: 'text', sortBy: true },
    { name: 'SKU', key: 'sku', type: 'text', sortBy: true },
    { name: 'Category', key: 'category_name', type: 'text', sortBy: true },
    { name: 'Finish', key: 'finishing', type: 'text', sortBy: true },
    { name: 'Size', key: 'size', type: 'size', sortBy: true, style: { whiteSpace: 'nowrap' }},
    { name: 'MRP', key: 'mrp', type: 'rupees', sortBy: true },
    { name: 'BX Price', key: 'bx_price', type: 'rupees', sortBy: true },
    { name: 'Procurement Cost', key: 'procurement_cost', type: 'rupees', sortBy: true },
    { name: 'Availability', type: 'action', actions: { toggle: { key: 'availability', onClick: onAvailabilityClick, onChange: ()=>{}, toolText: (value) => value ? 'In stock' : 'Out of stock' } }, sortBy: true },
    { name: 'Brand', key: 'brands', type: 'text', sortBy: true },
    { name: 'Action', type: 'action', actions: { edit: {onClick: onEdit, toolText: 'Edit element'}, delete: {onClick: onDelete, toolText: 'Delete element'} } }
  ];

  const getTotalExistingElements = async () => {
    try {
      setFetching(true);
      let allData = [];
      const promises = [];

      for (let currentPage = 1; currentPage <= Math.ceil(count / limit); currentPage++) {
        let fullUrl = `element/get-all-elements?pageNo=${currentPage}&limit=${limit}`;
        if (selectedCategory && selectedCategory !== 'All') {
          fullUrl += `&category_id=${selectedCategory}`;
        }
        if (searchText) {
          fullUrl += `&title=${searchText}`;
        }
        promises.push(fetchData('get', fullUrl));
      }

      const results = await Promise.all(promises);
      results.forEach(result => {
        allData = allData.concat(result?.data?.elements || []);
      });
      const excelData = allData.map(element => ({
        'ID':element.id || "",
        'Title': element.title || "",
        'SKU Code': element.sku || "",
        'Description':element.description || "",
        'Category Name': element.category_name || "",
        'Subcategory Name': element.subcategory_name || "",
        'Execution Category':element.execution_category[0] || "",
        'Length': element.size.length || "",
        'Width': element.size.width || "",
        'Height': element.size.height || "",
        'Brands': element.brands || "",
        'Misc':element.misc || "",
        'Finishing':element.finishing || "",
        'MRP':element.mrp || "",
        'Unit':element.unit || "",
        'Procurement Cost':element.procurement_cost || "",
        'BX Price':element.bx_price || "",
        'Service Cost Type':element.service_cost_type || "",
        'Service Cost':element.service_cost || "",
        'Element Images':element.element_image.join(', ') || "",
        'Android Model File':element.model_url,
        'IOS Model File':element.ios_model_url || "",
        'WEBGL Model File':element.webgl_model_url || "",
        
        // 'Finish': element.finishing || "",
        // 'Size': element.size || "",
        // 'MRP': element.mrp || "",
        // 'BX Price': element.bx_price || "",
        // 'Procurement Cost': element.procurement_cost || "",
        // 'Availability': element.availability ? 'In stock' : 'Out of stock',
        // 'Brand': element.brands || "",
      }));

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(excelData);

      XLSX.utils.book_append_sheet(wb, ws, 'Elements');

      XLSX.writeFile(wb, 'Elements.xlsx');

    } catch (error) {
      console.error('Export failed:', error);
      toastError('Export failed. Please try again.');
    } finally {
      setFetching(false);
    }
  }

  return (
    <Layout activePage="Elements" style={{ display: 'flex', flexDirection: 'column' }}>

      <Modal
        show={showAvailableModal}
        hide={hideModal}
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/stock-modal-icon-green.svg" />
          <CommonStyled.VerifyModalHeading style={{ color: '#118D8B' }}>{actionElement?.availability ? 'Out of Stock' : 'In Stock'}</CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>
            { actionElement?.availability ?
              'Marking this element as out of stock will make it unavailable in bathroom themes and bundles.'
            :
              'Marking this element as in stock will make it available in bathroom themes and bundles.'
            }
          </CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onAvailabilityConfirm}>Confirm</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      <Modal
        show={showDeleteModal}
        hide={hideModal}
        okText="Delete"
        hideFooter={true}
        width="446px"
      >
        <CommonStyled.VerificationWrapDiv>
          <img src="/delete-modal-icon-green.svg" />
          <CommonStyled.VerifyModalHeading style={{ color: '#118D8B' }}>Delete Element</CommonStyled.VerifyModalHeading>
          <CommonStyled.VerifyModalSubheading>Are you sure you want to delete this element?</CommonStyled.VerifyModalSubheading>
          <div>
            <button onClick={hideModal}>Cancel</button>
            <button onClick={onConfirmDelete}>Delete</button>
          </div>
        </CommonStyled.VerificationWrapDiv>
      </Modal>

      <Modal
        show={showImportModal}
        hide={() => setShowImportModal(false)}
        hideFooter={true}
        width="600px"
        heading="Import Element"
      >
        <CommonStyled.ImportWrapDiv>
          <CommonStyled.ImportDiv>
            <CommonStyled.ImportLink to="/upload-element/zip">
              <img src="/unity-icon.png" />
            </CommonStyled.ImportLink>
            <div>3D Models</div>
            <div>or Images</div>
          </CommonStyled.ImportDiv>

          <CommonStyled.ImportDiv>
            <CommonStyled.ImportLink to="/upload-element/csv">
              <img src="/no-element-added-icon.svg" style={{ width: '60px', height: '60px' }} />
            </CommonStyled.ImportLink>
            <div>Element Details</div>
          </CommonStyled.ImportDiv>
        </CommonStyled.ImportWrapDiv>
      </Modal>

      <Heading
        heading="Elements"
        subHeading="This is where you can view the listing of all the elements and can add new element."
      >
        <div style={{ display: 'flex' }}>
          <SecondaryButton
            title="Import Element"
            style={{ borderRadius: '40px', color: '#118d8b', border: '1px solid #118d8b' }}
            onClick={() => navigate('/upload-element')}
          />
            <SecondaryButton
            title="Update Element"
            style={{ marginLeft: '20px',borderRadius: '40px', color: '#118d8b', }}
            onClick={() => navigate('/bulk-edit-elements')}
          />
          <PrimaryButton
            title="Upload New Element"
            style={{ marginLeft: '20px' }}
            to={'/add-elements'}
          />
          <Loader loading={fetching} />
          <PrimaryButton
            title="Export"
            style={{ marginLeft: '20px' }}
            onClick={getTotalExistingElements}
          />
        </div>
      </Heading>

      <CommonStyled.FilterDiv>
        <div style={{ display: 'flex' }}>
          <SingleSelect
            data={elementsDropDown}
            selectedId="elements"
            onSelect={(id) => navigate(`/${id}`)}
            style={{ marginRight: '20px' }}
          />
          <SingleSelect
            data={allCategories}
            selectedId={selectedCategory}
            onSelect={(id) => setSelectedCategory(id)}
          />
        </div>

        <SearchInput
          onInputChange={(e) => setSearchText(e.target.value)}
          placeholder="Search with element name"
          data={searchText}
        />
      </CommonStyled.FilterDiv>

      { !allElements.length && selectedCategory === 'All' && !searchText ?
        <>
          { !loading ?
            <CommonStyled.NoDataFoundDiv>
              <img src="/no-element-added-icon.svg" />
              <div>No element added so far</div>
              <PrimaryButton title="Upload New Element" to={'/add-elements'} />
            </CommonStyled.NoDataFoundDiv>
          : ''
          }
        </>
      :
        <Table
          rows={allElements}
          headings={headings}
          page={page}
          limit={limit}
          count={count}
          onPageChange={onPageChange}
          onLimitChange={(value) => setLimit(value)}
          noDataImage="/no-element-found-icon.svg"
          noDataText="No element found"
          loading={loading}
        />
      }

      

    </Layout>
  )
}

export default withRouter(Elements);