import * as Styled from "./styled";

const ToolTip = ({ children, tooltip='', wrap=false, tooltipStyle={} }) => {

  return (
    <Styled.WrapDiv>
      <Styled.ToolTipBaseDiv>
        <Styled.ToolTipDiv style={tooltipStyle}>
          <div>
            <p style={wrap ? {whiteSpace: 'wrap'} : {}} dangerouslySetInnerHTML={{__html: tooltip}} />
          </div>
        </Styled.ToolTipDiv>
      </Styled.ToolTipBaseDiv>

      {children}
    </Styled.WrapDiv>
  )
}

export default ToolTip;
