export const ExecutionLayout = () => (
  [
    {
      title: 'Demolition',
      layout: [
        'Covering & Packing',
        'Only CP and Sanitary Removal',
        'Complete Demolition',
        'Removal of Bathtub/Jacuzi'
      ]
    },
    {
      title: 'Plumbing Work',
      layout: [
        'Installations of Sanitary & CP Fittings',
        'Internal CPVC Plumbing',
        'Internal UPVC Plumbing',
        'Plumbing for Bathtub/Jacuzi'
      ]
    },
    {
      title: 'Electrical Works',
      layout: [
        'Electrical Fitting Installation',
        'Electrical Package'
      ]
    },
    {
      title: 'Wall Plaster'
    },
    {
      title: 'Waterproofing'
    },
    {
      title: 'Ledge Wall'
    },
    {
      title: 'Tile Works',
      layout: [
        'Tile Laying With Adhesive',
        'Extra for Epoxy Grout'
      ]
    },
    {
      title: 'Material Handling Charges'
    }
  ]
)