import { useState, useRef, useEffect } from "react";
import JSZip from "jszip";
import { read, utils } from "xlsx";
import Layout from "../../Components/Layout";
import Heading from "../../Components/Heading";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import { fetchData } from "../../Utilities/handleRequest";
import { toastError } from "../../Utilities/toast";
import * as Styled from "./styled";

const BulkUpdateUsers = () => {
  const [file, setFile] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [remainingFiles, setRemainingFiles] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [errorFiles, setErrorFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const fileInput = useRef(null);

  const texts = {
    subHeading: "Upload the ZIP file of xlsx.",
    upload: "File format: .zip",
    notes: [
      "The .zip file must contain .xlsx file including all required data.",
      "The .zip will be imported in the background and their respective statuses will be updated once the import is completed.",
    ],
  };

  const onUpload = async () => {
    // ... existing code ...
    setProgressPercentage(0);
    if (loading) {
      return;
    }
    if (!file) {
      toastError("Please select zip file!");
      return;
    }
    setLoading(true);

    const zip = new JSZip();
    try {
      const zipContent = await zip.loadAsync(file);
      const files = Object.keys(zipContent.files).filter(
        (fileName) => !fileName.includes("__MACOSX") && !fileName.includes("/.")
      );

      const excelFileName = files.find((fileName) =>
        fileName.endsWith(".xlsx")
      );

      if (excelFileName) {
        try {
          const excelFile = await zipContent.files[excelFileName].async("blob");

          const ab = await excelFile.arrayBuffer();
          const wb = read(ab);
          const ws = wb.Sheets[wb.SheetNames[0]];
          const fileData = utils.sheet_to_json(ws);

          if (!fileData?.length) {
            toastError("Excel file is empty.");
            setLoading(false);
            return;
          } else {
            setRemainingFiles(fileData.length);
          }

          for (let x = 0; x < fileData.length; x++) {
            const payload = {};
            let payloadError = "";
            const currentLine = fileData[x];

            // Log the current line to see the data being read
            let data = {
              id: currentLine.Id || "",
              name: currentLine["Name"] || "",
              email: currentLine["Email"] || "",
              role: ((currentLine["Role"] || "").toUpperCase().replace(/\s+/g, '_')) || "",
              employee_id: String(currentLine["Employee ID"] || ""),
              phone_number: currentLine["Phone No"] || "",
              zone: currentLine["Zone"] || "",
              pincode: currentLine["Pincode"] || "",
              //address: currentLine["Address"] || "",
            };

            // Check the response from fetchData
            let res = await fetchData(
              "post",
              `admin-auth/edit-user/${data.id}`,
              data
            );
            if (res.message == "Successfully Edit") {
              // Assuming res.ok indicates success
              setUploadedFiles((prev) => prev + 1); // Increment uploadedFiles on success
              setRemainingFiles((prev) => prev - 1); // Decrement remainingFiles
            } else {
              payloadError = "Error updating projects"; // Set an appropriate error message
              setErrorFiles((prev) => [
                ...prev,
                `Appointment ${x + 1}: ${payloadError}`,
              ]);
            }
            setProgressPercentage(Math.ceil((100 / fileData.length) * (x + 1)));
          }
        } catch (uploadError) {
          toastError("Excel file is invalid.");
        }
      } else {
        toastError("Excel file not found in the ZIP.");
        setLoading(false);
        return;
      }
    } catch (err) {
      toastError("Failed to unzip the file.");
    }
    setLoading(false);
  };

  const onFileSelect = (e) => {
    if (!e?.target?.files?.length) {
      if (fileInput?.current) {
        fileInput.current.value = "";
      }
      return;
    }
    afterFileSelect(e.target.files[0]);
  };

  const onDropFile = (e) => {
    e.preventDefault();
    if (!e?.dataTransfer?.files?.length) {
      if (fileInput?.current) {
        fileInput.current.value = "";
      }
      return;
    }
    const droppedFiles = Array.from(e.dataTransfer.files);
    afterFileSelect(droppedFiles[0]);
  };

  const afterFileSelect = (selectedFile) => {
    const fileArr = selectedFile.name.split(".");
    const fileExt = fileArr[fileArr.length - 1];

    if (fileExt !== "zip") {
      toastError("File type not supported");
      if (fileInput?.current) {
        fileInput.current.value = "";
      }
      return;
    }

    setFile(selectedFile);
    setRemainingFiles(0);
    setUploadedFiles(0);
    setErrorFiles([]);
  };

  const onUploadAgain = () => {
    setFile(null);
    setRemainingFiles(0);
    setUploadedFiles(0);
    setErrorFiles([]);
  };

  return (
    <Layout activePage="Users">
      <Heading heading="Update Users" subHeading={texts.subHeading} />

      <Styled.CardDiv>
        {loading ? (
          <Styled.LoadingDiv>
            <div />
            <Styled.ImportWrapDiv>
              <Styled.ImportHeading>Users Updating...</Styled.ImportHeading>
              <Styled.ProgressWrapDiv>
                <Styled.ImportDetail>
                  {progressPercentage}% Complete
                </Styled.ImportDetail>
                <Styled.ProgressDiv>
                  <div style={{ width: `${progressPercentage}%` }} />
                </Styled.ProgressDiv>
              </Styled.ProgressWrapDiv>

              <Styled.RemainingDiv>
                <div />
                {remainingFiles} Remaining
              </Styled.RemainingDiv>
              <Styled.CompletedDiv>
                <div />
                {uploadedFiles} Uploaded
              </Styled.CompletedDiv>
              <Styled.ResultErrorDiv>
                <div />
                {errorFiles?.length || 0} Error
              </Styled.ResultErrorDiv>
            </Styled.ImportWrapDiv>
          </Styled.LoadingDiv>
        ) : uploadedFiles || errorFiles?.length ? (
          <Styled.ResultDiv>
            <Styled.Text1Div>{`${uploadedFiles} Users${
              uploadedFiles > 1 ? "s" : ""
            } updated successfully.`}</Styled.Text1Div>
            {errorFiles.length ? (
              <Styled.ErrorDiv>
                <div>{`Error occurred in following ${errorFiles.length} themes:`}</div>
                {errorFiles.map((item) => (
                  <div>{item}</div>
                ))}
              </Styled.ErrorDiv>
            ) : (
              ""
            )}
          </Styled.ResultDiv>
        ) : (
          <Styled.InputWrapLabel onDrop={onDropFile}>
            <Styled.UploadImgDiv style={{ margin: "0px 0px 12px 0px" }}>
              <img src="/upload-image-icon.svg" />
            </Styled.UploadImgDiv>

            <Styled.TextWrapDiv style={{ alignItems: "center" }}>
              <Styled.Text1Div>Choose file to upload</Styled.Text1Div>
              <Styled.Text2Div>or drag and drop them here</Styled.Text2Div>
              <Styled.Text3Div>{file?.name || texts.upload}</Styled.Text3Div>
            </Styled.TextWrapDiv>
            <input type="file" onChange={onFileSelect} ref={fileInput} />
          </Styled.InputWrapLabel>
        )}

        {/* <Styled.DownloadCSVA href="https://bathxpertz.s3.ap-south-1.amazonaws.com/admin/Sample_excel_to_upload_elements.xlsx">
          <img src="/download-icon.svg" />
          Sample .xlsx File
        </Styled.DownloadCSVA> */}

        <Styled.NotesDiv>
          Note:
          <ul>
            {texts.notes.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </Styled.NotesDiv>
        <Styled.BottomDiv>
          <SecondaryButton to="/users" title="Back" />
          {uploadedFiles || errorFiles?.length ? (
            <PrimaryButton
              title="Import Again"
              style={{ borderRadius: "8px", marginLeft: "20px" }}
              onClick={onUploadAgain}
            />
          ) : (
            <PrimaryButton
              title="Import Now"
              style={{ borderRadius: "8px", marginLeft: "20px" }}
              onClick={onUpload}
            />
          )}
        </Styled.BottomDiv>
      </Styled.CardDiv>
    </Layout>
  );
};

export default BulkUpdateUsers;
