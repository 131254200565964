import React, { useEffect, useState } from "react";
import { getUserPermissions } from "../../Utilities/localStorageHandler";
import * as Styled from "./styled";

export const Sidebar = ({ activePage }) => {
  const [sideMenus, setSideMenus] = useState([]);

  useEffect(() => {
    const permissions = getUserPermissions();

    if (permissions) {
      setSideMenus(menus.filter((item) => permissions.includes(item.icon)));
    }
  }, []);

  const menus = [
    { title: "Projects", icon: "projects", path: "/projects" },
    { title: "Themes", icon: "themes", path: "/themes" },
    { title: "Elements", icon: "elements", path: "/elements" },
    { title: "Users", icon: "users", path: "/users" },
    { title: "Feedback", icon: "feedback", path: "/feedback" },
    { title: "Support", icon: "support", path: "/support" },
    { title: "Pricing", icon: "pricing", path: "/pricing" },
    { title: "Transactions", icon: "transactions", path: "/transactions" },
    {
      title: "Discount Coupon",
      icon: "discount-coupon",
      path: "/discount-coupon",
    },
    { title: "Appointments", icon: "appointments", path: "/appointments" },
    { title: "Customers", icon: "customers", path: "/customers" },
    { title: "Settings", icon: "settings", path: "/settings" },
  ];

  return sideMenus && sideMenus.length ? (
    <Styled.MainDiv >
      <Styled.LinkWrapDiv>
        {sideMenus.map((item) => (
          <Styled.ItemLink
            active={item.title === activePage ? "true" : "false"}
            to={item.path}
            key={`${item.title}_sidebar`}
          >
            <img
              src={`/sidebar-icons/${item.icon}${
                item.title === activePage ? "-active" : ""
              }.svg`}
            />
            {item.title}
          </Styled.ItemLink>
        ))}
      </Styled.LinkWrapDiv>
    </Styled.MainDiv>
  ) : (
    <></>
  );
};

export default Sidebar;

