import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { isUserLoggedIn, getUserPermissions } from './Utilities/localStorageHandler';
import App from './App';
import Users from './Pages/Users';
import Themes from './Pages/Themes';
import EditThemes from './Pages/EditThemes';
import Elements from './Pages/Elements';
import EditElements from './Pages/EditElements';
import Feedback from './Pages/Feedback';
import Home from './Pages/Home';
import Settings from './Pages/Settings';
import Appointments from './Pages/Appointments';
import Projects from './Pages/Projects';
import ProjectDetails from './Pages/ProjectDetails';
import Support from './Pages/Support';
import SupportTicket from './Pages/SupportTicket';
import Pricing from './Pages/Pricing';
import ElementsBundles from './Pages/ElementsBundles';
import EditElementsBundle from './Pages/EditElementsBundle';
import Transactions from "./Pages/Transactions";
import DiscountCoupon from './Pages/DiscountCoupon';
import EditDiscountCoupon from './Pages/EditDiscountCoupon';
import Customers from './Pages/Customers';
import NoFoundPage from './Pages/NoFoundPage';
import NoAccessPage from './Pages/NoAccessPage';
import Payment from './Pages/Payment';
import UploadTheme from './Pages/UploadTheme';
import UploadElement from './Pages/UploadElement';
import ProjectExecution from './Pages/ProjectExecution';
import BulkUpdateElements from './Pages/BulkUpdateElements';
import BulkUpdateThemes from './Pages/BulkUpdateThemes';
import BulkUpdateAppointments from './Pages/BulkUpdateAppointments';
import BulkUpdateCustomers from './Pages/BulkUpdateCustomers';
import BulkUpdateUsers from './Pages/BulkUpdateUsers';
import BulkUpdateProjects from './Pages/BulkUpdateProjects';
import RedirectToApp from './Pages/RedirectToApp';

const permissions = getUserPermissions() || [];

const routes = [
  { path: '/users', component: <Users />, permission: 'users' },
  { path: '/themes', component: <Themes />, permission: 'themes' },
  { path: '/add-themes', component: <EditThemes />, permission: 'themes' },
  { path: '/edit-themes/:id', component: <EditThemes />, permission: 'themes' },
  { path: '/elements', component: <Elements />, permission: 'elements' },
  { path: '/add-elements', component: <EditElements />, permission: 'elements' },
  { path: '/edit-elements/:id', component: <EditElements />, permission: 'elements' },
  { path: '/bulk-edit-elements', component: <BulkUpdateElements />, permission: 'elements' },
  { path: '/bulk-edit-themes', component: <BulkUpdateThemes />, permission: 'themes' },
  { path: '/bulk-edit-appointments', component: <BulkUpdateAppointments />, permission: 'appointments' },
  { path: '/bulk-edit-customers', component: <BulkUpdateCustomers />, permission: 'customers' },
  { path: '/bulk-edit-users', component: <BulkUpdateUsers />, permission: 'users' },
  { path: '/bulk-edit-projects', component: <BulkUpdateProjects />, permission: 'projects' },
  { path: '/feedback', component: <Feedback />, permission: 'feedback' },
  { path: '/settings', component: <Settings />, permission: 'settings' },
  { path: '/appointments', component: <Appointments />, permission: 'appointments' },
  { path: '/projects', component: <Projects />, permission: 'projects' },
  { path: '/projects/:id', component: <ProjectDetails />, permission: 'projects' },
  { path: '/support', component: <Support />, permission: 'support' },
  { path: '/support-ticket/:id', component: <SupportTicket />, permission: 'support' },
  { path: '/pricing', component: <Pricing />, permission: 'pricing' },
  { path: '/transactions', component: <Transactions />, permission: 'transactions' },
  { path: '/elements-bundles', component: <ElementsBundles />, permission: 'elements' },
  { path: '/add-elements-bundle', component: <EditElementsBundle />, permission: 'elements' },
  { path: '/edit-elements-bundle/:id', component: <EditElementsBundle />, permission: 'elements' },
  { path: '/discount-coupon', component: <DiscountCoupon />, permission: 'discount-coupon' },
  { path: '/add-discount-coupon', component: <EditDiscountCoupon />, permission: 'discount-coupon' },
  { path: '/edit-discount-coupon/:id', component: <EditDiscountCoupon />, permission: 'discount-coupon' },
  { path: '/customers', component: <Customers />, permission: 'customers' },
  { path: '/upload-theme', component: <UploadTheme />, permission: 'themes' },
  { path: '/upload-element', component: <UploadElement />, permission: 'elements' },
  { path: '/projects/boq/:id', component: <ProjectExecution />, permission: 'projects' },
];

const getRouteElement = (route) => {
  if (isUserLoggedIn()) {
    if (permissions && permissions.includes(route.permission)) {
      return route.component;
    }
    return <NoAccessPage />
  } else {
    return <Navigate to={`/${permissions[0] || ''}`} replace />
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={isUserLoggedIn() ? <Navigate to={`/${permissions[0] || 'no-permission'}`} replace /> : <Home />} />
        
        {routes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={getRouteElement(route)}
          />
        ))}
        
        <Route path="/payment/:token/:order_id/:project_id" element={<Payment />} />
        <Route path="/no-permission" element={<NoAccessPage />} />
        <Route path="/redirect" element={<RedirectToApp />} />
        <Route path="/*" element={<NoFoundPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
